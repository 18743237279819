import { useHookstate, type StateMethods } from "@hookstate/core";
import { Button, Grid } from "@mui/material";
import React from "react"
import { InputSearchWithClear } from "~/components/InputSearchWithClear";
import { useLang } from "~/hooks/useLang";

interface DispatchFiltersProps {
  readonly searchCity: StateMethods<string>;
  readonly clearFilters: () => void;
}

export default function DispatchFilters({ searchCity, clearFilters }: DispatchFiltersProps) {
  const { translate } = useLang();

  return (
    <Grid
      container
      direction={'row'}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '24px'
      }}>
      <Grid item xs={2} sx={{ pt: 1 }}>
        <InputSearchWithClear sx={{ width: '100%' }} placeholder={translate('Enter a city')} search={searchCity} />
      </Grid>
      <Grid item xs={3} sx={{ pl: 2, pt: 1 }}>
        <Button variant="outlined" onClick={clearFilters}>
          {translate('Clear Filters')}
        </Button>
      </Grid>
    </Grid>
  )
}