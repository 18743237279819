import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Index() {
  const { translate } = useLang();
  const friendlyType = useHookstate<string | null>(null)
  const lockFields = useHookstate<boolean>(false);
	const loading = useHookstate<boolean>(false);
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number | null>(null);
  const forceRefresh = useHookstate<boolean>(false);
  
  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const friendlyTypeFilters = [
    {
      type: 'name',
      placeholder: 'Name',
      variable: friendlyType
    },
    {
      type: 'type_id',
      variable: typeId
    },
    {
      type: 'subtype_id',
      variable: subtypeId,
      dependent: typeId
    }
  ]

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='friendly-type'
        title='Type/SubType Friendly'
        aliasLabel={[
          { column: 'type_id', label: 'Id Tipo' },
          { column: 'type:name', label: 'Nome do Tipo' },
          { column: 'type:code', label: 'Código do tipo' },
          { column: 'subtype:name', label: 'Nome do subtipo' },
          { column: 'subtype:code', label: 'Código do subtipo' },

        ]}
        forceOrderColumns={['name', 'description', 'type', 'subtype']}
        showInViewMore={['name', 'description', 'type', 'subtype', 'created_at']}
        viewTitle='Type/SubType Friendly'
        loading={loading}
        lockFields={lockFields.get()}
        forceRefresh={forceRefresh.get()}
        filters={friendlyTypeFilters}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        clearFilters={() => {
          friendlyType.set('')
          typeId.set(null)
          subtypeId.set(null)
          forceRefresh.set(!forceRefresh.get())
        }} 
        codeFields={['type', 'subtype']}
        canView
        disableColumnsFilters
      />
    </Layout>
  )
}
