import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CitySelectField from "../CitySelectField";
import DistrictSelectField from "../DistrictSelectField";
import { LocalityTextField, LocationState } from "~/components/LocalityTextField";
import SubtypeSelectField from "../SubtypeSelectField";
import TypeSelectField from "../TypeSelectField";
import ReferenceField from '../ReferenceField';
import { useHookstate } from "@hookstate/core";
import FilterAutocompleteCity from "~/components/FilterSelectAutocompleteCity";
import FilterAutocompleteDistrict from "~/components/FilterSelectAutocompleteDistrict";
import useSystemState from "~/features/System/stores/SystemState";
import { getEntryProcedures } from "../../services";


export default function EventInputContainer({ 
  readonly, 
  isValid, 
  isInvalidRequest, 
  id, 
  lat, 
  lon, 
  type, 
  location, 
  reference, 
  cityId, 
  districtId, 
  typeId, 
  typeName, 
  subtypeId, 
  subTypeName, 
  showVehicleButton, 
  totalSubtypes,
  openModalEntryProcedure,
  saved,
  totalDistricts
}) {
  const showVehicleButtonType = useHookstate(false);
  const { windowManager } = useSystemState();

  const locationState: LocationState = {
    id,
    cityId,
    districtId,
    latitude: lat,
    longitude: lon,
    locationValue: location,
    type
  }
  
  return (
    <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
      <Grid item>
        <LocalityTextField readonly={readonly} isValid={isValid} isInvalidRequest={isInvalidRequest} locationState={locationState}
        />
      </Grid>
      <Grid item>
        <ReferenceField readonly={readonly} isValid={isValid} reference={reference}
        />
      </Grid>
      <Grid item>
        <FilterAutocompleteCity
          disabled={!isValid}
          readonly={readonly}
          onCityChange={(city) => {
            cityId?.set(city)
          }}
          cityId={cityId?.get()}
          isInvalidRequest={isInvalidRequest}
        />
        {/* <CitySelectField readonly={readonly} isValid={isValid} isInvalidRequest={isInvalidRequest} cityId={cityId} /> */}
      </Grid>
      <Grid item>
        <FilterAutocompleteDistrict
          disabled={!isValid}
          readonly={readonly}
          onDistrictChange={(district) => {
            districtId?.set(district)
          }}
          districtId={districtId}
          cityId={cityId.get()}
          getRowsCount={(value) => totalDistricts.set(value)}
          isInvalidRequest={isInvalidRequest}
        />
        {/* <DistrictSelectField readonly={readonly} isValid={isValid} cityId={cityId} districtId={districtId} /> */}
      </Grid>
      <Grid item>
        <TypeSelectField readonly={readonly} isValid={isValid} isInvalidRequest={isInvalidRequest} typeId={typeId} typeName={typeName} showVehicleButton={showVehicleButtonType} isActive={true} />
      </Grid>
      <Grid item>
        <SubtypeSelectField readonly={readonly} isValid={isValid} isInvalidRequest={isInvalidRequest} typeId={typeId} subtypeId={subtypeId} subTypeName={subTypeName} showVehicleButtonType={showVehicleButtonType} showVehicleButton={showVehicleButton} getTotalSubtypes={(value) => {
          if (totalSubtypes) {
            if(typeId.get() && (!value || subtypeId.get()) ){
              getEntryProcedures({
                typeId: typeId.get(),
                subtypeId: subtypeId.get(),
              }).then((response) => {
                response.length > 0 ? windowManager()['entryProcedure'].open.set(true) : windowManager()['entryProcedure'].open.set(false)
                windowManager()['entryProcedure'].procedure.set(response)
              }).catch(console.error)
            } else {
              windowManager()['entryProcedure'].open.set(false)
              windowManager()['entryProcedure'].procedure.set([])
            }
            return totalSubtypes.set(value ?? 0);
          }
          return 0;
        }}
        showVehicleModal
        />
      </Grid>
    </Grid>
  );
}
