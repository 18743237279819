/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import {
  getDeviceByStatus,
} from '../DispatchByTimeAndStatusCharts/services'
import PieChartMui from '~/components/PieChartMui'
import useSystemState from '~/features/System/stores/SystemState'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type Payload2Props = {
  readonly excludeDisconnected?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DeviceByTimeAndStatusCharts({
  loading,
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const statusData = useHookstate<ResponseProps>([])
  const closedStatusData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const payload2 = useHookstate<Payload2Props>({ excludeDisconnected: true })
  const statusDataTotal = useHookstate<number>(0)
  const closedStatusDataTotal = useHookstate<number>(0)
  const { getDeviceStatusColors } = useSystemState();

  useEffect(() => {
    statusDataTotal.set(0)
    closedStatusDataTotal.set(0)
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDeviceByStatus(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload2.get(),
      groupSetId,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData, index) => {
          statusDataTotal.set(statusDataTotal.get() + responseData.total)
          return { id: index, label: responseData.name, value: responseData.total, color: responseData.name == 'EM MISSÃO' ? '#004c8e' : getDeviceStatusColors(responseData.id).main }
        })
        return statusData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid container xs={12} sx={{ display: 'flex', paddingX: 3, paddingTop: 3 }}>
    
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <PieChartMui
          data={statusData.attach(Downgraded).get()}
          title={'Device Connected x Status*'}
          subtitle={`Total: ${Intl.NumberFormat("pt-BR").format(Number(statusDataTotal.get()))}`}
          info='* Excluindo dispositivos desconectados'
          typeTotal={'Status'}
        />
      </Grid>
    </Grid>
  )
}
