import React, { ChangeEvent } from 'react';
import { Grid, TextField, Tooltip, IconButton} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import InputMask from 'react-input-mask';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import { formatPhone } from '~/utils/format';
import { truncateText } from "~/utils/strings";
import EditIcon from '@mui/icons-material/Edit';
import { getOnlyNumbersInString } from '~/utils/strings';
import useSystemState from '~/features/System/stores/SystemState';

export default function EventInputRequesterOccurrence({ canEdit = true}) {
  const { translate } = useLang();
  const {systemConfig} = useSystemState();
  const { occurrence, occurrenceStatusActions } = useOccurrenceState();

  return <>

    {!occurrenceStatusActions().editingOccurrence.get() ? (
      <Grid
      item
      marginTop={2}
    >
      <TextField
        disabled={true}
        placeholder={translate('Address')}
        value={
          `${translate('Requester')}: ${occurrence().name?.get() ? truncateText(String(occurrence().name.get()), 11) : translate('Not informed')}\n\n${translate('Phone')}: ${occurrence().get()?.phone ? `${formatPhone(occurrence().get().phone)}` : translate('Not informed')}`
        }
        size='small'
        fullWidth
        multiline
        rows={3.5}
        InputProps={{
          endAdornment:
          <>{ canEdit ? (
            <IconButton
              onClick={() => occurrenceStatusActions().editingOccurrence.set(true)}
              sx={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                padding: '3px',
              }}>
              <EditIcon />
            </IconButton>
          ) : false }</>
        }}
      />
    </Grid>
    ) : (
      <>
        <Grid item>
          <TextField
            disabled={systemConfig().allowedEditRequesterDataOfOccurrence.get() === '0'}
            fullWidth
            label={translate('Requester')}
            size="small"
            value={occurrence()?.name.get() ? occurrence()?.name.get() : ''}
            onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
              occurrence()?.name.set(changeEvent.target.value)
            }}
          />
        </Grid>

        <Grid item marginTop={2}>
          <InputMask 
            mask={'(99) 99999-9999'}
            value={String(occurrence()?.phone.get() ? (occurrence()?.phone.get()) : '')}
            disabled={systemConfig().allowedEditRequesterDataOfOccurrence.get() === '0'}
            onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
              occurrence()?.phone.set(getOnlyNumbersInString(changeEvent.target.value))
            }}
            
          >
            <TextField
              fullWidth
              label={translate('Requester Contact')}
              size="small"
            />
          </InputMask>
        </Grid>
      </>
    )}
  </>
}