/* eslint-disable react/react-in-jsx-scope */
import { Grid, Typography } from "@mui/material";
import IReportData from "../../interfaces/IReportData";
import IReportColumn from "../../interfaces/IReportColumn";
import dayjs from "dayjs";
import EmptyReport from "../EmptyReport";
import { useLang } from "~/hooks/useLang";

export default function ReportDataBasic({ data, columns, indentation }: {
  readonly data: readonly IReportData[],
  readonly columns: readonly IReportColumn[],
  readonly indentation?: boolean,
}) {

  const { translate } = useLang();

  function sumOfGridColumns() {
    const initialSum = 0;
    const sumWithInitial = columns.reduce((acc, currentValue) => acc + currentValue.grid, initialSum)
    return sumWithInitial;
  }

  function checkStringData(value) {
    if(typeof value == 'string'){
      const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
      const isDate = value.length === 24 && regex.test(value)
      
      if (isDate) {
        const date = dayjs(value).format('DD/MM/YYYY')
        const hour = dayjs(value).format('HH:mm:ss')
        return `${date}` + ' ' + `${hour}`
      } else {
        return value
      }
    } else {
      return value
    }
  }

  return (
    // <Container fixed={false}>
    <Grid
      marginLeft={indentation ? 1.5 : 0}
      container
    // marginTop={1}
    >
      {
        data.length == 0 ?
          (<EmptyReport justify="center" />)
          : data.map((item, index) => {
            return (
              <Grid
                key={item.code}
                container
                direction="row"
                borderTop={index > 0 ? 1 : 0}

                item
                columns={sumOfGridColumns()}
                sx={{
                  "&:last-child": {
                    borderBottom: '1px solid black',
                    marginBottom: '16px',
                  }
                }}
              >
                {
                  columns?.map((col) => {
                    return (
                      <Grid
                        textAlign={col.alignment == 'center' ? 'center' : col.alignment == 'right' ? 'right' : 'left'}
                        key={col.id}
                        xs={col.grid}
                        item
                        sx={{
                          borderLeft: '1px solid black',
                          "&:last-child": {
                            borderRight: '1px solid black',
                          }
                        }}
                      >
                        <Typography
                          key={col.grid + col.id}
                          gridColumn={col.grid}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: col.alignment,
                            paddingRight: 0.5,
                            paddingLeft: col.alignment === 'left' ? 1 : 0,
                            paddingY: 0.5,
                            minHeight: '25px',
                            fontSize: 10,
                            wordBreak: "break-word",
                          }}
                        >
                          {checkStringData(item[col.id])}
                          {/* {typeof item[col.id]} */}
                        </Typography>
                      </Grid>
                    )
                  })}
              </Grid >
            )
          })
      }
    </Grid >
    // </Container>

  )
}