import { PostCommentResponse } from '~/features/Dispatch/stores/DispatchCommentState'
import { authFetch } from '~/services/fetch'

type IGetOccurrence = {
  readonly page: number
  readonly limit: number
  readonly filters: any
  readonly dispatchGroupIds?: any
  readonly visibleDispatchGroups?: boolean
  readonly createdByMe?: boolean
  readonly statusId?: number | null
  readonly keyWords?: string[] | null;
  readonly includeAllWords?: boolean;
}

export async function getOccurrenceOpenService({ page, limit, filters }) {
  try {
    const response = await authFetch({
      url: '/occurrence/get-opens',
      method: 'POST',
      data: {
        page: page,
        limit: limit,
        orders: [{ field: 'id', sort: 'desc' }],
        filters: filters,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getOccurrenceAllService({
  page,
  limit,
  filters,
  dispatchGroupIds,
  visibleDispatchGroups,
  createdByMe,
  statusId,
  keyWords,
  includeAllWords
}: IGetOccurrence) {
  try {
    const response = await authFetch({
      url: '/occurrence/get-all',
      method: 'POST',
      data: {
        key_words: keyWords,
        include_all_words: includeAllWords,
        page: page,
        limit: limit,
        orders: [{ field: 'id', sort: 'desc' }],
        filters: filters,
        dispatch_group_ids: dispatchGroupIds,
        visible_dispatch_groups: visibleDispatchGroups,
        created_by_me: createdByMe,
        status_id: statusId,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getContributersDevicesFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/dispatch/contributers/devices',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedVehicleFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-vehicle/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedPeopleFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-people/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedWeaponFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-weapon/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedDrugsFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-drugs/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedOrganizationFromOccurrenceService(
  dispatchId
) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-organization/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getAssociatedObjectsFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-object/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function storeOccurrenceCommentService({ id, comment }) {
  try {
    const { data } = await authFetch({
      url: '/occurrence/comment/store',
      method: 'POST',
      data: {
        occurrence_id: id,
        comment: comment,
      },
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getAllOccurrenceCommentService({ id, order = 'DESC' }) {
  try {
    const { data } = await authFetch({
      url: `/occurrence/${id}/comments`,
      method: 'POST',
      data: {
        order: order,
      },
    })

    if (!data) return []

    return data
  } catch (error) {
    throw error
  }
}

export async function getAllOccurrenceCommentServiceByDeviceId({
  id,
  order = 'DESC',
}) {
  try {
    const { data } = await authFetch({
      url: `/dispatch/comments`,
      method: 'POST',
      data: {
        dispatch_id: id,
        order: order,
      },
    })

    if (!data) return []

    return data
  } catch (error) {
    throw error
  }
}

export async function getOccurrenceById({ id }) {
  try {
    const { data } = await authFetch({
      url: `/occurrence/${id}`,
      method: 'GET',
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getDispatchesForOccurrenceById({ id }) {
  try {
    const { data } = await authFetch({
      url: `/occurrence/${id}/get-dispatches`,
      method: 'GET',
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function reopenOccurrence({ id }) {
  try {
    const response = await authFetch({
      url: '/occurrence/reopen',
      method: 'POST',
      data: {
        occurrence_id: id,
      },
    })

    return response
  } catch (error) {
    throw error
  }
}

export async function nearOccurrences({ latitude, longitude }) {
  try {
    const { data } = await authFetch({
      url: '/occurrence/near-occurrences',
      method: 'POST',
      data: {
        latitude: latitude,
        longitude: longitude,
      },
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function updateOccurrenceByIdService({
  id,
  addressReference,
  location,
  cityId,
  districtId,
  latitude,
  longitude,
  name,
  phone,
  typeId,
  subtypeId,
}) {
  try {
    const { data } = await authFetch({
      url: `/occurrence/${id}`,
      method: 'PUT',
      data: {
        address_reference: addressReference,
        location: location,
        city_id: cityId,
        district_id: districtId,
        latitude: latitude,
        longitude: longitude,
        name: name,
        phone: phone,
        type_id: typeId,
        subtype_id: subtypeId,
      },
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getInfoDispatch({ code }) {
  try {
    const response = await authFetch({
      url: `/dispatch/get-by-code`,
      method: 'POST',
      data: {
        code: code,
      },
    })

    return response
  } catch (error) {
    throw error
  }
}

export async function getTimeLineDispatch({ dispatchId }) {
  try {
    const response = await authFetch({
      url: `/dispatch/status-timeline`,
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response
  } catch (error) {
    throw error
  }
}

export async function requestClosureOccurrence({ occurrenceId, comment }) {
  try {
    const response = await authFetch({
      url: '/occurrence/request-closure',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId,
        comment: comment,
      },
    })

    return response
  } catch (error) {
    throw error
  }
}

export async function getVideomonitoringReport(dispatchId) {
  try {
    const response = await authFetch({
      url: '/dispatch/video-surveillance-reports',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        orders: [{ field: 'created_at', sort: 'desc' }],
        without_pagination: true,
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export async function getAssociatedAnimalFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-animal/all-from-occurrence',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getUnifiedDispatchFromOccurrenceService(dispatchId) {
  try {
    const response = await authFetch({
      url: '/dispatch/current-unifications',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        without_pagination: true,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}