/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { Box, Button, Card, Typography, Tooltip, ListItemButton, ListItemText } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import useUserState from '~/features/User/stores/UserState';
import { useHistory } from 'react-router-dom';

export default function AuditMenu({ activeMenu, handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const history = useHistory()

  return (
    <>
      {(verifyPermission(['interest_point_log_index']) || verifyPermission(['occurrence_associated_search_log_index'])
            ) && (
      <Box>
        <Tooltip title={translate('Audit')} placement="bottom">
          <Button className={`main-menu ${activeMenu == 'audit' ? 'active' : ''}`} onClick={() => handleMenuClick('audit')}>
            <RecentActorsIcon />
          </Button>
        </Tooltip>
        <Card className='submenu-box' sx={{ borderRadius: 0, display: activeMenu == 'audit' ? 'block' : 'none' }}>
          <Typography variant='h4'>
            <RecentActorsIcon />
            {translate('Audit')}
          </Typography>

          {verifyPermission(['occurrence_associated_search_log_index']) && (
          <Tooltip
            title={translate('Audit')}
            placement="right"
          >
            <ListItemButton
              onClick={() => {
                handleMenuClick('audit')
                history.push('/audit-qualifiers')
              }}
            >
              <ListItemText
                primary={translate('Qualificators Audit')}
              />
            </ListItemButton>
          </Tooltip>
          )}
          {verifyPermission(['interest_point_log_index']) && (
          <Tooltip
            title={translate('Audit')}
            placement="right"
          >
            <ListItemButton
              onClick={() => {
                handleMenuClick('audit')
                history.push('/audit-interest-point')
              }}
            >
              <ListItemText
                primary={translate('Interest Point Audit')}
              />
            </ListItemButton>
          </Tooltip>
           )}
        </Card>
      </Box>
       )}

    </>
  )
}
