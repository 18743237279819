import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getAllClosedStatus } from './services';
import { ClosedStatusResponse } from './interface';

interface FilterAutocompleteClosedStatusProps {
    readonly onClosedStatusIdChange: (value: number | null) => void;
    readonly closedStatusClassId?: number | null;
    readonly hideCode?: boolean;
    readonly closedStatusId?: number | null;
    readonly disabled?: boolean
    readonly error?: boolean;
    readonly isActive?: boolean;
    readonly label?: string;
}

export default function FilterAutocompleteClosedStatus({ disabled, closedStatusId, onClosedStatusIdChange, hideCode = false, closedStatusClassId, error, isActive = false, label }: FilterAutocompleteClosedStatusProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly ClosedStatusResponse[]>([]);

    const [optionsFilter, setOptionsFilter] = useState<readonly ClosedStatusResponse[]>([]);

    const [value, setValue] = useState<ClosedStatusResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getAllClosedStatus().then(closedStatus => {
            setOptions(closedStatus);
            setOptionsFilter(closedStatus);
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!closedStatusId) {
            setValue(null);
        } else {
            const option = options.find((option) => option.id == closedStatusId);
            setValue(option ?? null);
        }

    }, [options, closedStatusId])

    useEffect(() => {
        if (closedStatusClassId) {
            if (options) {
                const filter = options.filter(closedStatus => closedStatus.closed_status_class_id === closedStatusClassId) ?? [];
                setOptionsFilter(filter.length ? filter : []);
            } else {
                setOptionsFilter(options ?? []);
            }
        } else {
            setOptionsFilter(options ?? []);
        }

    }, [closedStatusClassId, options])

    return (
        <FormControl fullWidth size='small'>
            <Autocomplete
                id='filter-types'
                disabled={!!closedStatusClassId && !optionsFilter.length || disabled}
                options={optionsFilter}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.acronym ? `[${option.acronym}] ${option.name}` : ''}
                onChange={(_, closedStatus) => {
                    setValue(closedStatus ?? null);
                    onClosedStatusIdChange(closedStatus?.id ?? null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error ? error : false}
                        label={translate(label ? label : 'Closed Status')}
                    />
                )}
            />
        </FormControl>
    )
}