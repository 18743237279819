import React, { useEffect, useRef } from 'react';
import { createState, useState } from '@hookstate/core';
import { GridRowModel } from '@mui/x-data-grid';
import { EmptyStatement } from 'typescript';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import useEntryState from './EntryState';

interface defaultActions {
  loading: boolean,
  retryConnect: boolean,
}
interface DispatchProps {
  readonly id: null | number,
  readonly agency_id?: null | number,
  readonly area_id?: null | number,
  readonly type_id?: null | number,
  readonly subtype_id?: null | number,
  readonly dispach_group_id?: null | number,
  readonly "area:name"?: string;
  readonly "area:acronym"?: string;
  readonly "agency:name"?: string;
  readonly "agency:acronym"?: string;
  readonly "dispach_group:name"?: string;
  readonly "dispach_group:acronym"?: string;
  readonly "manual_setted"?: boolean;
}

const entryDispatch = createState<ReadonlyArray<DispatchProps>>([]);
const loading = createState(false)

const defaultActions: defaultActions = {
  loading: false,
  retryConnect: false,
}


const useEntryDispatchState = () => {
  const entryDispatchState = useState(entryDispatch);
  const loadingDispatchConfig = useState(loading)
  const loadingData = useState(defaultActions.loading)
  const retryConnect = useState(defaultActions.retryConnect)

  return ({
    loadingData: loadingData,
    retryConnect: retryConnect,
    entryDispatch: () => entryDispatchState,
    loadingDispatchConfig: loadingDispatchConfig,
    getEntryDispatch: async (entryId:number | null) => {
      retryConnect.set(false)
      loadingData.set(true)
      entryDispatchState.set([])

      if (!entryId) {
        loadingData.set(false)
        return false;
      }
      
      if(entryId != null){
        await authFetch({
          url: '/entry/get-dispatch-config',
          method: 'POST',
          data: {
            "idRef": entryId
          }
        }).then(({data}) => {
          if (!data) {
            entryDispatchState.set([])
            return false
          }
          entryDispatchState.set(data);
          return true
        })
        .catch(() => retryConnect.set(true))
        .finally(() => {
          loadingData.set(false)
          loadingDispatchConfig.set(false)
        });

      }
    },
    resetEntryDispatch: () => {
      entryDispatchState.set([]);
    },
  })
}

export default useEntryDispatchState;