/* eslint-disable max-lines-per-function */
import { useHookstate } from '@hookstate/core'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, IconButton } from '@mui/material'
import { DataGrid, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid'
import { ptBR } from '@mui/x-data-grid/locales'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonRound from '~/components/ButtonRound'
import ConfirmDialog from '~/components/ConfirmDialog'
import Layout from '~/components/Layout'
import InternalContent from '~/components/Layout/components/InternalContent'
import InternalHeader from '~/components/Layout/components/InternalHeader'
import { useLang } from '~/hooks/useLang'
import AreaConfigFilters from '../components/AreasConfigFilters'
import { deleteAreaConfig, listAreaConfig } from '../services'
import DialogDeleteMultipleConfigArea from '../components/DialogDeleteMultipleConfigArea'

export default function Index() {
  const { translate } = useLang()
  const history = useHistory()

  const [rows, setRows] = React.useState<readonly GridRowModel[]>([])

  const rowId = useHookstate<string>('')
  const loading = useHookstate(true)
  const rowCount = useHookstate(0)
  const forceRefresh = useHookstate(false)
  const openDialog = useHookstate(false)
  const page = useHookstate<any>(0)
  const limit = useHookstate(10)

  const agencyId = useHookstate<number | null>(null)
  const areaId = useHookstate<number | null>(null)
  const deviceId = useHookstate<number | null>(null)
  const groupId = useHookstate<number | null>(null)

  const typeId = useHookstate<number | null>(null)
  const subtypeId = useHookstate<number | null>(null)
  const configIds = useHookstate<GridRowId[]>([])
  const showCloseButton = useHookstate(false)
  const openModalResultMultipleDeleteAreaConfig = useHookstate(false)
  const rowsSelected = useHookstate<(GridRowModel | undefined)[]>([])

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  useEffect(() => {
    const selectedRowsData = configIds
      .get()
      .map((id) => rows.find((row) => row.id === id))
    rowsSelected.set(selectedRowsData ? selectedRowsData : [])
    showCloseButton.set(configIds.get().length ? true : false)
  }, [configIds.get()])

  const handleDialogOpen = () => {
    openDialog.set(true)
  }

  const handleDelete = (id: string) => {
    handleDialogOpen()
    rowId.set(id)
  }

  const clearFilters = () => {
    deviceId.set(null)
    agencyId.set(null)
    groupId.set(null)
    typeId.set(null)
    subtypeId.set(null)
    areaId.set(null)
    forceRefresh.set(!forceRefresh.get())
  }

  const handleDialogCloseFunction = () => {
    deleteAreaConfig(rowId.get()).then(() => {
      forceRefresh.set(!forceRefresh.get())
      handleDialogClose()
    })
  }

  const handleDialogClose = () => {
    openDialog.set(false)
  }

  const columns: GridColDef[] = [
    { field: 'id' },
    {
      field: 'agency',
      headerName: translate('Agency:name'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'area',
      headerName: translate('Area'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'dispatch_group',
      headerName: translate('Dispatch Group'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'type',
      headerName: translate('Type'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'subtype',
      headerName: translate('Subtype'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   getActions: (params: GridRowParams) => {
    //     return [
    //       <Tooltip key={1} title={translate('Delete a Area Config')}>
    //         <span>
    //           <GridActionsCellItem
    //             key={0}
    //             icon={<DeleteIcon />}
    //             onClick={() => handleDelete(params.row.id)}
    //             label="Delete"
    //           />
    //         </span>
    //       </Tooltip>,
    //     ]
    //   },
    // },
  ]

  const handleCreateClick = () => {
    history.push('/area-config/create')
  }

  useEffect(() => {
    loading.set(true)
    listAreaConfig(page.get(), limit.get(), [
      {
        field: 'agency_id',
        operator: 'equals',
        value: agencyId.get(),
      },
      { field: 'area_id', operator: 'equals', value: areaId.get() },
      { field: 'type_id', operator: 'equals', value: typeId.get() },
      {
        field: 'subtype_id',
        operator: 'equals',
        value: subtypeId.get(),
      },
      {
        field: 'dispatch_group_id',
        operator: 'equals',
        value: groupId.get(),
      },
    ])
      .then(({ data: { data, rowsCount } }) => {
        setRows(
          data.map((row) => ({
            id: row?.id,
            agency: row?.agency?.name,
            area: row?.area?.acronym,
            dispatch_group: row?.dispatch_group?.name,
            subtype: row?.subtype ? `[${row?.subtype?.code}] ${row?.subtype?.name}` : '',
            type: `[${row?.type?.code}] ${row?.type?.name}`,
          }))
        )
        rowCount.set(rowsCount)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [forceRefresh.get()])

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        title={translate(`Area Configuration`)}
        action={
          <ButtonRound onClick={handleCreateClick}>
            <AddIcon color="primary" />
          </ButtonRound>
        }
      />
      <InternalContent expandButton={false}>
        <AreaConfigFilters
          areaId={areaId}
          agencyId={agencyId}
          deviceId={deviceId}
          groupId={groupId}
          typeId={typeId}
          lockFields={false}
          subtypeId={subtypeId}
          searchButton={() => {
            forceRefresh.set(!forceRefresh.get())
          }}
          clearFilters={clearFilters}
        />
        <Grid xs={12} container>
          {showCloseButton.get() && (
            <IconButton
              title={translate('Area Config Delete')}
              onClick={() => openModalResultMultipleDeleteAreaConfig.set(true)}
              size="large"
              sx={{ marginLeft: 1 }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          <Grid item xs={12} sx={{ padding: 1 }}>
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              pageSizeOptions={[10]}
              columns={columns}
              columnVisibilityModel={{
                id: false,
              }}
              autoHeight={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pagination
              rowCount={rowCount.get()}
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              loading={loading.get()}
              onPaginationModelChange={(pageChange) => {
                page.set(pageChange.page)
                forceRefresh.set(!forceRefresh.get())
              }}
              disableColumnSelector
              disableColumnMenu
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                configIds.set(ids)
              }}
            />
          </Grid>
          <ConfirmDialog
            handleAgree={handleDialogCloseFunction}
            handleClose={handleDialogClose}
            open={openDialog}
            title={translate('Confirm delete?')}
          />
          <DialogDeleteMultipleConfigArea
            openDialog={openModalResultMultipleDeleteAreaConfig}
            onClose={() => {}}
            rowsSelected={rowsSelected}
            configIds={configIds.get()}
            clearFilters={() => forceRefresh.set(!forceRefresh.get())}
          />
        </Grid>
      </InternalContent>
    </Layout>
  )
}
