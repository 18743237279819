import { AxiosError } from 'axios';
import { authFetch } from '~/services/fetch';
import { IParamsTable, IUpdateProps } from '../../../interfaces';


export async function getCircunstanceById(id: IUpdateProps) {
    const response = await authFetch({
        url: `/circunstance/${id}`,
        method: 'GET' 
    });
    return response.data;
}

export async function getCircunstance({
  filters = [],
  limit = 100000,
  orders = [
      {field: 'name', sort: 'asc'}
  ]
}: IParamsTable = {}) {
  const response = await authFetch({
      url: `/circunstance`,
      method: 'POST',
      data: { 
          limit,
          filters,
          orders
      },
      
  });
  return response.data.data;
}
