/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { getEntryByType } from '../../services'
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic'
import PieChart from '~/components/PieChart'
import ColumnChartHorizontal from '~/components/ColumnChartMui/HorizontalChart'
import { useLang } from '~/hooks/useLang'

export default function EntryByTypeChart({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed = false,
  showTitle = false,
  refresh,
  loading,
  cityId,
}: IEntryStatisticGraphProps) {
  const data = useHookstate([])
  const { translate } = useLang()
  const typeTotal = useHookstate<number>(0)

  useEffect(() => {
    loading.set(true)
    typeTotal.set(0)
    getEntryByType({
      agencyId,
      deviceId,
      typeId,
      subtypeId,
      startDate,
      endDate,
      exludeClosed,
      cityId,
    })
      .then((response) => {
        const result = response.map((data) => {
          typeTotal.set(typeTotal.get() + Number(data.total))
          return ({
          item: data.name,
          value: data.total,
        })})
        return data.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <ColumnChartHorizontal
      data={data.attach(Downgraded).get()}
      title={`${translate('Type Chart')}`}
      subtitle={`Total de Atendimentos: ${Intl.NumberFormat('pt-BR').format(
        Number(typeTotal.get())
      )}`}
      typeTotal={'Types'}
    />
  )
}
