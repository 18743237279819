import { Downgraded, useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import WidgetCreate from '~/components/WidgetCreate';
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic';
import { getEntryByStatus } from '../../services';

export default function EntryTotalByStatusWidget({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed = false, showTitle = false, refresh, loading, cityId }: IEntryStatisticGraphProps) {
  const data = useHookstate([]);

  useEffect(() => {
    loading.set(true)
    getEntryByStatus({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed, cityId }).then((response) => {
      const result = response.map((data) => {
        return data.name == 'Ocorrência' ? { id: 'occurrence', name: data.name, total: data.total } : { id: data.id, name: data.name, total: data.total }
      });
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <WidgetCreate data={data.attach(Downgraded).get()} title={`${showTitle || data.get().length ? 'Entry' : ''}`} />
  )
}