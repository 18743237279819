import { useHookstate } from '@hookstate/core'
import React, { useEffect } from 'react'
import CrudIndex from '~/components/CrudIndex'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'

export default function Index() {
  const { translate } = useLang()
  const outOfServiceReason = useHookstate<string | null>(null)
  const lockFields = useHookstate<boolean>(false)
  const loading = useHookstate<boolean>(false)
  const usual = useHookstate<boolean | null>(null)
  const forceRefresh = useHookstate<boolean>(false)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const outOfServiceReasonFilters = [
    {
      type: 'name',
      variable: outOfServiceReason,
    },
    {
      type: 'usual',
      variable: usual
    }
  ]

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model="device/out-of-service-reason"
        title="Device out of service reason"
        booleanField={['usual']}
        forceOrderColumns={['name', 'usual']}
        createTitleComplement="Reason"
        canView
        showInViewMore={['name', 'usual', 'created_at']}
        viewTitle="Out of service reason"
        disableColumnsFilters
        lockFields={lockFields.get()}
        loading={loading}
        clearFilters={() => {
          outOfServiceReason.set('')
          usual.set(null)
          forceRefresh.set(!forceRefresh.get())
        }}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        forceRefresh={forceRefresh.get()}
        filters={outOfServiceReasonFilters}
      />
    </Layout>
  )
}
