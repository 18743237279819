import React, { useEffect, useState } from 'react'
import { useHookstate, type State } from '@hookstate/core'
import { Autocomplete, Box, Chip, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import IType from '~/features/Type/interfaces/IType'
import { getAllTypes } from '~/features/Type/services'

interface FilterAutoCompleteTypesWithCategoryProps {
  readonly onTypeChange: (value) => void
  readonly onSubtypesChange: (value) => void;
  readonly categoryId: number | number[] | null;
  readonly hideCode?: boolean
  readonly typeId?: readonly number[]
  readonly subtypes: readonly number[];
  readonly refTypeIds?: State<number[] | []>
  readonly disabled?: boolean
  readonly error?: boolean
  readonly limitedTags?: number
  readonly itemTypeRemoved?: State<boolean>;
  readonly itemSubtypeRemoved?: State<boolean>;
}

// eslint-disable-next-line max-lines-per-function
export default function FilterAutoCompleteTypesWithCategory({
  disabled,
  typeId,
  subtypes,
  hideCode = false,
  categoryId,
  error,
  refTypeIds,
  limitedTags = 5,
  itemTypeRemoved,
  itemSubtypeRemoved,
  onTypeChange,
  onSubtypesChange
}: FilterAutoCompleteTypesWithCategoryProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly IType[] | undefined>([])
  const [optionsFilter, setOptionsFilter] = useState<readonly IType[]>([])
  const [value, setValue] = useState<IType[]>([])
  const loading = useHookstate(false)

  useEffect(() => {
    getAllTypes().then((types) => {
      const resTypes = types.map((type) => ({
        ...type,
        displayName: hideCode ? `${type.name}` : `[${type.code}] ${type.name}`,
        category_id: type.category_id ?? null,
      }))

      setOptions(resTypes)
      setOptionsFilter(resTypes)
    })
  }, [])

  useEffect(() => {
    if (!typeId?.length) {
      setValue([])
      onSubtypesChange([])
    }
  }, [typeId?.length])

  useEffect(() => {
    if (!subtypes.length && (categoryId || (Array.isArray(categoryId) && categoryId.length))) {
      setOptionsFilter(options ?? [])
    }
  }, [subtypes.length])

  useEffect(() => {

    if (categoryId) {
      if (Array.isArray(categoryId) && categoryId.length > 0 && options) {
        const filter = options.filter((typeMap) => typeMap.categories?.find((category) => categoryId.includes(category.id))) ?? []
        setOptionsFilter(filter.length ? filter : options)

      } else if (Array.isArray(categoryId) && categoryId.length == 0 && value && value.length) {
        setOptionsFilter(options ?? [])

      } else if (!Array.isArray(categoryId) && categoryId && options) {
        const filter = options.filter((typeMap) => typeMap.categories?.find((category) => categoryId == category.id)) ?? []
        setOptionsFilter(filter.length ? filter : [])
      } else if (Array.isArray(categoryId) && categoryId.length == 0 && !value.length) {
        setOptionsFilter(options ?? [])
      }
    } else {
      setOptionsFilter(options ?? [])
      refTypeIds?.set([])
    }

  }, [categoryId])


  const removeDuplicity = (refIds) => {
    const withoutDuplicity = refIds.filter((item, index) => refIds.indexOf(item) === index)
    return withoutDuplicity
  }


  useEffect(() => {
    if (refTypeIds?.get().length) {
      if (categoryId || (Array.isArray(categoryId) && categoryId.length > 0)) {
        const filterType = options?.filter((typeMap) => refTypeIds?.get().find((item) => item == Number(typeMap.id))) ?? []

        if (!value.length) {

          setValue(filterType)
        } else {
          if (itemSubtypeRemoved?.get()) {
            const filterType = options?.filter((typeMap) => refTypeIds?.get().find((item) => item == Number(typeMap.id))) ?? []

            setValue(filterType)
            onTypeChange(filterType.map((itemId) => itemId.id))

            itemSubtypeRemoved.set(false)
          } else {
            const arrayConcat = filterType.concat(value)
            const arrayContatWithoutDuplicity = removeDuplicity(arrayConcat)

            setValue(arrayContatWithoutDuplicity)
          }
        }
      }
    }

  }, [refTypeIds?.get()])

  const handleAddType = ({ type }) => {
    if (type.length) {
      setValue(type)

      if (itemTypeRemoved?.get()) {
        const updateTypeIds = type.map((type) => type.id) ?? []
        refTypeIds?.set(updateTypeIds)
        onTypeChange(updateTypeIds)

      } else if (categoryId || (Array.isArray(categoryId) && categoryId.length > 0)) {
        const allTypes = type.concat(value)

        const typeIdsForSending = removeDuplicity(allTypes)

        const updateTypeIds = typeIdsForSending.map((type) => type.id) ?? []

        refTypeIds?.set(updateTypeIds)
        onTypeChange(updateTypeIds)
      } else {
        onTypeChange(type.map((itemType) => itemType.id ?? []))
      }
    } else {
      onTypeChange([])
      setValue([])
      refTypeIds?.set([])
      onSubtypesChange([])
    }
  }

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        renderTags={(values, getTagProps, owner) => (
          <Box sx={{ maxHeight: "110px", overflowY: 'auto' }}>
            {values.map((value, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                label={owner.getOptionLabel(value)}
                size={owner.size}
                {...getTagProps({ index })}
                {...owner.ChipProps}
              />
            ))}
          </Box>
        )}
        ListboxProps={{ style: { maxHeight: '15rem' } }}
        id="filter-types"
        multiple
        limitTags={limitedTags}
        disabled={disabled}
        options={optionsFilter}
        value={value}
        filterSelectedOptions
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size="small"
        disableCloseOnSelect
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName ?? option.name ?? ''}
        onChange={(_, type) => {
          if (value && value.length > type.length) {
            itemTypeRemoved?.set(true)
          }

          handleAddType({ type: type })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Types')}
          />
        )}
      />
    </FormControl>
  )
}
