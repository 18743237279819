import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import { useParams } from 'react-router-dom';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';
import UserGroup from '../Interfaces/UserGroup';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<UserGroup>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string' },
    {
      name: 'permissions',
      type: 'transferList',
      options: { firstListUrl: `/group/${useParams<ICrudEditParams>().id}/permissions`, secondListUrl: '/permission' },
      placeholder: 'Permissions',
      showColumnText: 'description',
    },
  ];

  return (
    <CrudEdit
      model={'group'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Users group successfully updated!'}
      title={'Edit Users group'}
      getValues={getValues}
    />
  )
}



