import React, { useState } from 'react';
import { Box } from '@mui/material';
import ManagementMenu from '~/features/Management/components/ManagementMenu';
import AdministrationMenu from './components/AdministrationMenu';
import DispatchMenu from './components/DispatchMenu';
import EntryMenu from './components/EntryMenu';
import OperationMenu from './components/OperationMenu';
import ShowVersionAppMenu from './components/ShowVersionAppMenu';

import './styles.scss';
import ReportMenu from '~/features/Report/components/ReportMenu';
import VideoMonitoringMenu from './components/VideoMonitoringMenu';
import InterestPointMenu from './components/InterestPoint';
import AuditMenu from './components/AuditMenu';

export default function SidebarMenu() {
  const [activeMenu, setMenuItem] = useState<string>('')

  const handleMenuClick = (menuItem: string) => {
    setMenuItem(activeMenu != menuItem ? menuItem : '')
  }

  return (
    <Box
      className="sidebar-menu"
      sx={{
        height: 'calc(100vh - 80px)',
        // height: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >

      <Box>
        <EntryMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <DispatchMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <VideoMonitoringMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <InterestPointMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick}/>

        <OperationMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <ManagementMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <ReportMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />

        <AuditMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />
        
        <AdministrationMenu activeMenu={activeMenu} handleMenuClick={handleMenuClick} />


      </Box>
      {
        <Box>
          <ShowVersionAppMenu />
        </Box>}

    </Box>
  )
}
