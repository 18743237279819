import React from 'react'
import { CircularProgress, Grid, IconButton } from '@mui/material'
import { SaveAs } from '@mui/icons-material'
import { useLang } from '~/hooks/useLang'
import useEntryState from '~/features/Entry/stores/EntryState'
import useSystemState from '~/features/System/stores/SystemState'
import useEntryDispatchState from '~/features/Entry/stores/EntryDispatchState'
interface SaveButtonProps {
  readonly canSave?: boolean;
  readonly save?: VoidFunction;
  readonly isDraggable?: boolean
}


export default function SaveButton({ canSave, save, isDraggable = false }: SaveButtonProps) {
  const { translate } = useLang()
  const { safeBikeCanSave } = useEntryState()
  const { windowManager } = useSystemState()
  const { loadingDispatchConfig } = useEntryDispatchState()


  return (
    <Grid item>
      <IconButton
        title={translate('Save')}
        disabled={
          !canSave || safeBikeCanSave.get() || loadingDispatchConfig.get()
        }
        onClick={() => {
          save && !safeBikeCanSave.get()
            ? save()
            : windowManager()['VehicleModal'].open.set(true)
        }}
      >
        {loadingDispatchConfig.get() ? <CircularProgress size={20} /> : <SaveAs /> }
      </IconButton>
    </Grid>
  )
}
