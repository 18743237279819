/* eslint-disable id-length */
/* eslint-disable max-lines-per-function */
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import InternalHeader from '~/components/Layout/components/InternalHeader'
import InternalContent from '~/components/Layout/components/InternalContent'
import StationCardMonitor from '~/features/Entry/components/StationCardMonitor'
import { Responsive, WidthProvider as widthProvider } from 'react-grid-layout'
import {
  deleteTag,
  getManagentStationsEntryService,
  getManagentStationsTagService,
  saveStationsManagementTags,
  saveStationsPositions,
} from '~/features/Entry/services'
import { Downgraded, useHookstate, useState } from '@hookstate/core'
import { Button, CircularProgress, Grid } from '@mui/material'
import notify from '~/utils/notify'
import ConfirmDialog from '~/components/ConfirmDialog'
import CreateTag from '../components/CreateTag'
import StationCardDivisor from '../components/StationCardDivisor'
import { IDivisors } from '../interfaces/IDivisors'

const ResponsiveGridLayout = widthProvider(Responsive)

interface ILayout {
  readonly i: string
  readonly h: number
  readonly w: number
  readonly x: number
  readonly y: number
}

export default function ManagementStations() {
  const { translate } = useLang()
  const divisors = useHookstate<readonly IDivisors[]>([])
  const divisorsListOpen = useHookstate(false)
  const divisorName = useState('')
  const forceRefresh = useHookstate(false)
  const openDialog = useHookstate(false)
  const loadingDeleteButton = useHookstate(false)
  const removedTag = useHookstate<IDivisors>({
    name: '',
    position_x: 0,
    position_y: 0,
    width: 0,
    height: 0,
  })
  const tagIndex = useHookstate<number | null>(null)
  const newDivisors = useHookstate<readonly IDivisors[]>([])
  const data = useHookstate<any[]>([])
  const loading = useHookstate(false)

  const loadData = async () => {
    loading.set(true)

    await getManagentStationsEntryService().then((station) => {
      getManagentStationsTagService().then((tag) => {
        data.set([...station, ...tag].sort((actual, next) => {
          if (Number(actual.position_x) > Number(next.position_x)) {
            return -1
          }

          if (Number(actual.position_x) < Number(next.position_x)) {
            return 1
          }

          if (Number(actual.position_y) > Number(next.position_y)) {
            return -1
          }

          if (Number(actual.position_y) < Number(next.position_y)) {
            return 1
          }

          return 0
        }))
        divisors.set(tag)
      })
    })

    loading.set(false)
  }

  useEffect(() => {
     loadData()
  }, [forceRefresh.get()])

  const handleChangeLayout = (newLayout: ILayout[]) => {
    newLayout.map((newItem) => {
      const type = newItem.i.split('-')
      if (type[0] == 'station') {
        data[type[1]].station.position_x.set(Number(newItem.x))
        data[type[1]].station.position_y.set(Number(newItem.y))
        data[type[1]].station.width.set(Number(newItem.w))
        data[type[1]].station.height.set(Number(newItem.h))
      }

      if (type[0] == 'divisors') {
        data[type[1]].position_x.set(Number(newItem.x).toFixed(1))
        data[type[1]].position_y.set(Number(newItem.y).toFixed(1))
        data[type[1]].width.set(Number(newItem.w))
        data[type[1]].height.set(Number(newItem.h))
      }

      if (type[0] == 'newdivisors') {
        newDivisors[type[1]].position_x.set(Number(newItem.x).toFixed(1))
        newDivisors[type[1]].position_y.set(Number(newItem.y).toFixed(1))
        newDivisors[type[1]].width.set(Number(newItem.w))
        newDivisors[type[1]].height.set(Number(newItem.h))
      }
    })
  }

  const handleSaveChange = async () => {
    loading.set(true)
    const newStationsPositions = data.get().filter((item) => item.station).map(item => item.station)
    const newTagsPositions = data.get().filter((item) => !item.station)
    
    
    
    try {
      await saveStationsPositions(newStationsPositions).then(() =>
        notify({
          message: `${translate('Stations position saved successfully!')}`,
          type: 'success',
        })
      )

      
      await saveStationsManagementTags(newTagsPositions)
        .then(() =>
          notify({
            message: `${translate('Tags position saved successfully!')}`,
            type: 'success',
          })
        )
    } catch {
      notify({
        message: `${translate('Something went wrong! Try again')}`,
        type: 'error',
      })
    } finally {
      newDivisors.set([])
      loadData()
    }
  }

  const handleAgreeDeleteTag = (olderTag) => {
    try {
      if (olderTag.id) {
        deleteTag(removedTag.attach(Downgraded).get().id).finally(() =>
          forceRefresh.set(!forceRefresh.get())
        )
      } else {
        const newTags = newDivisors
          .attach(Downgraded)
          .get()
          .filter((tag) => tag != olderTag)
        newDivisors.set(newTags)
      }
      notify({
        message: `${translate('Tag delete successfully!')}`,
        type: 'success',
      })
    } catch {
      notify({
        message: `${translate('Something went wrong! Try again')}`,
        type: 'error',
      })
    }

    openDialog.set(false)
  }

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        title={translate(`Management Stations`)}
        action={
          <Grid sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              disabled={false}
              onClick={() => divisorsListOpen.set(true)}
            >
              {translate('Tags Management')}
            </Button>
            <Button variant="contained" onClick={() => handleSaveChange()}>
              {translate('Save')}
            </Button>
          </Grid>
        }
      />

      <InternalContent title={`${translate('Filters')}`} expandButton={false}>
        {loading.get() ? 
        <Grid xs={12} display={'flex'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Grid>
         : <ResponsiveGridLayout
          className="layout"
          compactType="vertical"
          mounted={false}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 8, sm: 6, xs: 4, xxs: 2 }}
          onLayoutChange={(currentLayout) => handleChangeLayout(currentLayout)}
        >
          {data.get().map((item, index) => {
            if (item.station) {
              return (
                <div
                  key={'station-' + index}
                  data-grid={{
                    x: Number(item.station.position_x),
                    y: Number(item.station.position_y),
                    w: Number(item.station.width),
                    h: Number(item.station.height),
                    minH: 1,
                    maxH: 1,
                  }}
                >
                  <StationCardMonitor
                    station={item.station}
                    user={item.user}
                    stationIsOnline={item.station_is_online}
                  />
                </div>
              )
            } else {
              return (
                <div
                  key={'divisors-' + index}
                  data-grid={{
                    x: Number(item.position_x),
                    y: Number(item.position_y),
                    w: Number(item.width),
                    h: Number(item.height),
                    minH: 0.3,
                    maxH: 0.3,
                  }}
                >
                  <StationCardDivisor divisorName={item.name} />
                </div>
              )
            }
          })}
          {newDivisors.get().map((divisor, index) => (
            <div
              key={'newdivisors-' + index}
              data-grid={{
                x: Number(divisor.position_x),
                y: Number(divisor.position_y),
                w: Number(divisor.width),
                h: Number(divisor.height),
                minH: 0.3,
                maxH: 0.3,
              }}
            >
              <StationCardDivisor divisorName={divisor.name} />
            </div>
          ))}
        </ResponsiveGridLayout>}
      </InternalContent>
      <CreateTag
        divisors={divisors}
        divisorsListOpen={divisorsListOpen}
        divisorName={divisorName}
        openDialog={openDialog}
        removedTag={removedTag}
        tagIndex={tagIndex}
        newDivisors={newDivisors}
        handleSaveChange={handleSaveChange}
        data={data}
      />
      <ConfirmDialog
        handleAgree={() =>
          handleAgreeDeleteTag(removedTag.attach(Downgraded).get())
        }
        handleClose={() => openDialog.set(false)}
        open={openDialog}
        title={translate('Confirmation')}
        content={`${translate('Confirm the delete of the tag')} "${
          removedTag.attach(Downgraded).get().name
        }"?`}
        loading={loadingDeleteButton}
        AgreeLabel={translate('Ok')}
      />
    </Layout>
  )
}
