/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import useReportState from '../../../stores/ReportState'
import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GenerateReportButton from '../../../components/GenerateReportButton'
import FilterAutocompleteContactCenter from '~/components/FilterAutocompleteContactCenter'
import ExportButtonCSV from '~/features/Report/components/ButtonExportCSV'
import { downloadReportCSV } from '~/features/Report/services/report'
import { makeRequestToGetReportTotalEntryByAccuracyLocationAndUser } from './services'


export default function LocationAccuracy() {
  const expandFilterSearch = useHookstate<boolean>(true)
  const { translate } = useLang()
  const isLoading = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const period = useHookstate('')
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const contactCenterId = useHookstate<number | null>(null)
  const reportName = 'total-entry-by-accuracy'

  const { reportData, loadingReport } = useReportState()

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  async function generateReport(exportCSV=false) {
    if (startDate && finishDate) {
      isLoading.set(true)
      !exportCSV && loadingReport().set(true)
      const data = await makeRequestToGetReportTotalEntryByAccuracyLocationAndUser({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        contactCenterId: contactCenterId.get(),
        exportCSV: exportCSV
      }).finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })

        if(exportCSV){
            downloadReportCSV(
                `${translate(reportName)}-${new Date().getTime()}.csv`,
                data
              )
        } else {
            setTimeout(() => {
                reportData().set(data)
              })
              window.open(`/report/result/${reportName}`)
        }
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Total entries by location accuracy and user`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteContactCenter
                    contactCenterId={contactCenterId.get()}
                    onContactCenterChange={(contactCenter) => {
                      contactCenterId?.set(contactCenter)
                    }}
                  />
                </Grid>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                    />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    contactCenterId.set(null)
                    period.set('')
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={() => generateReport()}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
