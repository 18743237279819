import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'

import DataTables from '~/components/DataTables'
import ICrudIndex from './interfaces/ICrudIndex'
import InternalHeader from '../Layout/components/InternalHeader'
import InternalContent from '../Layout/components/InternalContent'
import { render } from 'react-dom'
import FiltersDashboard from './components/FiltersDashboard'

export default function CrudIndex({
  model,
  title,
  exceptColumns,
  forceShowColumns,
  aliasLabel,
  extraActions,
  forceRefresh,
  children,
  stringField,
  booleanField,
  datesField,
  showCreateButton = true,
  desableDelete = false,
  forceOrderColumns,
  arrayField,
  notSortable,
  createTitleComplement,
  deleteable = true,
  forceBooleanField,
  filters,
  loading,
  lockFields,
  clearFilters,
  searchButton,
  disableColumnsFilters,
  editable = true,
  cellLabel,
  createTitleComplete,
  canView=false,
  showInViewMore=[],
  viewTitle,
  priorityActions,
  permissionName,
  codeFields
}: ICrudIndex) {
  const { translate } = useLang()
  const history = useHistory()
  const handleCreateClick = () => {
    history.push(`/${model}/create`)
  }

  return (
    <>
      <InternalHeader
        title={translate(title)}
        action={
          showCreateButton ? (
            <Button
              onClick={handleCreateClick}
              title={translate('Create')}
              variant="contained"
            >
              <Typography variant="body2">
               {createTitleComplete ? 
               translate(createTitleComplete) 
               :
                translate('Create')+' '+translate(createTitleComplement ? createTitleComplement : title)
                }
              </Typography>
            </Button>
          ) : (
            <></>
          )
        }
      />
      
      <InternalContent expandButton={false}>
        <Typography variant="body2" color="text.secondary" />
        {
          filters && (
            <FiltersDashboard loading={loading} lockFields={lockFields} searchButton={searchButton} clearFilters={clearFilters} filters={filters} />
          )
        }
        <DataTables
          model={model}
          exceptColumns={exceptColumns}
          forceShowColumns={forceShowColumns}
          aliasLabel={aliasLabel}
          extraActions={extraActions}
          forceRefresh={forceRefresh}
          stringField={stringField}
          booleanField={booleanField}
          datesField={datesField}
          desableDelete={desableDelete}
          forceOrderColumns={forceOrderColumns}
          arrayField={arrayField}
          notSortable={notSortable}
          deleteable={deleteable}
          forceBooleanField={forceBooleanField}
          filters={filters}
          disableColumnsFilters={disableColumnsFilters}
          editable={editable}
          cellLabel={cellLabel}
          canView={canView}
          showInViewMore={showInViewMore}
          viewTitle={viewTitle}
          priorityActions={priorityActions}
          permissionName={permissionName}
          codeFields={codeFields}
        />
      </InternalContent>
      {children}
    </>
  )
}
