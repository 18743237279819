import React, { useEffect, useState } from 'react';
import { State } from '@hookstate/core';
import { DataGrid, GridActionsCellItem, GridCallbackDetails, gridClasses, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Avatar, Chip, Grid, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Star } from '@mui/icons-material';
import IListDispatchSocket, { IListDispatchesSocketResponse } from '~/components/ListDispatchesSocket/interfaces/IListDispatchesProps';
import { toDomain } from '~/mappers/dispatches-mapper';
import { Print } from '@mui/icons-material';
import { elapsedTime } from '~/utils/dateTime';
import SelectReportType from '~/components/FilterSelectReportType';
import { LoadingButton } from '@mui/lab';

interface IListDispatchesManagementProps {
  readonly dispatches: State<readonly IListDispatchSocket[]>;
  readonly dispatchIds: State<readonly GridRowId[]>;
  readonly reportType: State<string>;
  readonly searchButton: () => void;
  readonly lockFields: boolean;
  readonly loading: State<boolean>

}

export default function ListDispatchesManagement({ dispatchIds, dispatches, reportType, searchButton, lockFields, loading }: IListDispatchesManagementProps) {
  const { translate } = useLang();
  const { getPriorityColors } = useSystemState();

  const [rows, setRows] = useState<readonly IListDispatchesSocketResponse[]>([]);

  const columns: GridColDef[] = ([
    { field: 'id' },
    { field: 'occurrence_id', headerName: translate('Occurrence ID'), sortable: false, width: 115},
    {
      field: "code",
      headerName: translate('Nº Occurrence'),
      flex: 0.7,
    },
    { field: 'status', headerName: translate('Status'), flex: 0.4, sortable: false, },
    { field: 'dispatch_group', headerName: translate('Dispatch Group'), flex: 0.6, sortable: false, },
    { field: 'type', headerName: translate('Type'), flex: 1, sortable: false, },
    { field: 'subtype', headerName: translate('Subtype'), flex: 1, sortable: false, },
    { field: 'location', headerName: translate('Location'), flex: 1.2 },
    {
      field: 'city', headerName: `${translate('City')} / ${translate('District')}`, flex: 0.8,
      renderCell: (params) => {
        return params.row.city + (params.row.district ? ` / ${params.row.district}` : '')
      }
    },
    {
      field: 'priority', headerName: translate('Priority'), flex: 0.4, renderCell: (params) => {
        return <Chip sx={{  display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} 
        avatar={
        <Avatar>
          <Brightness1Icon sx={{ color: getPriorityColors(params.row.priority).main }}/>
        </Avatar>
        }
        label={params.row.priority}/>
      },
    },
    {
      field: 'elapsed_time',
      headerName: translate('Elapsed Time'),
      flex: 0.5,
      align: 'center',
      sortable: false,
      renderCell: (params) => elapsedTime(params.row.dispatched_at)
    },
    {
      field: 'highlight',
      headerName: translate('Highlight'),
      flex: 0.3,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (params.row.highlight ? <Star color="warning" /> : <></>);
      },
    },
    {
      field: 'actions', type: 'actions', flex: 0.2,
      getActions: (params) => [
        <Tooltip key={2} title={params.row.statusId !== 8 ? translate('Dispatch is not closed') : translate('Print dispatch')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              disabled={params.row.statusId !== 8}
              icon={<Print />}
              onClick={() => window.open(`/dispatch/print/${params.row.id}`)}
              label="Print" />
          </span>
        </Tooltip>
      ]
    },
  ]);

  useEffect(() => {
    setRows(dispatches.get().map(row => toDomain(row)))
  }, [dispatches])

  return (
     <>
     <Grid item xs={2} sx={{ ml: 4, pt: 2, gap: 2, display: 'flex', flexDirection: 'row' }}>
        <SelectReportType
          valueDefault={reportType.get()}
          onReportTypeChange={(type) => {
            reportType.set(type);
          } } 
          disabled={false} 
        />
        <LoadingButton
          variant="contained"
          onClick={searchButton}
          disabled={dispatchIds.get().length ? false : true}
          loading={loading.get()}
        >
          {translate('Export')}
        </LoadingButton>
      </Grid>
      <DataGrid
        sx={{ 
          minHeight: '250px', 
          m: 4,
          [`.${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 'bold !important',
          },
         }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        slotProps={{ row: { style: { cursor: 'pointer' } } }}
        getRowId={(row) => Number(row.id)}

        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        checkboxSelection
        onRowSelectionModelChange={(selectedRow) => {
          dispatchIds.set(selectedRow);
        } }
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight />
      </>
  )
}