import React from "react";
import { useHookstate } from '@hookstate/core';
import { IconButton, Badge, Tooltip } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import MessageModalDraggable from "../MessageModalDraggable";
import useMessageState from '../../stores/MessageState';
import { useLang } from '~/hooks/useLang';
import useSystemState from "~/features/System/stores/SystemState";

export default function MessageButton() {
  const isOpen = useHookstate(false);
  const { messagesNotReadCount, syncMessagesNotReadCount } = useMessageState();
  const { translate } = useLang();
  const { windowManager } = useSystemState()


  const toggleEvent = () => {
    windowManager()['MessageModal'].open.set(!windowManager()['MessageModal'].open.get());
  }

  React.useEffect(() => {
    syncMessagesNotReadCount();
  }, []);

  return (
    <Tooltip title={translate('Messages')} placement="bottom">
      <IconButton size="large" onClick={toggleEvent}>
        <Badge badgeContent={messagesNotReadCount().get()} color="primary">
          <MessageIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}