import { useHookstate, type State } from "@hookstate/core";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import type IType from "~/features/Type/interfaces/IType";
import { getAllTypes } from "~/features/Type/services";
import { useLang } from "~/hooks/useLang";

interface FilterAutocompleteTypeWithCategoryProps {
  readonly categoryId: number | null;
  readonly typeId: number | null;
  readonly refTypeId?: State<number | null>;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly isActive?: boolean;
  readonly hideCode?: boolean
  readonly onTypeChange: (value: IType | null) => void
}

export default function FilterAutocompleteTypeWithCategory({
  categoryId,
  typeId,
  refTypeId,
  disabled,
  error,
  isActive,
  hideCode,
  onTypeChange
}: FilterAutocompleteTypeWithCategoryProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly IType[]>([])
  const [optionsFilter, setOptionsFilter] = useState<readonly IType[]>([])
  const [value, setValue] = useState<IType | null>(null)
  const [loading, setLoading] = useState(false)
  const resTypes = useHookstate<readonly IType[]>([])

  useEffect(() => {
    setLoading(true)
    getAllTypes().then((types) => {
      const filteredTypes = isActive
        ? types.filter((item) => Boolean(item.active) == true)
        : types
      resTypes.set(
        filteredTypes.map((type) => ({
          ...type,
          displayName: hideCode
            ? `${type.name}`
            : `[${type.code}] ${type.name}`,
          category_id: type.category_id ?? null,
        }))
      )

      setOptions(resTypes.get())
      setOptionsFilter(resTypes.get())

      if (typeId) {
        const option = resTypes.get().find((option) => option.id == typeId)
        setValue(option ?? null)
      } else {
        setValue(null)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!typeId && categoryId) {
      const filter = options.filter((typeMap) => typeMap.categories?.find((category) => category.id === categoryId)) ?? []
      setOptionsFilter(filter ?? [])
    } else if (!typeId) {
      setValue(null)
    } else {
      const option = options.find((option) => option.id == typeId)
      setValue(option ?? null)
    }
  }, [options, typeId])

  useEffect(() => {
    if (categoryId) {
      if (options) {
        const filter = options.filter((typeMap) => typeMap.categories?.find((category) => Array.isArray(categoryId) ? categoryId.includes(category.id) : category.id === categoryId)) ?? []
        setOptionsFilter(filter.length ? filter : [])
      }
    }
    else {
      setOptionsFilter(options ?? [])
    }
  }, [categoryId, options])

  useEffect(() => {
    if (refTypeId?.get() && categoryId) {
      const filterType = options.filter((typemap) => typemap.id === refTypeId?.get())
      const filterOptions = options.filter((typeMap) => typeMap.categories?.find((category) => category.id === categoryId)) ?? []
      setOptionsFilter(filterOptions.length ? filterOptions : [])
      onTypeChange(filterType[0])
      setValue(filterType[0])
    } else if (!refTypeId && !categoryId) {
      setOptionsFilter(options ?? [])
      setValue(null)
    } else {
      // setOptionsFilter(options ?? [])
    }

  }, [refTypeId?.get(), typeId])

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        id="filter-types"
        disabled={disabled}
        options={optionsFilter}
        value={value}
        noOptionsText={translate('No options')}
        size="small"
        loading={loading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName ?? option.name ?? ''}
        onChange={(_, type) => {
          if (type) {
            setValue(type)
            onTypeChange(type)
          } else {
            setValue(null)
            onTypeChange(null)
            refTypeId?.set(null)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Type')}
            helperText={error}
          />
        )}
      />
    </FormControl>
  )
}