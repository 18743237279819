import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedWeaponFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface gunHistoryProps {
  readonly  id: number,
  readonly  model: string,
  readonly  serial_number: string,
  readonly  notes: string,
  readonly  occurrence_id: number,
  readonly  quantity: number,
  readonly  weapon_type: {
    readonly id: number,
    readonly name: string
  },
  readonly  weapon_kind: {
    readonly id: number,
    readonly name: string
  },
  readonly  weapon_brand: {
    readonly id: number,
    readonly name: string
  },
  readonly  firearm_caliber: {
    readonly id: number,
    readonly name: string
  }
}

interface GunsRegisteredInTheOccurrencePrintProps {
  readonly GunsRegisteredInTheOccurrencePrintResponse: gunHistoryProps[]
}


export default function GunsRegisteredInTheOccurrencePrint({ GunsRegisteredInTheOccurrencePrintResponse }: GunsRegisteredInTheOccurrencePrintProps){
  const { translate } = useLang();


  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        { GunsRegisteredInTheOccurrencePrintResponse.length != 0 ? (
          GunsRegisteredInTheOccurrencePrintResponse.map((gun) => (
            <Grid container key={gun?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{gun?.weapon_type?.name ?? `* ${translate('Uninformed')} *`}</b></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Type')}:</b> {gun?.weapon_type?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Brand')}:</b> {gun?.weapon_brand?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Model')}:</b> {gun?.model ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Subtype')}:</b> {gun?.weapon_kind?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Caliber')}:</b> {gun?.firearm_caliber?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Quantity')}:</b> {gun?.quantity ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Serial Number')}:</b> {gun?.serial_number ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {gun?.notes ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
        </Grid>
        )}
      </Grid>
    </Container>
  )
}