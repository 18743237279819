import React, { useState } from 'react'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { PieChart as PieChartGraph } from '@mui/x-charts/PieChart'
import {
  HighlightItemData,
} from '@mui/x-charts'

export default function PieChart({
  data,
  title,
  isPrint = false,
  height = 300,
  width = 0,
  subtitle = '',
  sxFont = {},
  typeTotal = '',
  info=''
}) {
  const { translate } = useLang()
  const [highlightedItem, setHighLightedItem] = useState<HighlightItemData| null>(null);

  return (
    <Card >
      <CardContent>
        <Typography sx={{fontWeight: 'bold', ...sxFont}}>{translate(title)}</Typography>
        {info && (
          <Typography variant="subtitle2" marginBottom={2} marginTop={1}>
            {translate(info)}
            </Typography>
        )}
        {subtitle && (
          <Typography variant="subtitle2" marginBottom={2} marginTop={1}>
            {translate(subtitle)} {typeTotal && `| ${translate('Total of')} ${translate(typeTotal)}: ${data.length}`}
            </Typography>
        )}
        {data.length !== 0 ? (
          <Grid
            container
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            
              <PieChartGraph
                series={[{ data: data, outerRadius: 150}]}
                width={width != 0 ? width : undefined}
                height={data.length > 20 ? 700 : 500}
                margin={{ bottom: 100, left: 100, right: data.length*10, top: 100 }}
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
        
                    
                  },
                }}
                skipAnimation
                // highlightedItem={highlightedItem}
                // onHighlightChange={(highlightedItem) => setHighLightedItem(highlightedItem)}
              
              />
          </Grid>
        ) : (
          <Grid item>
            <Card
              sx={{
                height: height,
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <CardContent>
                <Typography>{translate('No data available')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
