import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import IFriendlyType from '../interfaces/IFriendlyType';
import { useHookstate } from '@hookstate/core';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IFriendlyType>();
  const typeId = useHookstate<number | null>(null)
  const fields = [
    { name: 'name', type: 'string', required: true },
    { name: 'description', type: 'string', required: false },
    { name: 'type_id', type: 'selectAutoComplete', model: 'type', placeholder: 'Type', required: true, filter: typeId, independent: true },
    { name: 'subtype_id', type: 'selectAutoComplete', model: 'subtype', placeholder: 'Subtype', filter: typeId, filterName: 'type_id', dependent: true },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'friendly-type'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Type/Subtype Friendly successfully created!'}
        title={'Add Type/SubType Friendly'}
        subheader={'Add new Type/Subtype Friendly'}
        setFormValue={setFormValue}
      />
    </Layout>
  )
}
