import { Card, CardHeader, Collapse, IconButton, Typography } from "@mui/material"
import React, { useState } from "react"
import InternalContent from "~/components/Layout/components/InternalContent";
import { useLang } from "~/hooks/useLang";
import AuditFilters from "../../Qualifiers/components/AuditFiltersQualifiers";
import { useHookstate } from "@hookstate/core";
import { getAssociatedSearchLogs } from "../../Qualifiers/services";
import { DataGrid, type GridColDef, type GridRowsProp, gridClasses } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import dayjs from 'dayjs';
import notify from "~/utils/notify";
import { getInterestPointSearchLogs } from "../services";
import AuditInterestPointFilters from "./AuditInterestPointFilters";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

// eslint-disable-next-line max-lines-per-function
export default function ListAllAuditInterestPoint() {
  const { translate } = useLang();
  const labelButton = useHookstate(translate('Search'));
  const selectAction = useHookstate<string | null>(null);
  const loadingButton = useHookstate<boolean>(false);
  const expandFilterSearch = useHookstate<boolean>(true);
  const userId = useHookstate<number | null>(null)
  const resetValeus = useHookstate(false)
  const focusChange = useHookstate<boolean>(false)
  const initialPeriod = useHookstate<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [finishDate, setFinishDate] = useState<string | null>(null);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const tableMode = "server";
  const limit = 15;
  const expandInterestPoints = useHookstate<boolean>(false)



  const columns: GridColDef[] = ([
    { field: 'id' },
    {
      field: 'action', headerName: translate('action'), flex: 1,
      valueFormatter: (value) => {
        return translate(value)
      }
    },
    { field: 'interest_point:name', headerName: translate('Interest point'), flex: 1 },
    { field: 'user:representation_name', headerName: translate('User'), flex: 1 },
    { field: 'device:name', headerName: translate('Device:name'), flex: 1 },
    { field: 'station:name', headerName: translate('Station'), flex: 1 },
    {
      field: 'created_at', headerName: 'Data de criação', type: 'date', flex: 1,
      valueFormatter: (value) => {
        return dayjs(value as string).format('DD/MM/YYYY HH:mm');
      },
    },
  ])


  const clearFilters = () => {
    setStartDate(null)
    setFinishDate(null)
    initialPeriod.set('')
    userId.set(null)
    selectAction.set(null)
    setRows([])
    expandInterestPoints.set(false)
  }


  const submit = async () => {
    if (!startDate || !finishDate) {
      notify({
        type: 'error',
        message: translate('Start date and end date must be provided')
      })

      return false
    }
    loadingButton.set(true)

    const searchLogData: any = await getInterestPointSearchLogs({
      action: selectAction.get(),
      userId: userId.get(),
      startDate: startDate,
      finishDate: finishDate,
    }).finally(() => {
      loadingButton.set(false)
      expandInterestPoints.set(true)
    })

    setRows(searchLogData.data.map((element) => {
      element['station:name'] = element?.station?.name
      element['user:representation_name'] = element?.user?.representation_name
      element['search_info'] = element?.search_info;
      element['interest_point:name'] = element?.interest_point?.name
      element['device:name'] = element?.device?.name

      return element;
    }))

    setRowCount(searchLogData.rowsCount);
  }


  return (
    <>
      <InternalContent title={`${translate('Filters')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <AuditInterestPointFilters
          hidden={expandFilterSearch.get()}
          loadingButton={loadingButton}
          labelButton={labelButton}
          selectAction={selectAction}
          focusChange={focusChange}
          resetValues={resetValeus}
          userId={userId}
          initialPeriod={initialPeriod}
          onPeriodChange={(value) => {
            labelButton.set(translate('Search'))
            initialPeriod.set(value)
          }}
          onStartDateChange={(value) => {
            labelButton.set(translate('Search'))
            setStartDate(value)
          }}
          onFinishDateChange={(value) => {
            labelButton.set(translate('Search'))
            setFinishDate(value)
          }}
          clearFilters={() => clearFilters()}
          searchButton={() =>
            submit()
          }
        />


      </InternalContent>
      <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
        <CardHeader
          title={translate('Interest point')}
          avatar={
            <IconButton
              onClick={() => expandInterestPoints.set(!expandInterestPoints.get())}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                marginRight: 1,
                padding: 0,
              }}
            >
              {expandInterestPoints.value ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          }
          titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
        />
        <Collapse in={expandInterestPoints.value}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
              deleted_at: false,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: limit } },
            }}
            disableColumnMenu
            autoHeight
            pagination
            filterMode={tableMode}
            sortingMode={tableMode}
            rowCount={rowCount}
            getRowId={(row) => Number(row.id)}
            sx={{
              minHeight: '250px',
              m: 4,
              [`.${gridClasses.columnHeaderTitle}`]: {
                fontWeight: 'bold !important',
              },
            }}
          />
        </Collapse>
      </Card >

    </>
  )
}