import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IFriendlyType from '../interfaces/IFriendlyType';
import { useHookstate } from '@hookstate/core';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IFriendlyType>();
  const typeId = useHookstate<number | null>(null)
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true },
    { name: 'description', type: 'string', required: false },
    { name: 'type_id', type: 'selectAutoComplete', model: 'type', placeholder: 'Type', required: true, filter: typeId, independent: true },
    { name: 'subtype_id', type: 'selectAutoComplete', model: 'subtype', placeholder: 'Subtype', filter: typeId, filterName: 'type_id', dependent: true },
  ];

  return (
    <CrudEdit model={'friendly-type'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Type/Subtype Friendly successfully updated!'}
      title={'Edit Type/SubType Friendly'}
      getValues={getValues}
    />
  )
}
