import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { useHookstate } from '@hookstate/core'
import { DeviceResponse } from './interface'
import { getDevices } from './service'

interface FilterAutocompleteDeviceProps {
  readonly onDeviceChange: (value: number | null) => void
  readonly deviceId?: number | null
  readonly groupDispatchs?: readonly number[] | null
  readonly disabled?: boolean
  readonly operationUnitId?: number | null
  readonly label?: string;
}

export default function FilterAutocompleteDevice({
  disabled,
  groupDispatchs,
  onDeviceChange,
  deviceId,
  operationUnitId,
  label
}: FilterAutocompleteDeviceProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly DeviceResponse[]>([])
  const [optionsFilter, setOptionsFilter] = useState<readonly DeviceResponse[]>(
    []
  )
  const [value, setValue] = useState<DeviceResponse | null>(null)
  const loading = useHookstate(false)

  useEffect(() => {
    getDevices().then((devices) => {
      setOptions(devices)
      setOptionsFilter(devices)
      setValue(null)
    })
  }, [])

  useEffect(() => {
    if (!deviceId) {
      setValue(null)
    } else {
      setValue(options.find(item => item.id == deviceId) ?? null)
    }
  }, [deviceId, options])

  useEffect(() => {
    if (groupDispatchs?.length && operationUnitId) {
        const filter = options.filter((group) => group.operation_unit_id == operationUnitId && groupDispatchs.includes(group.dispatch_group_id))
        setOptionsFilter(filter)
    } else if (groupDispatchs?.length) {
        const filter = options.filter((group) => groupDispatchs.includes(group.dispatch_group_id))
        setOptionsFilter(filter.length ? filter : [])
    } else if (operationUnitId) {
        const filter = options.filter((group) => group.operation_unit_id && group.operation_unit_id == operationUnitId)
        setOptionsFilter(filter)
    } else {
        setOptionsFilter(options)
    }
  }, [groupDispatchs, operationUnitId])

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        id="filter-devices"
        options={optionsFilter}
        disabled={disabled}
        value={value}
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size="small"
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onDeviceChange(type?.id ?? null)
        }}
        renderInput={(params) => (
          <TextField {...params} label={translate(label ? label : 'device')} />
        )}
      />
    </FormControl>
  )
}
