import { authFetch } from '~/services/fetch';

export async function getDataByPlate(plate) {
	try {
		const { data } = await authFetch({
			url: `/rh/shift/${plate}/today`,
			method: 'GET',
			timeout: 5000000     
		})
			
		return data;
	} catch (error) {console.error("entrou")
		throw error;
	}
}

export async function checkTrackingDevice({ deviceId }) {
	try {
		const response = await authFetch({
			method: 'GET',
			url: `/device/${deviceId}/check-tracking`
		})

		return response
	} catch (error) {
		throw error
	}
}