import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import { Clear, DirectionsCar } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useEntryState from '~/features/Entry/stores/EntryState';
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import PlateField from '../PlateField';
import CircunstanceSelectField from '~/features/Entry/components/CircunstanceSelectField';
import { useHookstate } from '@hookstate/core';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';
import SearchIcon from '@mui/icons-material/Search';
import notify from '~/utils/notify';
import useUserState from '~/features/User/stores/UserState'
import { verifyExternalKeyRegistrtation } from '../../services';
import type ScreenSettingsDraggableProps from '../../interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';

const modal = document.getElementById('div-modal') as HTMLElement;

// eslint-disable-next-line max-lines-per-function
export default function VehicleModalDraggable() {
  const { systemConfig } = useSystemState();
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const entryState = useEntryState();
  const { vehicle } = entryState.entry();
  const { safeBikeCanSave } = useEntryState()
  const { canSave } = entryState.entryStatusActions();
  const { verifyPermission } = useUserState();
  const resultCheckExternalKeyRegistration = useHookstate(false)
  const messageForAttendant = useHookstate<string | null>('')
  const disableButtonCheckPlate = useHookstate(false)
  const [searchExternalKey, setSearchExternalKey] = useState<string | null>('')
  const changed = useHookstate(false)

  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('VehicleModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.VehicleModalDraggable)
      }
    })

    return () => {
      windowManager()['VehicleModal'].open.set(false)
    }
  }, [])


  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['VehicleModal'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'VehicleModalDraggable',
        isOpen: windowManager()['VehicleModal'].open.get(),
        isMinimized: windowManager()['VehicleModal'].minimized.get(),
        positionX: windowManager()['VehicleModal'].positionX.get(),
        positionY: windowManager()['VehicleModal'].positionY.get()
      })
    }
  }, [
    windowManager()['VehicleModal'].open.get(),
    windowManager()['VehicleModal'].minimized.get(),
    windowManager()['VehicleModal'].positionY.get(),
    windowManager()['VehicleModal'].positionX.get()
  ])

  useEffect(() => {
    if (vehicle.externalKeyTracking.get()) {
      setSearchExternalKey(vehicle.externalKeyTracking.get())
    }
  }, [vehicle.externalKeyTracking.get()])

  useEffect(() => {
    if (changed.get()) {
      messageForAttendant.set(null)
    }
  }, [changed.get()])


  const handleCheckPlateAndKeyRegistration = async (term) => {
    if (term && term.length > 3) {
      vehicle.externalKeyTracking.set(searchExternalKey);
      const response = await verifyExternalKeyRegistrtation({ plate: vehicle.plate.get(), externalKeyTracking: vehicle.externalKeyTracking.get() })

      resultCheckExternalKeyRegistration.set(response)

      if (response) {
        messageForAttendant.set(translate('This plate is registered in the program Safe Bike.'))
        safeBikeCanSave.set(false)
        changed.set(false)
        notify({
          message: translate('The vehicle will be pursued when the incident is reported'),
          type: 'success'
        })
      } else {
        messageForAttendant.set(translate('There is no such plate and/or tracking key registered in the Safe Bike program.'))
        vehicle.externalKeyTracking.set(null)
        safeBikeCanSave.set(true)
      }
    } else {
      notify({
        message: 'Chave de Rastreamento contém no mínimo 4 caracteres.',
        type: 'error',
      })
    }
  }

  const showErros = () => {
    if (messageForAttendant.get()) {
      if (resultCheckExternalKeyRegistration.get()) {
        return false
      } else {
        return true
      }
    } else if (searchExternalKey === '') {
      return false
    }
  }

  const verifyDisableSaveButton = () => {

    if (vehicle.plate.get() && vehicle.circunstanceId.get() && vehicle.externalKeyTracking.get() && !changed.get()) {
      safeBikeCanSave.set(false)
    } else if (!searchExternalKey?.length && changed.get()) {
      safeBikeCanSave.set(false)
    } else if (
      (vehicle.plate.get() && !vehicle.circunstanceId.get()) ||
      (!vehicle.plate.get() && vehicle.circunstanceId.get()) ||
      (searchExternalKey && searchExternalKey.length > 0 && !resultCheckExternalKeyRegistration.get()) ||
      changed.get()
    ) {
      safeBikeCanSave.set(true)
    } else {
      safeBikeCanSave.set(false)
    }
    return false
  }
  

  useEffect(() => {
    verifyDisableSaveButton()
  }, [vehicle.plate.get(), vehicle.circunstanceId.get(), searchExternalKey, changed.get()])

  useEffect(() => {
    if (searchExternalKey && searchExternalKey?.length < 3 || !searchExternalKey?.length) {
      disableButtonCheckPlate.set(true)
    } else {
      disableButtonCheckPlate.set(false) 
    }
  }, [searchExternalKey, vehicle.externalKeyTracking.get()])

  const closeModalDraggable = () => {
    safeBikeCanSave.set(false)
    windowManager()['VehicleModal'].open.set(false)
  }

  if (windowManager()['VehicleModal'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={`${translate("Add Vehicle")}`}
            avatar={DirectionsCar}
            dimensions={{
              width: 450
            }}
            isExpanded={windowManager()['VehicleModal'].minimized}
            handleStop={handleStop}
            position={{
              x: windowManager()['VehicleModal'].positionX?.get() ? Number(windowManager()['VehicleModal'].positionX.get()) : 550,
              y: windowManager()['VehicleModal'].positionY?.get() ? Number(windowManager()['VehicleModal'].positionY.get()) : 100
            }}
            closeButton={() => { closeModalDraggable() }}
          >
            <Grid container direction={'row'} xs={12} >
              <Grid item xs={6} sx={{ padding: 1 }}>
                <PlateField plate={vehicle.plate} />
              </Grid>
              <Grid item xs={6} sx={{ padding: 1 }}>
                <CircunstanceSelectField isValid={true} circunstanceId={vehicle.circunstanceId} />
              </Grid>

              <Grid container xs={12} sx={{ padding: 1 }} >
                {
                  systemConfig().showEntryFieldExternalKeyTracking.get() === '1' && (
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        size='small'
                        id="external-tracking"
                        label={translate('Chave de Rastreamento')}
                        value={searchExternalKey}
                        helperText={translate('Minimum of 4 characters')}
                        InputProps={{
                          onChange: (event) => {
                            setSearchExternalKey(event.target.value);
                            changed.set(true)

                            if (!event.target.value.length) {
                              vehicle.externalKeyTracking.set(null)
                            }
                          },
                          endAdornment:
                            <InputAdornment position="end">
                              <Tooltip title={translate('Clear')}>
                                <IconButton size='small' onClick={() => {
                                  setSearchExternalKey('');
                                  safeBikeCanSave.set(false);
                                  messageForAttendant.set(null);
                                  vehicle.externalKeyTracking.set(null)
                                  changed.set(false)
                                  showErros();
                                }}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>,
                        }}
                        error={showErros()} />
                    </Grid>
                  )}
                <Grid item xs={1} paddingLeft={1} paddingTop={0.5} >
                  <Tooltip title={translate('Verify in safe bike')}>
                    <IconButton
                      disabled={disableButtonCheckPlate.get()}
                      size='small'
                      onClick={() => handleCheckPlateAndKeyRegistration(searchExternalKey)}>
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {messageForAttendant.get() &&
                <Grid padding={1}>
                  <Typography>
                    {messageForAttendant.get()}
                  </Typography>
                </Grid>
              }
              <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} marginTop={1}>
                {(verifyPermission([])) && (
                  <Button
                    variant={"contained"}
                    disabled={!canSave || safeBikeCanSave.get()}
                    onClick={() => {
                      !safeBikeCanSave.get() ? entryState.saveEntry() : false
                    }}
                  >
                    {translate('Save')}
                  </Button>
                )}
              </Box>
            </Grid>

          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}
