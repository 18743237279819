import React from 'react';
import { useLang } from '~/hooks/useLang';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { maskPlate } from '~/features/Dispatch/utils/stringsManipulations';
import { Clear } from '@mui/icons-material';
import type { State } from '@hookstate/core';

interface PlateField {
  readonly plate: State<string | null>;
}

export default function PlateField({ plate }: PlateField) {
  const { translate } = useLang();
  
    return (
      <TextField
          fullWidth
          size='small'
          id="outlined-plate"
          label={translate('Plate')}
          value={plate?.get() ? plate?.get() : ''}
          InputProps={{
            onChange:
              (event) => {
                const plateMask = maskPlate(event.target.value);
                plate?.set(plateMask);
              },
            endAdornment:
              <InputAdornment position="end">
                <Tooltip title={translate('Clear')}>
                  <IconButton size='small' onClick={() => plate.set(null)}>
                    <Clear />
                  </IconButton>
                </Tooltip>
               
              </InputAdornment>,
          }}
        />
    )
}