/* eslint-disable functional/prefer-readonly-type */
import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { useHookstate } from '@hookstate/core';
import { randomIntFromInterval } from '~/utils/numbers';
import { getAreaPlanService } from '~/features/Map/services/request';
import {ErrorBoundary} from 'react-error-boundary';
import useSystemState from '~/features/System/stores/SystemState';

type AreaPlansProps = {
    readonly id: number;
    readonly name: string;
    readonly areas: readonly {
        readonly id: number;
        readonly name: string;
        readonly color: string;
        readonly acronym: string;
        readonly polygon: {
            readonly type: "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon" | "GeometryCollection" | "Feature" | "FeatureCollection",
            readonly coordinates: [number, number][]
        };
    }[]
}

export default function AreaPlans() {
    const areaPlans = useHookstate<readonly AreaPlansProps[]>([]);
    const { windowManager } = useSystemState();

    const featuredColors = ['#F4AB6C', '#e57373', '#ffb74d', '#4fc3f7', '#81c784', '#795548', '#673ab7', '#ef6c00', '#616161', '#e3f2fd'];

  React.useEffect(() => {
    getAreaPlanService().then((data) => {
      areaPlans.set(data);
      data.map((area) => {
        windowManager()['mapSetting']['markersView']['areas']['children'].merge({ [area.name]: { title: area.name, show: false, filterPropName: 'id', filterCondition: () => true } })
      })
      windowManager()['mapSetting']['markersView']['areas'].merge({ hide: true, show: false })
    });
       
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <ErrorBoundary FallbackComponent={({error, resetErrorBoundary}) => {
            console.info('AreaPlans', error);
            return (<></>)
          }}>
            {areaPlans.value.map((areaPlan) => {
                return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        { 
                            windowManager()['mapSetting']['markersView']['areas']['children'].get() ? (
                            windowManager()['mapSetting']['markersView']['areas']['children'].get()?.[`${areaPlan.name}`]?.['show'] ?
                                areaPlan.areas.map((area) => {
                            
                                    const random = randomIntFromInterval(0, featuredColors.length - 1)
                                    const areas = area.polygon.coordinates[0]
                                    if(typeof areas == 'object'){
                                        return (
                                            <Polygon  
                                                key={area.name}  
                                                positions={[areas]}
                                                pathOptions={{
                                                    fillColor: area.color,
                                                    weight: 2.5,
                                                    color: area.color,
                                                    dashOffset: '5',
                                                    fillOpacity: 0.4,
                                                    stroke: true,
                                                }}  
                                            >
                                                <Tooltip>
                                                    {area.name}
                                                </Tooltip>
                                            </Polygon>
                                        );
                                    }
                                })
                            : false        
                            ) : false }
                    </>
                );
            })}
        </ErrorBoundary>
        
    );
}