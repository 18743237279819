import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import ViewListIcon from '@mui/icons-material/ViewList';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import OccurrenceList from '~/features/Occurrence/components/OccurrenceList';
import { authFetch } from '~/services/fetch';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function OccurrenceListModalDraggable() {
  const { translate } = useLang();
  const { windowManager, systemConfig, loadingButton } = useSystemState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('OccurrenceListModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.OccurrenceListModalDraggable)
      }
      
    }).finally(() => {
      
      loadingComponent.set(false)
    })

    return () => {
      windowManager()['occurrenceList'].open.set(false)
    }
  }, []) 

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'OccurrenceListModalDraggable',
        isOpen: windowManager()['occurrenceList'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['occurrenceList'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['occurrenceList'].open.value && !loadingButton.get() && !loadingComponent.get() &&
        <StandardModal
          title={`${translate('Occurrences')}`}
          subheader={`${translate('Last occurrences hours', { hours: systemConfig().occurrenceSearchPeriodInHours.get() })}`}
          avatar={ViewListIcon}
          dimensions={{
            width: 800,
          }}
          handleStop={handleStop}
          isExpanded={getPositionsDraggable.is_window_minimized}
          position={{
            x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 600,
            y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 100
          }}
          closeButton={!(windowManager()['occurrenceList'].disabled.value) ? () => windowManager()['occurrenceList'].open.set(false) : false}
        >
          <OccurrenceList />

        </StandardModal>
      }
    </>
    , modal);
}

// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom'
// import ViewListIcon from '@mui/icons-material/ViewList';
// import StandardModal from '~/components/StandardModal';
// import { useLang } from '~/hooks/useLang';
// import useSystemState from '~/features/System/stores/SystemState';
// import OccurrenceList from '~/features/Occurrence/components/OccurrenceList';
// import { authFetch } from '~/services/fetch';
// import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
// import { useHookstate } from '@hookstate/core';
// import { useLocation } from 'react-router';
// import { storeScreenSettings } from '~/features/System/services';

// const modal = document.getElementById('div-modal') as HTMLElement;

// export default function OccurrenceListModalDraggable() {
//   const { translate } = useLang();
//   const { windowManager, systemConfig, loadingButton } = useSystemState();

//   const handleStop = (event, dragElement) => {
//     event.stopPropagation();

//     windowManager()['occurrenceList'].merge({
//       positionX: dragElement.x,
//       positionY: dragElement.y
//     })
//   };

//   useEffect(() => {
//     return () => {
//       storeScreenSettings({
//         windowName: 'OccurrenceListModalDraggable',
//         isOpen: windowManager()['occurrenceList'].open.get(),
//         isMinimized: windowManager()['occurrenceList'].minimized.get(),
//         positionX: windowManager()['occurrenceList'].positionX.get(),
//         positionY: windowManager()['occurrenceList'].positionY.get()
//       })
//     }
//   }, [
//     windowManager()['occurrenceList'].open.get(),
//     windowManager()['occurrenceList'].minimized.get(),
//     windowManager()['occurrenceList'].positionY.get(),
//     windowManager()['occurrenceList'].positionX.get()
//   ])

//   return ReactDOM.createPortal(
//     // eslint-disable-next-line react/jsx-no-useless-fragment
//     <>
//       {
//         !loadingButton.get() &&
//         <StandardModal
//           title={`${translate('Occurrences')}`}
//           subheader={`${translate('Last occurrences hours', { hours: systemConfig().occurrenceSearchPeriodInHours.get() })}`}
//           avatar={ViewListIcon}
//           dimensions={{
//             width: 800,
//           }}
//           handleStop={handleStop}
//           isExpanded={windowManager()['occurrenceList'].minimized}
//           position={{
//             x: windowManager()['occurrenceList'].positionX?.get() ? Number(windowManager()['occurrenceList'].positionX.get()) : 600,
//             y: windowManager()['occurrenceList'].positionY?.get() ? Number(windowManager()['occurrenceList'].positionY.get()) : 100
//           }}
//           closeButton={!(windowManager()['occurrenceList'].disabled.value) ? () => windowManager()['occurrenceList'].open.set(false) : false}
//         >
//           <OccurrenceList />

//         </StandardModal>
//       }
//     </>
//     , modal);
// }