import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import StandardModal from '~/components/StandardModal';
import { Settings } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { storeScreenSettings } from '~/features/System/services';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';
import type ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { useLocation } from 'react-router-dom';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import useDeviceState from '~/features/Device/stores/DeviceState';

const modal = document.getElementById('div-modal') as HTMLElement;

// eslint-disable-next-line max-lines-per-function
export default function MapSettingModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const { agencyIdFilterMap, operationIdFilterMap, filterActived } = useDeviceState()
  const location = useLocation();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })
  const filtersMapSettings = useHookstate({
    devices: true
  })


  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('MapSettingModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MapSettingModalDraggable)
      }
    }).finally(() => {
      loadingComponent.set(false)
    })
    return () => {
      windowManager()['mapSetting'].open.set(false)
    }

  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapSettingModalDraggable',
        isOpen: windowManager()['mapSetting'].open.get(),
        isMinimized: getPositionsDraggable?.is_window_minimized.get(),
        positionX: getPositionsDraggable?.x_position.get(),
        positionY: getPositionsDraggable?.y_position.get()
      })
    }

  }, [
    windowManager()['mapSetting'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  const openFilterDeviceDraggable = () => {
    if (!windowManager()['mapSetting']['markersView']['device'].show.get()) {
      windowManager()['mapSetting']['markersView']['device'].show.set(true)
      windowManager()['mapFiltersDevice'].open.set(!windowManager()['mapFiltersDevice'].open.get())
    } else {
      windowManager()['mapFiltersDevice'].open.set(!windowManager()['mapFiltersDevice'].open.get())
    }
  }

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapSetting'].open.value && !loadingButton.get() &&
        <StandardModal
          title={translate('Map Settings')}
          avatar={Settings}
          handleStop={handleStop}
          position={{
            x: windowManager()['mapSetting'].positionX?.get() ? Number(windowManager()['mapSetting'].positionX?.get()) : 80,
            y: windowManager()['mapSetting'].positionY?.get() ? Number(windowManager()['mapSetting'].positionY?.get()) : 141
          }}
          closeButton={!(windowManager()['mapSetting'].disabled.value) ? () => windowManager()['mapSetting'].open.set(false) : false}
          isExpanded={getPositionsDraggable.is_window_minimized}
        >
          <Box className='leaflet-control'>
            {Object.keys(windowManager()['mapSetting']['markersView']).filter((sectionSelect) => windowManager()['mapSetting']['markersView'][sectionSelect].hide.get()).map((section, index) => {
              return (
                <Box key={`${section}`} >
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <FormControlLabel
                      label={
                        <Box width={'100%'} display={'flex'} alignItems={'center'}>
                          {translate(windowManager()['mapSetting']['markersView'][section].title.get())}
                        </Box>
                      }
                      control={
                        <Checkbox
                          checked={windowManager()['mapSetting']['markersView'][section].show.get()}
                          onChange={() => {
                            if (windowManager()['mapSetting']['markersView'][section].show.get() && windowManager()['mapSetting']['markersView'][section].get()['children']) {
                              Object.keys(windowManager()['mapSetting']['markersView'][section].get()['children']).map((children) => {
                                windowManager()['mapSetting']['markersView'][section]['children'][children].show.set(false)
                              })
                            }
                            windowManager()['mapSetting']['markersView'][section].show.set(!windowManager()['mapSetting']['markersView'][section].show.get())
                          }}
                          sx={{
                            color: '#41AEAA',
                            '&.Mui-checked': {
                              color: '#41AEAA',
                            },
                          }}
                        />

                      }
                    />
                    {windowManager()['mapSetting']['markersView'][section].title.get() === 'View Device' && window.location.pathname === '/dispatch/management-map' &&
                      <IconButton
                        title={translate('Open device filters')}
                        size="small"
                        sx={{ marginLeft: 8 }}
                        onClick={() => openFilterDeviceDraggable()}
                      >
                        <FilterAltIcon fontSize="small" color={filterActived.get() ? 'primary' : 'inherit'} />
                      </IconButton>
                    }
                  </Box>
                  {/* {windowManager()['mapSetting']['markersView'][section].title.get() === 'View Device' &&
                    <IconButton size="small">
                    <FilterAltIcon fontSize="small" />
                    </IconButton>
                    } */}
                  {windowManager()['mapSetting']['markersView'][section].get()['children'] ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {Object.keys(windowManager()['mapSetting']['markersView'][section]['children']).map((sectionChildren, indexChildren) => {
                        return (<FormControlLabel
                          key={`${sectionChildren}`}
                          label={translate(windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].title.get())}
                          control={

                            <Checkbox
                              checked={windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get()}
                              onChange={() => {
                                if (!windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get()) {
                                  if (!windowManager()['mapSetting']['markersView'][section].show.get()) {
                                    windowManager()['mapSetting']['markersView'][section].show.set(true);
                                  }
                                }
                                windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.set(!windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get());
                              }}
                              sx={{
                                color: '#41AEAA',
                                '&.Mui-checked': {
                                  color: '#41AEAA',
                                },
                              }} />
                          }
                        />)
                      })}
                    </Box> : <></>}
                </Box>
              )
            })}
          </Box>
        </StandardModal>
      }
    </>
    , modal);
}