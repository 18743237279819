import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { getEntryTimeByStation } from '../../services';
import ColumnChart from '~/components/ColumnChart';
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic';
import { convertSecondsToTime } from '~/utils/dateTime';
import ColumnChartVertical from '~/components/ColumnChartMui/VerticalChart';

export default function EntryTimeByStationColumnChart({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed = false, showTitle = false, refresh, loading, cityId }: IEntryStatisticGraphProps) {
  const data = useHookstate([]);
  const total = useHookstate<number>(0)

  useEffect(() => {
    loading.set(true)
    total.set(0)
    getEntryTimeByStation({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed, cityId }).then((response) => {
      const result = response.map((data) => {
        total.set(total.get() + data.averageTimeInSeconds)
        return {
          item: data.name,
          value: data.averageTimeInSeconds,
          label: `${data.averageTime}`,
          tooltipInfo: data.total_entries
        }
      })
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <ColumnChartVertical
      data={data.attach(Downgraded).get()}
      title={`${showTitle ? 'Entries Average Time Station' : ''}`}
      subtitle={`Tempo médio aproximado de atendimentos: ${data.get().length ? convertSecondsToTime(Math.round(total.get() / data.get().length)) : 0}`}
      typeTotal={'Stations'}
      colorBar="primary"
      hasOtherLabel
      Ytype='time'
      marginLeft={90}
      tooltip='Total de atendimentos: '
    />
  )
}