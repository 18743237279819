/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import Layout from '~/components/Layout'
import InternalContent from '~/components/Layout/components/InternalContent'
import InternalHeader from '~/components/Layout/components/InternalHeader'
import { useLang } from '~/hooks/useLang'
import {
  getAllSystemConfigService,
  updateAllSystemConfigService,
} from '~/features/System/services'
import { ISystemConfigAllByGroups } from '../interfaces/ISystemConfig'
import {
  CardActions,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { StateMethods, useHookstate } from '@hookstate/core'
import LoopIcon from '@mui/icons-material/Loop'

import ButtonForm from '~/components/ButtonForm'
import { useHistory } from 'react-router-dom'
import notify from '~/utils/notify'
import { capitalize, splitCamelCase, toCamelCase } from '~/utils/strings'
import FilterAutocompleteType from '~/components/FilterAutocompleteType'
import IType from '~/features/Type/interfaces/IType'
import FilterAutocompleteClosedStatus from '~/components/FilterAutocompleteClosedStatus'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import FilterAutocompleteFetch from '~/components/FilterAutocompleteFetch'
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin'
import FilterAutocompleteStations from '~/components/FilterAutocompleteStations'
import FilterAutocompleteStation from '~/components/FilterAutocompleteStation'

interface IGenerateField {
  readonly type: string
  readonly label: string
  readonly value: StateMethods<any>
  readonly id: number
}

export default function SystemSettings() {
  const { translate } = useLang()
  const processing = useHookstate<boolean>(false)
  const history = useHistory()
  const allSystemConfigs = useHookstate<readonly ISystemConfigAllByGroups[]>([])

  useEffect(() => {
    document.title = translate('System Settings - Dispatch System')
  }, [])

  function generateField({ type, label, value, id }: IGenerateField) {
    const labelChange = capitalize(splitCamelCase(toCamelCase(label)))

    const field = {
      string: (
        <TextField
          label={translate(labelChange)}
          name={translate(labelChange)}
          size="small"
          value={value.get()}
          onChange={(event) => {
            value.set(event.target.value)
          }}
          fullWidth
        />
      ),
      number: (
        <TextField
          fullWidth
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 0 },
          }}
          label={translate(labelChange)}
          name={translate(labelChange)}
          size="small"
          value={value.get()}
          onChange={(event) => {
            value.set(event.target.value)
          }}
        />
      ),
      boolean: (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(_, valueCheck) => {
                if (valueCheck) {
                  value.set('1')
                } else {
                  value.set('0')
                }
              }}
              checked={!!parseInt(value.get())}
            />
          }
          label={translate(labelChange)}
        />
      ),
      type: (
        <FilterAutocompleteType onTypeChange={(type) => value.set(type)} typeId={value.get()} label={labelChange}/>
      ),
      float: (
        <TextField
          fullWidth
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 0, step: 0.5 },
            
          }}
          label={translate(labelChange)}
          name={translate(labelChange)}
          size="small"
          value={value.get()}
          onChange={(event) => {
            value.set(event.target.value)
          }}
        />
      ),
      dispatch_closed_status: (
        <FilterAutocompleteClosedStatus onClosedStatusIdChange={(closedStatus) => value.set(closedStatus)} closedStatusId={value.get()} label={labelChange} />
      ),
      device: (
        <FilterAutocompleteDevice onDeviceChange={(device) => value.set(device)} deviceId={value.get()} label={labelChange} />
      ),
      user: (
        <FilterAutocompleteFetch label={labelChange} urlFetch={'/user/get'} size={"small"} onFetchChange={(user) => value.set(user)} model={'user'} valueId={value.get()} />
      ),
      entry_origin: (
        <FilterAutocompleteEntryOrigin onEntryOriginChange={(entryOrigin) => value.set(entryOrigin)} originId={value.get()} label={labelChange}/>
      ),
      station: (
        <FilterAutocompleteStation onStationsChange={(station) => value.set(station)} stationId={value.get()} label={labelChange} />
      )
    }

    return field[type]
  }

  const handleSubmit = async () => {
    const allConf = allSystemConfigs
      .get()
      .flatMap((system) => system.system_configs)
      .map((config) => ({
        id: config.id,
        name: config.name,
        value: config.val,
        required: config.required,
      }))
    updateAllSystemConfigService(allConf).then(() => {
      notify({ message: translate('Saved successfully'), type: 'success' })
    })
  }

  useEffect(() => {
    getAllSystemConfigService().then((systemConfigs) => {
      const insertFildsTypes = systemConfigs.map(
        (config): ISystemConfigAllByGroups => ({
          ...config,
          system_configs: config.system_configs.map((conf) => ({
            ...conf,
            val: conf.value,
            fieldType: conf.value_type,
          })),
        })
      )
      allSystemConfigs.set(insertFildsTypes)
    })
  }, [])

  return (
    <Layout marginLayout={true}>
      <InternalHeader title={translate('System Settings')} />
      {allSystemConfigs.map((configs) => (
        <InternalContent expandButton={false} key={configs.id.get()}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>
              {translate(capitalize(configs.name.get()))}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {translate(configs.description.get())}
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingY: 2,
            }}
          >
            <Grid container direction={'row'}>
              {configs.system_configs.map(({ id, fieldType, name, val }) => (
                <Grid item xs={3} sx={{ pr: 1, pt: 2 }} key={id.get()}>
                  {generateField({
                    type: fieldType.get(),
                    label: name.get(),
                    value: val,
                    id: id.get(),
                  })}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </InternalContent>
      ))}
      <CardActions disableSpacing>
        <ButtonForm
          type="cancel"
          onClick={() => {
            history.push('/')
          }}
        >
          {translate('Cancel')}
        </ButtonForm>
        <ButtonForm type="submit" onClick={handleSubmit}>
          <LoopIcon
            className="load-icon"
            sx={{ display: processing.get() ? 'block' : 'none' }}
          />
          {processing.get() ? translate('Saving') : translate('Save')}
        </ButtonForm>
      </CardActions>
    </Layout>
  )
}
