import React, { useEffect, useState } from 'react';
import { useHookstate, type State, type StateMethods } from '@hookstate/core';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import ISubtype from '~/features/Subtype/interfaces/ISubtype';
import { getAllSubTypes } from '~/features/Subtype/services';
import { useLang } from '~/hooks/useLang';
import type { ListSubtypesProps, typesAndSubtypesSelectedProps } from '../FilterAutocompleteTypesAndSubtypesWithDescription';
import IType from '~/features/Type/interfaces/IType'
import { element } from 'prop-types';


interface FilterAutocompleteSubtypesByTypeProps {
  readonly onSubtypesChange: (value: number[]) => void;
  readonly subtypes: readonly number[];
  readonly typeId: number | null;
  readonly refTypeIds?: State<number | null>
  readonly categoryId?: number | number[] | null
  readonly error?: boolean;
  readonly disabled?: boolean;
  readonly limitedTags?: number;
  readonly hideCode?: boolean;
  readonly ListSubtypesProps?: StateMethods<ListSubtypesProps[] | []>;
  readonly requiredSubType?: State<boolean>;
  readonly filterSubtypesSelecteds?: State<string[]>;
  readonly updateFilterSubtype?: State<boolean>;
}

export default function FilterAutocompleteSubtypesByType({ error, typeId, subtypes, limitedTags = 2, hideCode, ListSubtypesProps, categoryId, refTypeIds, requiredSubType, updateFilterSubtype, filterSubtypesSelecteds, onSubtypesChange }: FilterAutocompleteSubtypesByTypeProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly ISubtype[]>([]);
  const [optionsFilter, setOptionsFilter] = useState<readonly ISubtype[]>([]);
  const [value, setValue] = useState<ISubtype[] | undefined>([]);
  const loading = useHookstate(false)
  const disableField = useHookstate(false)


  useEffect(() => {
    getAllSubTypes().then((subTypesResponse) => {
      const resSubtypes = subTypesResponse.map((subtype) => ({
        ...subtype,
        displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
      }))
      setOptions(resSubtypes)
      setOptionsFilter(resSubtypes)
      setValue([])
    })

  }, [])

  useEffect(() => {
    if (typeId) {
      const filter = options
        .filter(element => typeId === element.type_id)
        .filter(objeto => !filterSubtypesSelecteds?.get().includes(objeto.code));
      setOptionsFilter(filter.length ? filter : [])
      filter.length > 0 ? requiredSubType?.set(true) : requiredSubType?.set(false)
    } else {
      setOptionsFilter(options)
      disableField.set(true)
    }
  }, [typeId, options, updateFilterSubtype?.get()])


  useEffect(() => {
    if (categoryId) {
      if (typeId && refTypeIds?.get() && value) {
        const filter = options.filter(element => element.categories?.find((category) => categoryId == category.id && typeId === element.type_id))
        setOptionsFilter(filter.length ? filter : [])
      } else if (options) {
        const filter = options.filter(element => element.categories?.find((category) => categoryId == category.id))
        setOptionsFilter(filter.length ? filter : [])
      } else {
        setOptionsFilter(options ?? [])
      }
    } else {
      setOptionsFilter(options ?? [])
    }
  }, [categoryId, options, refTypeIds?.get()])


  useEffect(() => {
    if (subtypes?.length) {
      setValue(optionsFilter.filter(option => subtypes.includes(option.id)))
      value && value?.length > 0 ? requiredSubType?.set(false) : requiredSubType?.set(true)
    } else {
      setValue([])
    }
  }, [subtypes, optionsFilter])

  useEffect(() => {
    if (!typeId) {
      setValue([]);
      setOptionsFilter([])
      onSubtypesChange([])
    }
  }, [options, typeId])


  const updateListDescription = (subtype) => {
    ListSubtypesProps?.set([])
    subtype.map((item) => {
      ListSubtypesProps?.merge([{
        id: item?.id ?? '',
        subTypeName: item?.name ?? '',
        subTypeCode: item?.code ?? '',
        typeId: item?.type_id ?? ''
      }])
    })
  }

  const disableSubTypeField = () => {
    if (categoryId || typeId) {
      return false
    }

    return true
  }

  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-subtypes'
        multiple
        limitTags={limitedTags}
        options={optionsFilter}
        disablePortal={true}
        disabled={disableSubTypeField()}
        value={value}
        filterSelectedOptions
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        disableCloseOnSelect
        size='small'
        sx={{ overflowY: 'auto' }}
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          setValue(subtype ?? null);
          onSubtypesChange(subtype.map(subtype => subtype.id) ?? []);

          updateListDescription(subtype)
          refTypeIds?.set(Number(subtype[0]?.type_id))

        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Subtypes')}
            helperText={error}
          />
        )}
      />
    </FormControl>
  )
}