/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState, type HTMLAttributes } from 'react';
import { Autocomplete, FormControl, Paper, TextField, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate, type State } from '@hookstate/core';
import { AgencyResponse } from './interface';
import { getAgencies } from './service';
import { getAgencyById } from '~/features/Agency/services';

interface AgencyProps {
  readonly onAgencyChange: (value: number | null) => void;
  readonly agencyId?: number | null;
  readonly disabled?: boolean;
  readonly error?: State<string | null | undefined>;
  readonly isMapManagement?: boolean;
}

export default function FilterAutocompleteAgency({ disabled, onAgencyChange, agencyId, error, isMapManagement = false }: AgencyProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly AgencyResponse[]>([]);
  const [value, setValue] = useState<AgencyResponse | null>(null);
  const loading = useHookstate(false);

  useEffect(() => {
    getAgencies().then(agencies => {
      setOptions(agencies)
    })

    if (agencyId) {
      getAgencyById(agencyId)
        .then((result) => {
          setValue({ id: Number(result.id), acronym: result.acronym, name: result.name });
        })
    }

  }, [])

  useEffect(() => {
    if (!agencyId) {
      setValue(null)
    }
  }, [agencyId])

  const updateValueAgency = ({ value }) => {
    if (isMapManagement) {
        onAgencyChange(value?.id)
    } else {
      onAgencyChange(value?.id ?? null)
    }

  }

  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-agency'
        options={options}
        disabled={disabled}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name && option.acronym ? `[${option.acronym}] ${option.name}` : option.name ?? ''}
        onChange={(_, agency) => {
          setValue(agency)
          updateValueAgency({ value: agency })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Agency:name')}
            error={!!error?.get()}
          // helperText={error?.get()}
          />
        )}
        // sx={{ height: 2 }}
        clearText={translate('Clear')}
      />
    </FormControl>
  )
}