import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState'
import OccurrenceForm from '~/features/Occurrence/components/OccurrenceForm';
import useSystemState from '~/features/System/stores/SystemState';
import { Chip, Grid, Stack, Tooltip } from '@mui/material';
import { dateTimeFormat, elapsedTime } from '~/utils/dateTime';
import { useHookstate, useState } from '@hookstate/core';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { useLocation } from 'react-router';
import { authFetch } from '~/services/fetch';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function OccurrenceModalDraggable() {
  const { translate } = useLang();
  const { occurrence } = useOccurrenceState();
  const { windowManager, loadingButton } = useSystemState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })
 
  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('OccurrenceModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.OccurrenceModalDraggable)
      }
      loadingComponent.set(false)
    })

    return () => {
      windowManager()['occurrence'].open.set(false)
    }
  }, [])

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'OccurrenceModalDraggable',
        isOpen: windowManager()['occurrence'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['occurrence'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  
  if (windowManager()['occurrence'].open.get() && occurrence().id.get() )   {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && !loadingComponent.get() &&
          <StandardModal
            title={
              <Grid container sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                {translate('Occurrence')}
                {' '}
                {occurrence().id.get() ? ' ID - ' + occurrence().id.get() : ''}

                <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px', gap: '16px' }}>

                  <Stack direction='row' alignItems='center' gap={1} >
                    <Tooltip title={occurrence()?.calls?.get() as number > 1 ? `${translate('Number of recalls')}` : `${translate('No recalls')}`} placement='top'>
                      <Chip variant={'outlined'} label={`${translate('Recalls')}: ${occurrence().calls.get() as number - 1}`} />
                    </Tooltip>
                  </Stack>

                  <Stack direction='row' alignItems='center' gap={1} >
                    <Tooltip title={`Despachado em: ${occurrence().created_at.get() ? dateTimeFormat(occurrence().created_at.get()?.toString()) : ''}`} placement='top'>
                      <Chip variant={'outlined'} label={elapsedTime((String(occurrence().created_at.get() ? occurrence().created_at.get()?.toString() : '')))} />
                    </Tooltip>
                  </Stack>

                </Grid>
              </Grid>
            }
            avatar={FeaturedPlayListIcon}
            dimensions={{
              width: 1000,
            }}
            handleStop={handleStop}
            isExpanded={windowManager()['occurrence'].minimized}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 650,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 200
            }}
            closeButton={() => windowManager().occurrence.open.set(false)}
          >
            <OccurrenceForm />
          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}