import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '../../../../components/StandardModal';
import { useLang } from '../../../../hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import { useHookstate, useState } from '@hookstate/core';
import DispatchDisunification from '../DispatchDisunification';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import useScreenSettingsState from '~/stores/ScreenSettingsState';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function DispatchDisunificationModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState();
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('DispatchDisunificationModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.DispatchDisunificationModalDraggable)
      }
    })

    return () => {
      windowManager()['DispatchDisunification'].open.set(false)
    }
  }, [])

  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchDisunificationModalDraggable',
        isOpen: windowManager()['DispatchDisunification'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['DispatchDisunification'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  if (windowManager()['DispatchDisunification'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={`${translate('Unified Dispatches')}`}
            dimensions={{
              width: 900
            }}
            handleStop={handleStop}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 250,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 100
            }}
            closeButton={() => windowManager()['DispatchDisunification'].open.set(false)}
            isExpanded={getPositionsDraggable.is_window_minimized}

            variant={'h5'}
          >
            {windowManager()['DispatchDisunification'].minimized.get() &&

              <DispatchDisunification />
            }

          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}
