/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import { GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { useLang } from '../../../../hooks/useLang'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'
import ProcedureList, { ConfigProcedureListProps } from '~/components/ProcedureList'
import { Downgraded, useHookstate } from '@hookstate/core'
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState'
import ResultListDispatches from './components/ResultListDispatches'
import type ScreenSettingsDraggableProps from '~/features/System/interfaces/ScreenSettings'
import { authFetch } from '~/services/fetch'

const modal = document.getElementById('div-modal') as HTMLElement

export default function DispatchResultSearchesModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const { occurrence } = useOccurrenceState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('DispatchResultSearchesModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.DispatchResultSearchesModalDraggable)
      }
      loadingComponent.set(false)

    })

    return () => {
      windowManager()['dispatchesResultSearches'].open.set(false)
    }
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchResultSearchesModalDraggable',
        isOpen: windowManager()['dispatchesResultSearches'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['dispatchesResultSearches'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])



  if (windowManager()['dispatchesResultSearches'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && !loadingComponent.get() && (
          <StandardModal
            title={
              <Typography variant='subtitle1'>
                {translate('Dispatches')}
              </Typography>
            }
            dimensions={{
              width: 300,
              height: 400
            }}
            handleStop={handleStop}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 80,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 141,
            }}
            closeButton={() => windowManager()['dispatchesResultSearches'].open.set(false)}
            isExpanded={getPositionsDraggable.is_window_minimized}
          >
            <ResultListDispatches occurrenceId={occurrence().id.get()} />
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}