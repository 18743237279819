/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { useHookstate } from '@hookstate/core'
import { StationsResponse } from './interfaces'
import { getStations } from './services'

interface StationProps {
  readonly onStationsChange: (value: number | null) => void
  readonly stationId?: number
  readonly disabled?: boolean
  readonly label?: string
}

export default function FilterAutocompleteStations({
  onStationsChange,
  stationId,
  label
}: StationProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly StationsResponse[]>([])
  const [value, setValue] = useState<StationsResponse | null>(null)
  const loading = useHookstate(false)

  useEffect(() => {
    getStations().then((stations) => {
      setOptions(stations)
      setValue(null)
    })
  }, [])

  useEffect(() => {
    if (!stationId) {
      setValue(null)
    } else {
      setValue(options.find((item) => item.id == stationId) ?? null)
    }
  }, [options, stationId])

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        filterSelectedOptions
        disableCloseOnSelect
        limitTags={2}
        sx={{ height: 1 }}
        ListboxProps={{ style: { maxHeight: '15rem' } }}
        id="filter-stations"
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size="small"
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onStationsChange(type ? type.id : null)
        }}
        renderInput={(params) => (
          <TextField {...params} label={translate(label ? label : 'Station')} />
        )}
      />
    </FormControl>
  )
}
