import React from 'react';
import activationRoutes from '~/features/ActivateStation/routes';
import authRoutes from '~/features/auth/routes';
import entryRoutes from '~/features/Entry/routes';
import dispatchRoutes from '~/features/Dispatch/routes';
import dispatchGroupRoutes from '~/features/DispatchGroup/routes';
import drugTypeRoutes from '~/features/DrugType/routes';
import involvementTypeRoutes from '~/features/InvolvementType/routes';
import objectTypeRoutes from '~/features/ObjectType/routes';
import circunstanceRoutes from '~/features/Circunstance/routes';
import peopleCircunstanceRoutes from '~/features/PeopleCircunstance/routes';
import destinationTypeRoutes from '~/features/Destination/routes';
import unitMeasurementTypeRoutes from '~/features/UnitMeasurement/routes';
import cameraRoutes from '~/features/Cameras/routes';
import typeRoutes from '~/features/Type/routes';
import subTypeRoutes from '~/features/Subtype/routes';
import agencyRoutes from '~/features/Agency/routes';
import areaConfigRoutes from '~/features/DispatchAreaConfig/routes';
import areaPlanRoutes from '~/features/AreaPlan/routes';
import stationRoutes from '~/features/Station/routes';
import dispatchClosedStatusRoutes from '~/features/DispatchClosedStatus/routes';
import entryOriginRoutes from '~/features/EntryOrigin/routes';
import entryStatusRoutes from '~/features/EntryStatus/routes';
import districtRoutes from '~/features/District/routes';
import cityRoutes from '~/features/City/routes';
import vehicleRoutes from '~/features/Vehicle/routes';
import vehicleBrandRoutes from '~/features/VehicleBrand/routes';
import vehicleColorRoutes from '~/features/VehicleColor/routes';
import vehicleModelRoutes from '~/features/VehicleModel/routes';
import vehicleTypeRoutes from '~/features/VehicleType/routes';
import deviceRoutes from '~/features/Device/routes';
import publicAgencyRoutes from '~/features/Organization/routes';
import deviceOutOfServiceReasonRoutes from '~/features/DeviceOutOfServiceReason/routes';
import systemRoutes from '~/features/System/routes';
import tacticalRoutes from '~/features/Tactical/routes';
import userRoutes from '~/features/User/routes';
import { AppRouteProps } from '.';
import managementRoutes from '~/features/Management/routes';
import weaponTypeRoutes from '~/features/WeaponType/routes';
import weaponKindRoutes from '~/features/WeaponKind/routes';
import weaponBrandRoutes from '~/features/WeaponBrand/routes';
import firearmCaliberRoutes from '~/features/FirearmCaliber/routes';
import actionTakenRoutes from '~/features/ActionTaken/routes';
import documentTypeRoutes from '~/features/DocumentType/routes';
import educationRoutes from '~/features/Education/routes';
import genderRoutes from '~/features/Gender/routes';
import ethnicityRoutes from '~/features/Ethnicity/routes';
import operationRoutes from '~/features/Operation/routes';
import contactCenterRoutes from '~/features/ContactCenter/routes';
import reportBuilderRoutes from '~/features/Report/routes';
import friendlyTypeRoutes from '~/features/FriendlyType/routes';
import deviceTypeRoutes from '~/features/DeviceType/routes';
import dispatchCodeRoutes from '~/features/DispatchCode/routes';
import dispatchClosedStatusClassRoutes from '~/features/ClosedStatusClass/routes';
import videoMonitoringRoutes from '~/features/VideoMonitoring/routes';
import announcementRoutes from '~/features/Announcement/routes';
import userGroup from '~/features/UserPermissions/routes';
import categoryRoutes from '~/features/Category/Routes';
import operationUnitRoutes from '~/features/OperationUnit/routes';
import interestPointTypeRoutes from '~/features/InterestPointType/routes';
import clerkRoutes from '~/features/Clerk/routes';
import interestPointSubtypeRoutes from '~/features/InterestPonitSubtype/Routes';
import interestPointRoutes from '~/features/InterestPoint/routes';
import procedureRoutes from '~/features/Procedure/routes';
import procedureConfigRoutes from '~/features/ProcedureConfig/routes';
import dispatchGroupSetRoutes from '~/features/DispatchGroupSet/routes';
import specieRoutes from '~/features/Specie/routes';
import breedRoutes from '~/features/Breed/routes';
import deviceOnMissionReasonRoutes from '~/features/DeviceOnMission/pages/routes';
import occupationRoutes from '~/features/Occupation/routes';
import searchLogsRoutes from '~/features/Audit/routes';

const routers: readonly AppRouteProps[] = [
    /* Autenticação */
    ...activationRoutes(),
    ...authRoutes(),
    /* Atendimento */
    ...entryRoutes(),
    /* Despacho */
    ...dispatchRoutes(),
    ...dispatchGroupRoutes(),

    /* Administração */

    /* <AdministrationRoutes /> */
    ...userRoutes(),
    ...agencyRoutes(),
    ...areaConfigRoutes(),
    ...areaPlanRoutes(),
    ...stationRoutes(),
    ...deviceOutOfServiceReasonRoutes(),
    ...dispatchClosedStatusRoutes(),
    ...typeRoutes(),
    ...subTypeRoutes(),
    ...cameraRoutes(),
    ...entryOriginRoutes(),
    ...entryStatusRoutes(),
    ...districtRoutes(),
    ...cityRoutes(),
    ...drugTypeRoutes(),
    ...involvementTypeRoutes(),
    ...objectTypeRoutes(),
    ...circunstanceRoutes(),
    ...peopleCircunstanceRoutes(),
    ...destinationTypeRoutes(),
    ...unitMeasurementTypeRoutes(),
    ...vehicleRoutes(),
    ...vehicleBrandRoutes(),
    ...vehicleColorRoutes(),
    ...vehicleModelRoutes(),
    ...vehicleTypeRoutes(),
    ...deviceRoutes(),
    ...contactCenterRoutes(),
    ...friendlyTypeRoutes(),
    ...publicAgencyRoutes(),
    ...weaponTypeRoutes(),
    ...weaponKindRoutes(),
    ...weaponBrandRoutes(),
    ...firearmCaliberRoutes(),
    ...actionTakenRoutes(),
    ...documentTypeRoutes(),
    ...educationRoutes(),
    ...genderRoutes(),
    ...ethnicityRoutes(),
    ...deviceTypeRoutes(),
    ...dispatchClosedStatusClassRoutes(),
    ...userGroup(),
    ...categoryRoutes(),
    ...operationUnitRoutes(),
    ...interestPointTypeRoutes(),
    ...interestPointSubtypeRoutes(),
    ...interestPointRoutes(),
    ...occupationRoutes(),

    ...operationRoutes(),
    ...dispatchCodeRoutes(),
    ...procedureRoutes(),
    ...procedureConfigRoutes(),
    ...dispatchGroupSetRoutes(),
    ...specieRoutes(),
    ...breedRoutes(),
    ...searchLogsRoutes(),
    // ...deviceOnMissionReasonRoutes(),

    /* Sistema */
    ...systemRoutes(),
    /* Tatico */
    ...tacticalRoutes(),
    /* Gestão */
    ...managementRoutes(),
    ...reportBuilderRoutes(),

    /* Video Monitoramento */
    ...videoMonitoringRoutes(),

    /*  */
    ...announcementRoutes(),

    ...clerkRoutes()
];

export default routers;