import { useHookstate } from "@hookstate/core";
import React, { useEffect } from "react"
import ReactDOM from "react-dom";
import StandardModal from "~/components/StandardModal";
import useSystemState from "~/features/System/stores/SystemState";
import { useLang } from "~/hooks/useLang";
import { InsertDriveFile } from '@mui/icons-material';
import SearchStaff from "../SearchStaff";
import ScreenSettingsDraggableProps from "~/features/Entry/interfaces/IPositionDraggable";
import { authFetch } from "~/services/fetch";
import useScreenSettingsState from "~/stores/ScreenSettingsState";
import { storeScreenSettings } from "~/features/System/services";


const modal = document.getElementById('div-modal') as HTMLElement;


export default function SearchStaffModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('SearchStaffModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.SearchStaffModalDraggable)
      }
      loadingComponent.set(false)

    })

    return () => {
      windowManager()['searchStaff'].open.set(false)
    }
  }, [])
  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchStaffModalDraggable',
        isOpen: windowManager()['searchStaff'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['searchStaff'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])


  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !loadingButton.get() && !loadingComponent.get() && 
        <StandardModal
          title={`${translate("Search staffs")}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 400
          }}
          isExpanded={windowManager()['searchStaff'].minimized}
          handleStop={handleStop}
          position={{
            x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 250,
            y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 100
          }}

          closeButton={() => windowManager()['searchStaff'].open.set(false)}
        >
          <SearchStaff />
        </StandardModal>
      }
    </>
    , modal);
}