import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import SearchInvolvement from '../SearchInvolvement';
import { Downgraded, useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function SearchInvolvementModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('SearchInvolvementModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.SearchInvolvementModalDraggable)
      }
      
    }).finally(() => {
      loadingComponent.set(false)
    })

    return () => {
      windowManager()['searchInvolvement'].open.set(false)
    }
  }, [])
  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchInvolvementModalDraggable',
        isOpen: windowManager()['searchInvolvement'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['searchInvolvement'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['searchInvolvement'].open.value &&!loadingButton.get() && !loadingComponent.get() &&
        <StandardModal
          title={`${translate('Search Involvement')}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 500
          }}
          isExpanded={getPositionsDraggable.is_window_minimized}
          handleStop={handleStop}
          position={{
            x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 80,
            y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 141,
          }}
          // initialPosition={initialPosition}
          closeButton={!(windowManager()['searchInvolvement'].disabled.value) ? () =>{ 
            windowManager()['searchInvolvement'].open.set(false)
            windowManager()['searchInvolvement'].dataSearchInvolvement.set([])

          }: false}
        >
          <SearchInvolvement data={windowManager()['searchInvolvement'].dataSearchInvolvement.get()}/>
        </StandardModal>
      }
    </>
    , modal);
}
