import React from "react";
import { IconButton, Grid } from '@mui/material';
import { DirectionsCar, ShareLocation, DriveFileRenameOutline } from '@mui/icons-material';
import DispatchClosedButton from "~/features/Dispatch/components/DispatchClosedButton";
import AddVehicleButton from '../AddVehicleButton';
import AddVehicleEntryButton from '../AddVehicleEntryButton';
import DispatchCopyButton from "~/features/Dispatch/components/DispatchCopyButton";
import DispatchTimeline from "~/features/Dispatch/components/DispatchTimeline";
import HighlightButton from "~/components/HighlightButton";
import DispatchReopenButton from "~/features/Dispatch/components/DispatchReopenButton";
import DispatchHoldButton from "~/features/Dispatch/components/DispatchHoldButton";
import UnifyButton from "~/features/Dispatch/components/UnifyButton";
import DisunifyButton from "~/features/Dispatch/components/DisunifyButton";
import RegistrationButton from "../RegistrationButton";
import TransferGroupDispachButton from '~/features/Dispatch/components/TransferGroupDispachButton';
import userUserState from '~/features/User/stores/UserState';
import SetDispatchButton from '~/features/Entry/components/EntrySetDispatchGroupButton';
import VideoMonitoringButton from "~/features/VideoMonitoring/components/VideoMonitoringButton";
import EntryShowProcedureButton from "~/features/Entry/components/EntryShowProcedureButton";

interface ButtonActionsVerticalContainerProps {
  readonly readonly: boolean;
  readonly isValid: boolean;

  readonly canChronology?: boolean;
  readonly chronology?: null | number | string;

  readonly canAddVehicles?: boolean;
  readonly canAddReportingRecord?: boolean;

  readonly canAddVehiclesEntry?: boolean;

  readonly hasVehicles?: boolean;
  readonly vehicles?: VoidFunction;

  readonly registration?: boolean;

  readonly canCopy?: boolean;
  readonly copy?: (params: any) => Promise<void>;

  readonly canLocation?: boolean;
  readonly location?: VoidFunction;

  readonly canMedia?: boolean;
  readonly media?: VoidFunction;

  readonly canUnify?: boolean;
  readonly unify?: (params: any) => Promise<void>;

  readonly canDisunify?: boolean;
  readonly showDisunify?: boolean;

  readonly canOccurrenceName?: boolean;
  readonly occurrenceName?: VoidFunction;

  readonly canClose?: boolean;
  readonly close?: (params: any) => Promise<void>;

  readonly canHighlight?: boolean;
  readonly highlight?: boolean;
  readonly dispatchId?: null | number | string;
  readonly setHighlight?: VoidFunction;

  readonly canReopen?: boolean;
  readonly reopen?: (loading) => Promise<void>;

  readonly onHold?: boolean;
  readonly canHold?: boolean;
  readonly hold?: (minutes, comment, loading) => Promise<void>;

  readonly canSetDispatchGroup?: boolean;
  readonly dispatchGroup?: (params: any) => Promise<void>;

  readonly hasTransferGroupDispach?: boolean;

  readonly canShowPEntryrocedure?: boolean;

  readonly statusId?: number; 
}

export default function ButtonActionsVerticalContainer(props: ButtonActionsVerticalContainerProps) {
  const { verifyPermission } = userUserState();

  return (
    <Grid
      item
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      paddingLeft={1.5}
      gap={1.3}
    >

      {props.canChronology ? <DispatchTimeline canChronology={props.canChronology} chronology={props.chronology} /> : false}

      {(verifyPermission(['entry_manually_set_dispatch_group'])) && (
        props.dispatchGroup ? <SetDispatchButton canSetDispatchGroup={props.canSetDispatchGroup && props.isValid && !props.readonly} setManuallyDispatchGroup={props.dispatchGroup} /> : false
      )}

      {props.canShowPEntryrocedure && (
        <EntryShowProcedureButton />
      )}

      {props.canAddVehicles ? <AddVehicleButton canAddVehicles={props.canAddVehicles && !props.readonly && props.statusId != 8 && props.statusId !== 10} /> : false}

      {props.canAddVehiclesEntry ? <AddVehicleEntryButton canAddVehicles={props.canAddVehiclesEntry && !props.readonly} isValid={props.isValid} /> : false}


      {props.registration ? <RegistrationButton canAddRegistration={props.registration && !props.readonly} /> : false}

      {props.vehicles ?
        (<Grid item>
          <IconButton disabled={!props.hasVehicles} onClick={() => props.vehicles ? props.vehicles() : false}>
            <DirectionsCar />
          </IconButton>
        </Grid>) : false}

      {props.copy ? (<DispatchCopyButton canCopy={props.canCopy && !props.readonly} copyDispatch={props.copy} />) : false}

      {props.location ?
        (<Grid item>
          <IconButton disabled={!props.canLocation} onClick={() => props.location ? props.location() : false}>
            <ShareLocation />
          </IconButton>
        </Grid>) : false}

      {/* { props.hasTransferGroupDispach ? <TransferGroupDispachButton canTransferGroupDispatch={!props.readonly} hasTransferGroupDispach={props.hasTransferGroupDispach}/> : false } */}
      {props.hasTransferGroupDispach ?
        <TransferGroupDispachButton canTransferGroupDispatch={!props.readonly} hasTransferGroupDispach={props.hasTransferGroupDispach && !props.readonly && props.statusId != 8 && props.statusId !== 10} />
        : false
      }

      {/* {props.media ?
      (<Grid item>
        <Button type="submit" variant="contained" sx={{ backgroundColor: 'info.main' }} disabled={!props.canMedia} onClick={() => props.media ? props.media() : false}>
          <Subscriptions />
        </Button>
      </Grid>) : false} */}

      {props.unify ? <UnifyButton canUnify={props.canUnify && !props.readonly} unify={props.unify} /> : false}

      {props.showDisunify ? <DisunifyButton canDisunify={props.canDisunify && !props.readonly} /> : false}

      {props.canHighlight ?
        (<HighlightButton canHighlight={props.canHighlight && !props.readonly} highlight={props.highlight} dispatchId={props.dispatchId} setHighlight={props.setHighlight} />) : false
      }

      {props.occurrenceName ?
        (<Grid item>
          <IconButton disabled={!props.canOccurrenceName && !props.readonly} onClick={() => props.occurrenceName ? props.occurrenceName() : false}>
            <DriveFileRenameOutline />
          </IconButton>
        </Grid>) : false}

      {props.close ? (<DispatchClosedButton canClose={props.canClose && !props.readonly} closeDispatch={props.close} />) : false}

      {props.reopen ? (<DispatchReopenButton canReopen={props.canReopen && !props.readonly} reopenDispatch={props.reopen} />) : false}

      {props.hold ? (<DispatchHoldButton canHold={props.canHold && !props.readonly} holdDispatch={props.hold} onHold={props.onHold} />) : false}

      {props.canAddReportingRecord ? <VideoMonitoringButton canAddReportingRecord={props.canAddReportingRecord} /> : false}

    </Grid>)
}
