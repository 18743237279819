import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Method } from 'axios';
import { IParamsTable } from '~/interfaces/IParamsTable';

type CrudAutoCompleteAgregateFieldProps = {
  readonly register: any;
  readonly name: string;
  readonly namePrimary: string;
  readonly nameSecondary: string;
  readonly modelPrimary: any;
  readonly modelSecondary: any;
  readonly control: any;
  readonly placeholderPrimary?: string;
  readonly placeholderSecondary?: string;
  readonly optionSelect?: any;
  readonly method?: Method;
  readonly setFormValue: UseFormSetValue<any>;
  readonly requiredPrimary?: boolean;
  readonly requiredSecondary?: boolean;
  readonly getValues?: UseFormGetValues<any>;

}

export default function CrudAutoCompleteAgregateField(props: CrudAutoCompleteAgregateFieldProps) {
  const { translate } = useLang();
  const { register, name, namePrimary, setFormValue, nameSecondary, modelPrimary, modelSecondary, control, placeholderPrimary = '', placeholderSecondary = '', optionSelect, method = 'POST', requiredPrimary = false, requiredSecondary = false, getValues} = props;
  const [itemsPrimary, setItemsPrimary] = useState([]);
  const [itemsSecondary, setItemsSecondary] = useState([]);
  const [primaryId, setprimaryId] = useState(getValues ? getValues(namePrimary) : 0)
  const [secondaryId, setSecondaryId] = useState(getValues ? getValues(nameSecondary) : 0)

  useEffect(() => {
    const fetchDataPrimary = async () => {
      const targetUrl = `/${modelPrimary}/`;
      const response = await authFetch({
        url: targetUrl,
        method,
        data: {
          limit: 100000,
          filters: [],
          orders: [{ field: 'name', sort: 'asc' }]
        }
      });

      const data = method === 'POST' ? response.data.data : response.data

      if (itemsPrimary.length <= 0 && data.length > 0) {
        setItemsPrimary(data);
      }
    }

    fetchDataPrimary()
  }, [])

  useEffect(() => {  
    if(primaryId){
      const fetchDataSecondary = async ({
        filters = [],
        limit = 1000,
        orders = [
          { field: 'subtypes.name', sort: 'asc' }
        ] }: IParamsTable = {}) => {
        const targetUrl = `/${modelPrimary}/${primaryId}/${modelSecondary}`;
        const response = await authFetch({
          url: targetUrl,
          method: 'GET',
          data: {
            limit,
            filters,
            orders,
          }
        });
  
        const data = response.data
  
        if (data.length > 0) {
          setItemsSecondary(data);
        } else {
          setItemsSecondary([])
        }
      }
  
      
      fetchDataSecondary({
        filters: [
        { field: 'type.id', operator: 'equals', value: '' }
        ],
      })
  
        .catch(console.error);
    }else{
      setSecondaryId(0)
      setItemsSecondary([])
    }

  }, [primaryId]);

  const listItemsPrimary = itemsPrimary.map((item: any) => ({
    key: item['id'],
    value: item['id'],
    label: optionSelect ? item[optionSelect] : item['name'],
  })).filter((value1, idx1, arr1) => arr1.findIndex((value2) => (value2.key === value1.key)) === idx1);

  const listItemsSecondary = itemsSecondary.map((item: any) => ({
    key: item['id'],
    value: item['id'],
    label: optionSelect ? item[optionSelect] : item['name'],
    typeId: item['type_id'],
  })).filter((value1, idx1, arr1) => arr1.findIndex((value2) => (value2.key === value1.key)) === idx1);

  const valueSecondary = listItemsSecondary.find((item) => item.key === secondaryId)

  return (
      <>
      <Grid item xs={4}>
        <Controller
          {...register(`${namePrimary}`)}
          name={namePrimary}
          control={control}
          render={(props) => (
            <Autocomplete
              {...props}
              options={listItemsPrimary}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option?.key === value?.key}
              onChange={(_, newValue) => {
                setprimaryId(newValue?.value), 
                setFormValue(namePrimary, newValue?.value);
                setFormValue(nameSecondary, null)
                setSecondaryId(0)
              } }
              renderInput={(params) => 
              <TextField
                {...params}
                label={translate(placeholderPrimary)}
                required={requiredPrimary} 
              />}
              value={listItemsPrimary.find((item) => item.key === primaryId)} />
          )} />
      </Grid>
      <Grid item xs={4}>
        <Controller
          {...register(`${nameSecondary}`)}
          name={nameSecondary}
          control={control}
          render={(props) => (
            <Autocomplete
              {...props}
              options={listItemsSecondary.filter((option) => option.typeId === primaryId)}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option?.key === value?.key}
              onChange={(_, newValue) => { setSecondaryId(newValue?.value), setFormValue(nameSecondary, newValue ? newValue?.value : null); 
              } }
              renderInput={(params) => 
              <TextField
                {...params}
                label={translate(placeholderSecondary)} 
                required={requiredSecondary} 
                />}
              value={valueSecondary ? valueSecondary : null}
            />
          )} />
        </Grid>
      </>
  )
}

