import React, { useEffect, useState } from 'react';
import { getCircunstance, getCircunstanceById } from '~/features/Circunstance/services/index';
import { useLang } from '~/hooks/useLang';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useHookstate, type State } from '@hookstate/core';
import type { CircunstanceResponse } from './interfaces';
import { result } from 'lodash';
interface circunstanceSelectFieldProps {
  readonly isValid: boolean;
  readonly circunstanceId: State<number | null>;
  readonly disabled?: boolean;
}

export default function CircunstanceSelectField({ isValid, circunstanceId, disabled }: circunstanceSelectFieldProps) {
  const [value, setValue] = useState<CircunstanceResponse | null>(null);
  const [options, setOptions] = useState<readonly CircunstanceResponse[]>([]);
  const isFirstRender = React.useRef(true);
  const { translate } = useLang();

  useEffect(() => {
    getCircunstance().then(categories => {
      setOptions(categories)
    }).finally(() => {
    })
  }, [])
  
  useEffect(() => {
    if (value && circunstanceId) {
      if (circunstanceId?.get() != (value ? value['id'] : null)) {
        isFirstRender.current = true;
        setValue(null);
      }
    }

    if (isFirstRender.current) {
      if (circunstanceId?.get()) {
        getCircunstanceById(circunstanceId.get())
          .then((result) => {
            setValue(result);
          })
        isFirstRender.current = false;
      }
    }
  }, [circunstanceId.get()]);

  
  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-circunstance'
        options={options}
        disabled={disabled}
        value={value}
        defaultValue={value}
        clearText={translate('Clear')}
        noOptionsText={translate('No options')}
        size='small'
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, circunstance) => {
          setValue(circunstance)
          circunstanceId.set(circunstance ? circunstance.id : null)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Circunstance')}
          />
        )}
      />
    </FormControl>
  );
}