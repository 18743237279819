import type { typesAndSubtypesSelectedProps } from "~/components/FilterAutocompleteTypesAndSubtypesWithDescription";
import { toSnakeCase } from "./strings";
import type { State } from '@hookstate/core'


interface formatTypeAndSubtypeForReportProps {
  readonly typesAndSubtypesSelected: typesAndSubtypesSelectedProps[]
  readonly typesFormatedForSending: State<number[] | []>
  readonly subTypesFormatedForSending: State<number[] | []>
}

export function formatDateHour(data: string | number) {
  const date = new Date(data)
  const day = date.getDate();
  const month = (date.getMonth() + 1);
  const year = date.getFullYear();

  const hour = date.getHours();
  const minutes = date.getMinutes();

  return {
    toFormatedDate: `${String(day).padStart(2, '0')}/${String(month).padStart(
      2, '0')}/${String(year).padStart(2, '0')}  ${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`,
    day: day,
    month: month,
    year: year,
    hour: hour,
    minutes: minutes
  };
}

export type DateAcronym = "MM" | "YY" | "DD";

const verifyDateAttributeLength = (str: string | number) => {
  let string = str as string;

  if (typeof str === 'number') {
    string = str.toString();
  }

  if (string.length < 2) {
    return `0${string}`;
  }
  return string;
}

const selectDateAttribute = (acronym: DateAcronym, dateObj: any) => {
  if (acronym === 'DD') {
    const day = dateObj['day'] as string;
    return verifyDateAttributeLength(day);
  };
  if (acronym === 'MM') {
    const mounth = dateObj['month'];
    return verifyDateAttributeLength(mounth);
  };
  if (acronym === 'YY') {
    return dateObj['year'];
  };
}

export function formatDate(date: string): string {
  const sequence: DateAcronym[] = ['YY', 'MM', 'DD'];
  const dateObj = formatDateHour(date);

  let formatedDate = "";
  sequence.forEach((value, index) => {
    formatedDate += selectDateAttribute(value, dateObj);

    if (!(index === (sequence.length - 1))) {
      formatedDate += '-';
    }
  });

  return formatedDate;
}

export function objectToSnakeCase(object, defaultValue: object | null = null) {
  const newObject = {}
  Object.keys(object).forEach(key => {
    if (!key.includes('_')) {
      object[toSnakeCase(key)] = object[key];
    }
  })
  const newKeys = Object.keys(object).filter((key) => !/[A-Z]/.test(key))
  newKeys.map((newKey) => {
    if (typeof object[newKey] == 'string' && object[newKey] == '') {
      newObject[newKey] = null
    } else if (!object[newKey] && defaultValue && defaultValue.hasOwnProperty(newKey)) {
      newObject[newKey] = defaultValue[newKey]
    } else {
      newObject[newKey] = object[newKey]
    }
  })
  return newObject
}

export function formatPhone(phone: string | null) {
  if (phone?.length === 11) {
    const phoneNumber = phone.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
    return phoneNumber ? '(' + phoneNumber[1] + ') ' + phoneNumber[2] + '-' + phoneNumber[3] : ''
  } else if (phone?.length === 10) {
    const phoneNumber = phone.replace(/\D/g, '').match(/(\d{2})(\d{4})(\d{4})/);
    return phoneNumber ? '(' + phoneNumber[1] + ') ' + phoneNumber[2] + '-' + phoneNumber[3] : ''
  } else {
    return ''
  }

}

export function formatTypeAndSubtypeForReport({
  typesAndSubtypesSelected,
  typesFormatedForSending,
  subTypesFormatedForSending }: formatTypeAndSubtypeForReportProps) {

  if (typesAndSubtypesSelected) {
    typesAndSubtypesSelected.map((type) => {
      typesFormatedForSending.merge([Number(type.id)])

      if (type.subtype.length) {
        type.subtype.map((subtype) => {
          subTypesFormatedForSending.merge([subtype.id])
        })
      }
    })
  }
}