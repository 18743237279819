import React from 'react';
import notify from '~/utils/notify';
import { useLang } from '../../../hooks/useLang';
import { createState, useState } from '@hookstate/core';
import IListMessage from '../interfaces/IListMessage';
import IMessage from '../interfaces/IMessage';
import INewMessage from '../interfaces/INewMessage';
import IMessageState from '../interfaces/IMessageState';
import { authFetch } from '~/services/fetch';
import {syncMessageReceived, syncMessageSent, syncMessagesNotReadCount} from '../services';

const ListMessageDefault:IListMessage = {
  messageReceived: [],
  messageSent: [],
  messagesNotReadCount: 0,
}

const currentMessageDefault:IMessage = {
  id: null,
  sender: {
    id: null,
    name: '',
    representationName: '',
  },
  receiver: {
    id: null,
    name: '',
    representationName: '',
  },
  messageDate: '',
  message: '',
  readAt: null,
  readUser: null
}

const newMessageDefault:INewMessage = {
  receiver: {
    id:null,
    name:null
  },
  receiverType: 'user',
  message: '',
}

const store = createState<IMessageState>({
  currentMessage:  Object.create(currentMessageDefault),
  newMessage: Object.create(newMessageDefault),
  listMessage: Object.create(ListMessageDefault),
  listReceiver: [],
  currentListMessage: 'received',
  isWritingMessage: true,
});

export default function useMessageState(){
  const { translate } = useLang()
  const { listMessage, listReceiver, currentMessage, currentListMessage, newMessage, isWritingMessage } = useState(store);
  const { messageReceived, messageSent, messagesNotReadCount } = useState(listMessage);

  const resetNewMessage = () => {
    newMessage.set( {receiver: {id:null, name: null}, receiverType: 'user', message: ''} );
  }

  return ({
    messageReceived: () => messageReceived,
    messageSent: () => messageSent,
    resetNewMessage: () => resetNewMessage,
    syncMessageReceived: () => syncMessageReceived(messageReceived, messagesNotReadCount),
    syncMessageSent: () => syncMessageSent(messageSent),
    currentMessage: () => currentMessage,
    isWritingMessage: () => isWritingMessage,
    newMessage: () => newMessage,
    currentListMessage: () => currentListMessage,
    sendNewMessage: async () => {
      await authFetch({
        url: '/message/send',
        data: {
          message: newMessage.message.get(),
          file: null,
          to_user:(newMessage.receiverType.get() == 'user' ? newMessage.receiver.id.get() : null),
          to_dispatch_group: (newMessage.receiverType.get() == 'dispatchGroup' ? newMessage.receiver.get() : null),
          to_station: (newMessage.receiverType.get() == 'station' ? newMessage.receiver.id.get() : null),
          to_vehicle: (newMessage.receiverType.get() == 'vehicle' ? newMessage.receiver.id.get() : null),
      }
      }).then(() => { 
        syncMessageReceived(messageReceived, messagesNotReadCount);
        syncMessageSent(messageSent);
        resetNewMessage();
        notify({message: `${translate('Message sent successfully!')}`, type: 'success'});
      })
    },
    syncListReceiver: async () => {
      if(newMessage.receiverType.get() != ''){
        await authFetch({
          url: `/${newMessage.receiverType.get()}`,
          method: 'GET',
        }).then((response) => { 
          listReceiver.set(response.data) 
        } )
      }
    },
    listReceiver: () => listReceiver,
    readMessage: async (id) => {
      await authFetch({
        url: `/message/read`,
        method: 'POST',
        data: {
          'idRef': id
        }
      }).then(() => { 
        syncMessageReceived(messageReceived, messagesNotReadCount);
      })
    },
    messagesNotReadCount: () => messagesNotReadCount,
    syncMessagesNotReadCount: () => syncMessagesNotReadCount(messagesNotReadCount),
  })
}
