import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import UserGroup from '../Interfaces/UserGroup';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<UserGroup>();
  const fields = [
    { 
      name: 'name', 
      type: 'string' 
    },
    { 
      name: 'permissions', 
      type: 'transferList', 
      options: { firstListUrl: '', secondListUrl: '/permission' }, 
      model: '/group/store', 
      method: 'POST',
      placeholder: 'Permissions',
      showColumnText: 'description'
    },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'group'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Users group successfully created!'}
        title={'Add Users group'}
        subheader={'Add new Users group'}
        setFormValue={setFormValue}
        />
    </Layout>
  )
}
