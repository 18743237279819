/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { getEntryByTypeSubtype } from '../../services'
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic'
import PieChart from '~/components/PieChart'
import ColumnChartHorizontal from '~/components/ColumnChartMui/HorizontalChart'

export default function EntryBySubtypeChart({
  agencyId,
  deviceId,
  typeId,
  subtypeId,
  startDate,
  endDate,
  exludeClosed = false,
  showTitle = false,
  refresh,
  loading,
  cityId,
}: IEntryStatisticGraphProps) {
  const data = useHookstate([])
  const subtypeTotal = useHookstate<number>(0)


  useEffect(() => {
    loading.set(true)
    subtypeTotal.set(0)
    getEntryByTypeSubtype({
      agencyId,
      deviceId,
      typeId,
      subtypeId,
      startDate,
      endDate,
      exludeClosed,
      cityId,
    })
      .then((response) => {
        const result = response.map((data) => {
          subtypeTotal.set(subtypeTotal.get() + Number(data.total))

          return { item: data.name, value: data.total }
        })
        return data.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <ColumnChartHorizontal
      data={data.attach(Downgraded).get()}
      title={`${showTitle ? 'Subtype Chart' : ''}`}
      subtitle={`Total de Atendimentos: ${Intl.NumberFormat('pt-BR').format(
        Number(subtypeTotal.get())
      )}`}
      typeTotal={'Subtypes'}
      colorBar="secondary"
    />
  )
}
