
import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { getInstanceSocket, defaultRooms } from '../services/socket';
import { Socket as SocketProps } from 'socket.io-client';
import useCookie from '~/hooks/useCookie';
import constants from '~/core/constants';
import messageSocket from '~/features/Message/services/socket';
import dispatchSocket from '~/features/Dispatch/services/socket';
import deviceSocket from '~/features/Device/services/socket';
import useMessageState from '~/features/Message/stores/MessageState';
import { useLang } from '~/hooks/useLang';
import useDispatchCommentState from '~/features/Dispatch/stores/DispatchCommentState';
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState';
import { useMarkerState } from '~/features/Map/stores/MapState';
import { useListAllVehicleState, useListAllVehicleNoPaginationState } from '~/components/ListAllVehicle';
import { useListAllDispatchState } from '~/components/ListAllDispatch';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useDispatchRelatedState from '~/features/Dispatch/stores/DispatchRelatedState';
import useDispatchVehicleState from '~/features/Dispatch/stores/DispatchVehicleState';
import useUserState from '~/features/User/stores/UserState';
import Cookies from 'universal-cookie';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useEntryState from '~/features/Entry/stores/EntryState';
import useSystemState from '~/features/System/stores/SystemState';
import entriesSocket from '~/features/Entry/services/socket';
import useOccurrenceDispatchesRelated from '~/features/Occurrence/stores/OccurrenceDIspatchesRelated';
import useDispatchTableState from '~/features/Dispatch/components/TabListDispatch/stores/DispatchTableState';
import useListVehiclesSocket from '~/components/ListVehiclesSocket/stores/ListVehiclesSocketState';
import { mountUrlRequest } from '~/services/fetch'
import useNotificationState from '~/components/NotificationsCentral/stores';
import useDeviceState from '~/features/Device/stores/DeviceState';

type SocketContextProviderProps = {
  readonly children: ReactNode;
}

type SocketContextProps = {
  readonly socket: SocketProps;
  sendMessage(room: string, message: string): void;
}

export type StatusType = { readonly id: number, readonly name: string, readonly acronym: string }

export const SocketContext = createContext({} as SocketContextProps);

export function SocketContextProvider(props: SocketContextProviderProps) {
  const [token, setToken] = useCookie(constants.keys.token);
  const [socket, setSocket] = useState<SocketProps>(getInstanceSocket(token));

  const { syncMessageReceived } = useMessageState();
  const { translate } = useLang();
  const { addComment: addDispatchComment, editedComment } = useDispatchCommentState();
  const { addComment: addOccurrenceComment } = useOccurrenceCommentState();
  const { updateMarker, createMarker, getMarker } = useMarkerState();
  const { updateListAllVehicles } = useListAllVehicleState();
  const { updateListAllNoPaginationVehicles } = useListAllVehicleNoPaginationState();
  const { rowsOpt: { updateRowsByDispatchId } } = useListAllDispatchState({ paginated: false });
  const rowsOptNP = useListAllDispatchState({ paginated: false })
  const { dispatch, syncDispatchFromId, socketUpdateLocation, openModalResultMultipleCloseDispatches, socketUpdateCallsDispatch } = useDispatchState();
  const { occurrence, syncOccurrenceEditAttendent, socketUpdateCallsOccurrence, socketUpdateStatusDispatchRelatedOccurrence, socketUpdateListDispatchRelatedOccurrence, updateListDispatch, dispatchesOccurrence } = useOccurrenceState();
  const { occurrenceDispatchesRelated, updateStatusOccurrenceDispatchesRelated, updateListOccurrenceDispatches, syncOccurrenceDispatchesRelated } = useOccurrenceDispatchesRelated();
  const { updateStatusRelated, dispatchRelated, updateDispatchesRelated } = useDispatchRelatedState();
  const { updateStatusVehicleListInDispatch, vehicleListInDispatch, syncVehicleListInDispatch } = useDispatchVehicleState();
  const { connection, verifyPermission, user: userSession } = useUserState();
  const { getEntryFromId, handleUpdateMyEntries, socketUpdateEntry, entry } = useEntryState();
  const { getListVehiclesDisconnects } = useListVehiclesSocket()
  const { windowManager } = useSystemState();
  const { updateDispatchRowsTable, addDispatchLineTable, updateOccurrenceRowsTable, updateCloseMultipleDispatchRowsTable } = useDispatchTableState();
  const { actualNotification } = useNotificationState();
  const { agencyIdFilterMap, operationIdFilterMap } = useDeviceState()



  useEffect(() => {
    console.info('============ CHANGED TOKEN ==================');
    console.info(token);
    socket.connect();
    socket.on('connect_error', (err) => {
      console.error(err);
      connection().set('offline');
    });
    socket.on('connect', () => {
      console.info('Connected main socket');

      connection().set('online');
    });
    socket.on('disconnect', (reason) => {
      if (reason == 'io server disconnect' && (new Cookies()).get(constants.keys.token)) {
        const keyUser = constants.keys.user;
        console.info(token);
        const reconnectInterval = setInterval(() => {
          const storedValue = localStorage.getItem(keyUser);
          const auxToken = (new Cookies()).get(constants.keys.token)
          if (storedValue) {
            socket.auth = { token: auxToken };

            socket.connect();
            clearInterval(reconnectInterval);
          }
        }, 6000);

        console.info('Não autenticado', token);
      }
      connection().set('offline');
    });


    socket.on('messages', (message) => messageSocket(message, syncMessageReceived, actualNotification));

    socket.on('occurrences', (message) => dispatchSocket({ verifyPermission: verifyPermission, message: message, translate: translate, dispatch: dispatch, updateStatusRelated: updateStatusRelated, updateRowsByDispatchId: updateRowsByDispatchId, updateListAllVehicles: updateListAllVehicles, updateListAllNoPaginationVehicles: updateListAllNoPaginationVehicles, addDispatchComment: addDispatchComment, rowsOptNP: rowsOptNP, syncDispatchFromId: syncDispatchFromId, socketUpdateLocation: socketUpdateLocation, createMarker: createMarker, updateMarker: updateMarker, addOccurrenceComment: addOccurrenceComment, occurrenceObject: occurrence, getMarker: getMarker, syncOccurrenceEditAttendent: syncOccurrenceEditAttendent, windowManager: windowManager, user: userSession().id.get(), updateDispatchRowsTable: updateDispatchRowsTable, socketUpdateCallsDispatch: socketUpdateCallsDispatch, socketUpdateCallsOccurrence: socketUpdateCallsOccurrence, socketUpdateStatusDispatchRelatedOccurrence: socketUpdateStatusDispatchRelatedOccurrence, socketUpdateListDispatchRelatedOccurrence: socketUpdateListDispatchRelatedOccurrence, updateListDispatch: updateListDispatch, updateStatusOccurrenceDispatchesRelated: updateStatusOccurrenceDispatchesRelated, dispatchesOccurrence: dispatchesOccurrence, updateListOccurrenceDispatches: updateListOccurrenceDispatches, addDispatchLineTable: addDispatchLineTable, updateOccurrenceRowsTable: updateOccurrenceRowsTable, mountUrlRequest, editedComment, actualNotification, dispatchRelated: dispatchRelated, updateDispatchesRelated: updateDispatchesRelated }));

    socket.on('devices', (message) => deviceSocket(message, dispatch, updateStatusVehicleListInDispatch, updateListAllVehicles, updateListAllNoPaginationVehicles, updateMarker, syncVehicleListInDispatch, createMarker, getMarker, getListVehiclesDisconnects, translate, userSession().id.get(), actualNotification, agencyIdFilterMap.get(), operationIdFilterMap.get()));

    socket.on('dispatches', (message) => dispatchSocket({ verifyPermission: verifyPermission, message: message, translate: translate, dispatch: dispatch, updateStatusRelated: updateStatusRelated, updateRowsByDispatchId: updateRowsByDispatchId, updateListAllVehicles: updateListAllVehicles, updateListAllNoPaginationVehicles: updateListAllNoPaginationVehicles, addDispatchComment: addDispatchComment, rowsOptNP: rowsOptNP, syncDispatchFromId: syncDispatchFromId, socketUpdateLocation: socketUpdateLocation, createMarker: createMarker, updateMarker: updateMarker, addOccurrenceComment: addOccurrenceComment, occurrenceObject: occurrence, getMarker: getMarker, syncOccurrenceEditAttendent: syncOccurrenceEditAttendent, windowManager: windowManager, user: userSession().id.get(), updateDispatchRowsTable: updateDispatchRowsTable, socketUpdateCallsDispatch: socketUpdateCallsDispatch, socketUpdateCallsOccurrence: socketUpdateCallsOccurrence, socketUpdateStatusDispatchRelatedOccurrence: socketUpdateStatusDispatchRelatedOccurrence, socketUpdateListDispatchRelatedOccurrence: socketUpdateListDispatchRelatedOccurrence, updateListDispatch: updateListDispatch, updateStatusOccurrenceDispatchesRelated: updateStatusOccurrenceDispatchesRelated, dispatchesOccurrence: dispatchesOccurrence, updateListOccurrenceDispatches: updateListOccurrenceDispatches, addDispatchLineTable: addDispatchLineTable, updateOccurrenceRowsTable: updateOccurrenceRowsTable, updateCloseMultipleDispatchRowsTable, actualNotification }));

    socket.on('entries', (message) => entriesSocket({ message: message, translate: translate, handleUpdateMyEntries: handleUpdateMyEntries, socketUpdateEntry: socketUpdateEntry, entry: entry, actualNotification }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const sendMessage = React.useCallback((room: string, message: string) => {
    if (!defaultRooms.includes(room)) throw Error('Room not found');
    return socket.emit(room, message);
  }, [socket])


  const memoedValue = useMemo(() => ({
    socket,
    sendMessage
  }), [socket, sendMessage]);

  return (
    <SocketContext.Provider value={memoedValue}>
      {props.children}
    </SocketContext.Provider>
  );
}