import React from 'react'
import { Downgraded, State } from '@hookstate/core'
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  TextField,
  IconButton,
  List,
  ListItem,
  Chip,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useLang } from '~/hooks/useLang'
import { IDivisors } from '../../interfaces/IDivisors'

interface ICreateTag {
  readonly divisorsListOpen: State<boolean>
  readonly divisorName: State<string>
  readonly divisors: State<readonly IDivisors[]>
  readonly newDivisors: State<readonly IDivisors[]>
  readonly removedTag: State<IDivisors>
  readonly tagIndex: State<number | null>
  readonly openDialog: State<boolean>
  readonly handleSaveChange: () => void
  readonly data: State<any[]>
}

export default function CreateTag({
  divisorsListOpen,
  divisorName,
  divisors,
  removedTag,
  tagIndex,
  openDialog,
  newDivisors,
  handleSaveChange,
  data
}: ICreateTag) {
  const { translate } = useLang()

  const handleCancel = () => {
    newDivisors.set([])
    divisorsListOpen.set(false)
  }

  const handleSave = async () => {
    // data.set()
    data.set([...newDivisors.attach(Downgraded).get(), ...data.attach(Downgraded).get()])
    handleSaveChange()
    divisorsListOpen.set(false)
  }

  return (
    <Dialog
      fullWidth={true}
      open={divisorsListOpen.get()}
      onClose={() => divisorsListOpen.set(false)}
    >
      <DialogTitle>
        <Grid sx={{ alignItems: 'center', textAlign: 'center' }}>
          {translate('Tags')}
          <Typography>
            {translate(
              'Create tags to categorize stations according to your work environment'
            )}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            mt: 2,
          }}
        >
          <Grid item xs={10}>
            <TextField
              id="new-divisors"
              size="small"
              label={translate('New Tag')}
              variant="outlined"
              value={divisorName.get()}
              onChange={(event) => {
                divisorName.set(event.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              title={translate('Add')}
              disabled={false}
              onClick={() => {
                newDivisors.merge([
                  {
                    name: divisorName.get(),
                    position_x: 0,
                    position_y: 0,
                    width: 2,
                    height: 0.3,
                  },
                ])
                divisorName.set('')
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List
          sx={{
            width: '100%',
            height: 'auto',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {divisors.get().map((divisor, index) => {
            return (
              <ListItem key={index} role="listitem">
                <Chip
                  label={`${divisor.name}`}
                  onDelete={() => {
                    removedTag.set(divisors[index].attach(Downgraded).get())
                    tagIndex.set(index)
                    openDialog.set(true)
                  }}
                  variant="outlined"
                  sx={{
                    justifyContent: 'space-between',
                    fontSize: 16,
                    marginLeft: -2,
                  }}
                />
              </ListItem>
            )
          })}
          {newDivisors.get().map((divisor, index) => {
            return (
              <ListItem key={index} role="listitem">
                <Chip
                  label={`${divisor.name}`}
                  onDelete={() => {
                    removedTag.set(newDivisors[index].attach(Downgraded).get())
                    tagIndex.set(index)
                    openDialog.set(true)
                  }}
                  variant="outlined"
                  sx={{
                    justifyContent: 'space-between',
                    fontSize: 16,
                    marginLeft: -2,
                  }}
                />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          {translate('Cancel')}
        </Button>
        <Button variant="contained" onClick={handleSave}>
          {translate('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
