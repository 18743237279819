import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHookstate, useState } from '@hookstate/core'
import { AddTaskOutlined } from '@mui/icons-material'
import StandardModal from '~/components/StandardModal'
import { useLang } from '~/hooks/useLang'
import useDispatchState from '../../features/Dispatch/stores/DispatchState'
import RegistrationTab from '~/features/Dispatch/components/DispatchRegistrationTab'
import './styles.scss'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import { authFetch } from '~/services/fetch'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'

const modal = document.getElementById('div-modal') as HTMLElement

export default function RegistrationModalDraggable() {
  const { translate } = useLang()
  const { dispatchStatusActions, dispatch } = useDispatchState()
  const { windowManager, loadingButton } = useSystemState()
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('RegistrationModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.RegistrationModalDraggable)
      }
    })

    return () => {
      windowManager()['registration'].open.set(false)
    }
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'RegistrationModalDraggable',
        isOpen: windowManager()['registration'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['registration'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  if (windowManager()['registration'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={
              <Typography variant='h5'>{translate('Registration of Qualifiers')}</Typography>
            }
            dimensions={{
              width: 1000,
              
            }}
            handleStop={handleStop}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 300,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 200,
            }}
            closeButton={() => windowManager()['registration'].open.set(false)}
            isExpanded={getPositionsDraggable.is_window_minimized}
            sxContent={{paddingX: 0}}
          >
            {getPositionsDraggable.is_window_minimized && (
              <RegistrationTab />
            )}
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}
