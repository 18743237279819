import { useHookstate } from '@hookstate/core';
import { Button, Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation';
import StandardModal from '~/components/StandardModal';
import type ScreenSettingsDraggableProps from '~/features/System/interfaces/ScreenSettings';
import { storeScreenSettings } from '~/features/System/services';
import useSystemState from '~/features/System/stores/SystemState';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { getMarkersDeviceAllService } from '../../services';
import useDeviceState from '../../stores/DeviceState';
import { useMarkerState } from '~/features/Map/stores/MapState';

const modal = document.getElementById('div-modal') as HTMLElement;


export default function MapDeviceFiltersModalDraggable() {
  const { translate } = useLang();
  const markersState = useMarkerState().markersState();
  const { windowManager } = useSystemState();
  const { forceClosePopup } = useMarkerState();
  const { reloadMarkersDevice, agencyIdFilterMap, operationIdFilterMap, filterActived } = useDeviceState()
  const loadingComponent = useHookstate(true)
  const abort = new AbortController();
  const signal = abort.signal;

  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('MapDeviceFiltersModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MapFiltersModalDraggable)
      }
      loadingComponent.set(false)
    })

    return () => {
      windowManager()['mapFiltersDevice'].open.set(false)
    }
  }, [])

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapDeviceFiltersModalDraggable',
        isOpen: windowManager()['mapFiltersDevice'].open.get(),
        isMinimized: windowManager()['mapFiltersDevice'].minimized.get(),
        positionX: windowManager()['mapFiltersDevice'].positionX.get(),
        positionY: windowManager()['mapFiltersDevice'].positionY.get(),
      })

      abort.abort();
    }
  }, [
    windowManager()['mapFiltersDevice'].open.get(),
    windowManager()['mapFiltersDevice'].minimized.get(),
    windowManager()['mapFiltersDevice'].positionY.get(),
    windowManager()['mapFiltersDevice'].positionX.get(),
  ])

  useEffect(() => {
    if (!windowManager()['mapSetting']['markersView']['device'].show.get()) {
      windowManager()['mapFiltersDevice'].open.set(false)
    }

  },[windowManager()['mapSetting']['markersView']['device'].show.get()])

  const handleStop = (event, dragElement) => {
    event.stopPropagation()

    windowManager()['mapFiltersDevice'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }


  const filterDevices = () => {
    getMarkersDeviceAllService({ abort: signal, agencyId: agencyIdFilterMap.get(), operationId: operationIdFilterMap.get() }).then((response) => {
      if (response) {
        markersState['device'].set(response);
        reloadMarkersDevice.set(!reloadMarkersDevice.get())

        if (agencyIdFilterMap.get() || operationIdFilterMap.get()) {
          filterActived.set(true)
        } else {
          filterActived.set(true)
        }
      }
    }).finally(() => {
      forceClosePopup.set(!forceClosePopup.get())
    })
  }

  const clearFilters = () => {
    agencyIdFilterMap.set(null)
    operationIdFilterMap.set(null)
    filterDevices()
  }

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapFiltersDevice'].open.value && !loadingComponent.get() &&
        <StandardModal
          title={translate('Device filter on the map')}
          subheader={translate('Select a agency or operation')}
          handleStop={handleStop}
          position={{
            x: windowManager()['mapFiltersDevice'].positionX?.get() ? Number(windowManager()['mapFiltersDevice'].positionX?.get()) : 80,
            y: windowManager()['mapFiltersDevice'].positionY?.get() ? Number(windowManager()['mapFiltersDevice'].positionY?.get()) : 141,
          }}
          closeButton={!(windowManager()['mapFiltersDevice'].disabled.value) ? () => windowManager()['mapFiltersDevice'].open.set(false) : false}
          isExpanded={windowManager()['mapFiltersDevice'].minimized}
          dimensions={{
            width: 500
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'}  flexDirection={'column'} paddingTop={1} >
            <Box width={'100%'} display={'flex'}  justifyContent={'flex-end'} flexDirection={'row'} gap={1}>
              <FilterAutocompleteAgency
                disabled={false}
                agencyId={agencyIdFilterMap.get()}
                onAgencyChange={(agency) => {
                  agencyIdFilterMap.set(agency)
                  if (!agency) {
                    filterDevices()
                  }
                }}
                isMapManagement
              />
              <FilterAutocompleteOperation
                disabled={false}
                operationId={operationIdFilterMap?.get()}
                onOperationChange={(operation) => {
                  operationIdFilterMap?.set(operation)
                  if (!operation) {
                    filterDevices()
                  }
                }}
                operationActive
                isMapManagement
              />
            </Box>

            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} flexDirection={'row'} gap={1} paddingTop={2}>
              <Button
                variant='outlined'
                size='small'
                onClick={() => clearFilters()}>
                {translate('Clear Filters')}
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => filterDevices()}
              >
                {translate('Search')}
              </Button>
            </Box>

          </Box>


        </StandardModal>

      }
    </>
    , modal)
}