import React, { useEffect } from "react";
import InternalHeader from "~/components/Layout/components/InternalHeader";
import { useLang } from "~/hooks/useLang";
import ListAllAuditInterestPoint from "./components/ListAllAuditInterestPoint";

export default function AuditInterestPointTable() {
    const { translate } = useLang();
    
    

    return (
        <>
            <InternalHeader title={translate('Interest Point Audit')} />
            <ListAllAuditInterestPoint />
        </>
    )
}