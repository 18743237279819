/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHookstate, useState } from '@hookstate/core'
import { EmergencyRecordingOutlined } from '@mui/icons-material'
import StandardModal from '~/components/StandardModal'
import { useLang } from '~/hooks/useLang'
import SurveillanceReportingCards from '../VideoMonitoringCards'
import useSurveillanceReportingState from '../../stores/SurveillanceReportingState'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import { authFetch } from '~/services/fetch'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'

const modal = document.getElementById('div-modal') as HTMLElement

export default function VideoMonitoringCardsDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      
      if ('VideoMonitoringCardsDraggable' in response.data) {
        getPositionsDraggable.set(response.data.VideoMonitoringCardsDraggable)
      }
      loadingComponent.set(false)

    })

    return () => {
      windowManager()['videoMonitoringCards'].open.set(false)
    }
  }, [])


  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };



  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'VideoMonitoringCardsDraggable',
        isOpen: windowManager()['videoMonitoringCards'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['videoMonitoringCards'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['videoMonitoringCards'].open.value && !loadingButton.get() && !loadingComponent.get() && (
        <StandardModal
          title={translate('Videomonitoring narrations')}
          dimensions={{
            width: 800,
          }}
          handleStop={handleStop}
          isExpanded={getPositionsDraggable.is_window_minimized}
          position={{
            x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 300,
            y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 200,
          }}
          closeButton={() =>
            windowManager()['videoMonitoringCards'].open.set(false)
          }
          variant={'h5'}
        >
          <Grid item xs={12} sx={{ overflowY: 'auto', width: '100%' }}>
            <SurveillanceReportingCards onlyCurrentOccurrence />
          </Grid>
        </StandardModal>
      )}
    </>,
    modal
  )
}
