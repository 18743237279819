import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Method } from 'axios';
import { element } from 'prop-types';
import { StateMethods, useHookstate } from '@hookstate/core';

type CrudAutoCompleteFieldProps = {
  readonly register: any;
  readonly name: string;
  readonly model: any;
  readonly control: any;
  readonly placeholder?: string;
  readonly optionSelect?: any;
  readonly method?: Method;
  readonly getValues?: UseFormGetValues<any>;
  readonly setFormValue: UseFormSetValue<any>;
  readonly required?: boolean
  readonly independent?: boolean
  readonly dependent?: boolean
  readonly filter?: StateMethods<number | null>
  readonly filterName?: string

}

export default function CrudAutoCompleteField(props: CrudAutoCompleteFieldProps) {
  const { translate } = useLang();
  const { register, name, model, control, placeholder = '', optionSelect, method = 'POST', getValues, setFormValue, required = false, independent = false, filter, filterName = 'id', dependent = false } = props;
  const [items, setItems] = useState([]);
  const [copyItems, setCopyItems] = useState([]);
  const currentValue = useHookstate<any>(undefined)
  const idCoordinator = useHookstate<number>(getValues ? getValues('coordinator_user_id') : 0)


  useEffect(() => {
    const fetchData = async () => {
      const targetUrl = `/${model}/`;
      await authFetch({
        url: targetUrl,
        method,
        data: {
          limit: 100000,
          filters: [],
          orders: [{ field: 'name', sort: 'asc' }]
        }
      }).then((response) => {
        const data = method === 'POST' ? response.data.data : response.data

        if (items.length <= 0 && data.length > 0) {
          setItems(data);
          setCopyItems(data);
        }

      });
    }

    fetchData()
      .catch(console.error);
  }, [])

  useEffect(() => {
    if (independent) {
      filter?.get() ? setItems(items.filter(item => item[filterName] == filter?.get())) : setItems(copyItems)
      const filterIndependent = items.find(item => item[filterName] == filter?.get())

      if(filterIndependent){
        currentValue.set(filterIndependent[filterName])
        setFormValue(name, filterIndependent[filterName])
      }
    }

    if (dependent) {
      filter?.get() ? setItems(items.filter(item => item[filterName] == filter?.get())) : setItems(copyItems)
    }

  }, [filter?.get()])

  useEffect(() => {

    independent && filter?.set(currentValue?.get())

    if (dependent) {
      const filterDependent = items.find(item => item['id'] == currentValue?.get())
      filterDependent ? filter?.set(filterDependent[filterName]) : filter?.set(null)
    }

  }, [currentValue?.get()])

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  const listItems = items.map((item: any) => ({
    key: item['id'],
    value: item['id'],
    label: optionSelect ? item[optionSelect] : item['name'],
  }))
    .filter((value1, idx1, arr1) => arr1.findIndex((value2) => (value2.key === value1.key)) === idx1)

  useEffect(() => {
    const indexCoordinator = listItems.findIndex((element) => element.key === idCoordinator.get())
    changePosition(listItems, indexCoordinator, 0)

  }, [listItems])

  const [value, setValue] = useState(listItems[getValues ? getValues('coordinator_user_id') : '']);

  return (
    <Grid item xs={4}>
      <Controller
        {...register(`${name}`)}
        name={name}
        control={control}
        defaultValue={value}
        render={(props) => (
          <Autocomplete
            {...props}
            options={listItems.length ? listItems : [] }
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) => option?.key === value?.key}
            value={props.field.value && listItems ? listItems.find(element => element?.key === props.field.value) : null}
            onChange={(_, data) => { currentValue.set(data?.value), setFormValue(name, data?.value ? data?.value : null) }}
            noOptionsText={translate('No options')}
            renderInput={(params) =>
              <TextField
                {...params}
                label={translate(placeholder)}
                required={required}
              />
            }
          />
        )}
      />
    </Grid>
  )
}

