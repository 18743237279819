import React from "react";
import { Card, Typography, CardContent, IconButton, Stack } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import MarkerPopupContentDispatch from "./components/MarkerPopupContentDispatch";
import MarkerPopupContentOccurrence from '~/features/Map/components/MarkerPopupContent/components/MarkerPopupContentOccurrence';
import MarkerPopupContentCamera from '~/features/Map/components/MarkerPopupContent/components/MarkerPopupContentCamera';
import MarkerPopupContentEntry from '~/features/Map/components/MarkerPopupContent/components/MarkerPopupContentEntry';
import MarkerPopupContentDevice from "./components/MarkerPopupContentDevice";
import MarkerPopupContentInterestPoint from "./components/MarkerPopupContentInterestPoint";
import { Close } from "@mui/icons-material";
import useSystemState from "~/features/System/stores/SystemState";

export function MarkerPopupContent({
  id,
  type,
  status,
  location,
  typeName,
  subtypeName,
  code,
  identifier,
  plate,
  date,
  speed,
  closeThisPopUp,
  groupId,
  readonly,
  dispatchedAt,
  isCriminal,
  expirationDate,
  revisionDate,
  validatedAt,
  nameInterestPoint,
  notes,
  agencyName,
  cameras = [],
  medias = []
}) {

  const { translate } = useLang();
  const composeTypeSubtype = (type, subtype) => {
    if (!type) return '';
    if (subtype && subtype.length > 0) return `${type} / ${subtype}`
    return type;
  }

  const typeSubtype = composeTypeSubtype(typeName, subtypeName);

  const contentPopup = {
    'entry': <MarkerPopupContentEntry id={id} location={location} readonly={readonly} typeSubtype={typeSubtype} closeThisPopUp={closeThisPopUp} />,
    'dispatch': <MarkerPopupContentDispatch id={id} status={status} code={code} typeSubtype={typeSubtype} location={location} dispatchedAt={dispatchedAt} closeThisPopUp={closeThisPopUp} />,
    'occurrence': <MarkerPopupContentOccurrence id={id} typeSubtype={typeSubtype} location={location} closeThisPopUp={closeThisPopUp}/>,
    'device': <MarkerPopupContentDevice id={id} status={status} code={code} identifier={identifier} plate={plate} date={date} speed={speed} closeThisPopUp={closeThisPopUp} groupId={groupId} readonly={readonly} agencyName={agencyName}/>,
    'camera': <MarkerPopupContentCamera readonly={readonly} cameras={cameras} closeThisPopUp={closeThisPopUp} />,
    'interestPoint': <MarkerPopupContentInterestPoint typeName={typeName} subtypeName={subtypeName} isCriminal={isCriminal} expirationDate={expirationDate} revisionDate={revisionDate} validatedAt={validatedAt} location={location} nameInterestPoint={nameInterestPoint} medias={medias} closeThisPopUp={closeThisPopUp} notes={notes} />,
    'dispatchFiltered': <MarkerPopupContentDispatch id={id} status={status} code={code} typeSubtype={typeSubtype} location={location} dispatchedAt={dispatchedAt} closeThisPopUp={closeThisPopUp} />,
  }

  return contentPopup[type] ? contentPopup[type] :
    <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
      <CardContent sx={{ padding: '1em 0 1em 0', textAlign: "center" }} >
        <Stack direction='row' alignItems='center' gap={1} justifyContent={"space-between"}>
        <Typography variant="subtitle1" sx={{ margin: '.6em 0' }}>{translate('No information')}</Typography>
          <IconButton size='small' onClick={() => closeThisPopUp()}>
            <Close fontSize='small'/>
          </IconButton>
        </Stack>
      </CardContent>
    </Card>

}