import React, { useState } from "react"
import InternalContent from "~/components/Layout/components/InternalContent";
import { useLang } from "~/hooks/useLang";
import { useHookstate } from "@hookstate/core";
import { getAssociatedSearchLogs } from "../services";
import { DataGrid, gridClasses, type GridColDef, type GridRowsProp } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import dayjs from 'dayjs';
import notify from "~/utils/notify";
import AuditFiltersQualifiers from "./AuditFiltersQualifiers";
import { Card, CardHeader, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

// eslint-disable-next-line max-lines-per-function
export default function ListAllAuditQualifiers() {
  const { translate } = useLang();
  const labelButton = useHookstate(translate('Search'));
  const selectType = useHookstate<string | null>(null);
  const loadingButton = useHookstate<boolean>(false);
  const expandFilterSearch = useHookstate<boolean>(true);
  const userId = useHookstate<number | null>(null)
  const resetValeus = useHookstate(false)
  const focusChange = useHookstate<boolean>(false)
  const initialPeriod = useHookstate<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [finishDate, setFinishDate] = useState<string | null>(null);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const tableMode = "server";
  const limit = 15;
  const expandQualifiers = useHookstate<boolean>(false)


  const formatedValueInfoSearch = (value) => {
    const valueFormated = JSON.parse(value)
    let result = "";
    const keys = Object.keys(valueFormated);
    keys.forEach((key, index) => {
      if (valueFormated.hasOwnProperty(key)) {
        result += translate(key) + ": " + (valueFormated[key].name ? valueFormated[key].name : isDate({ stringDate: valueFormated[key] }) ? dayjs(valueFormated[key]).format('DD/MM/YYYY') : valueFormated[key]);

        if (index < keys.length - 1) {
          result += ", \n";
        }
      }
    });
    return result;
  }

  const isDate = ({ stringDate }) => {
    return dayjs(stringDate, 'YYYY-MM-DD', true).isValid();
  }

  const columns: GridColDef[] = ([
    { field: 'id' },
    {
      field: 'search_type', headerName: translate('Search Type'), flex: 1,
      valueFormatter: (value) => {
        return translate(value)
      }
    },
    {
      field: 'search_info', headerName: translate('Search Info'), flex: 1,
      valueFormatter: (value) => {

        return formatedValueInfoSearch(value)
      },
    },
    { field: 'user:representation_name', headerName: translate('User'), flex: 1 },
    { field: 'station:name', headerName: translate('Station'), flex: 1 },
    {
      field: 'created_at', headerName: 'Data de criação', type: 'date', flex: 1,
      valueFormatter: (value) => {
        return dayjs(value as string).format('DD/MM/YYYY HH:mm');
      },

    },
  ])


  const clearFilters = () => {
    setStartDate(null)
    setFinishDate(null)
    initialPeriod.set('')
    userId.set(null)
    selectType.set(null)
    setRows([])
    expandQualifiers.set(false)
  }


  const submit = async () => {
    if (!startDate || !finishDate) {
      notify({
        type: 'error',
        message: translate('Start date and end date must be provided')
      })

      return false
    }
    loadingButton.set(true)

    const searchLogData: any = await getAssociatedSearchLogs({
      searchType: selectType.get(),
      startDate: startDate,
      finishDate: finishDate,
      userId: userId.get(),
    }).finally(() => {
      loadingButton.set(false)
      expandQualifiers.set(true)
    })

    setRows(searchLogData.data.map((element) => {
      element['station:name'] = element?.station?.name
      element['user:representation_name'] = element?.user?.representation_name
      element['search_info'] = element?.search_info;

      return element;
    }))

    setRowCount(searchLogData.rowsCount);
  }


  return (
    <>
      <InternalContent title={`${translate('Filters')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <AuditFiltersQualifiers
          hidden={expandFilterSearch.get()}
          loadingButton={loadingButton}
          labelButton={labelButton}
          selectType={selectType}
          initialPeriod={initialPeriod}
          focusChange={focusChange}
          resetValues={resetValeus}
          userId={userId}
          onPeriodChange={(value) => {
            labelButton.set(translate('Search'))
            initialPeriod.set(value)
          }}
          onStartDateChange={(value) => {
            labelButton.set(translate('Search'))
            setStartDate(value)
          }}
          onFinishDateChange={(value) => {
            labelButton.set(translate('Search'))
            setFinishDate(value)
          }}
          clearFilters={() => clearFilters()}
          searchButton={() =>
            submit()
          }
        />


      </InternalContent>
      <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
        <CardHeader
          title={translate('Qualifiers')}
          avatar={
            <IconButton
              onClick={() => expandQualifiers.set(!expandQualifiers.get())}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                marginRight: 1,
                padding: 0,
              }}
            >
              {expandQualifiers.value ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          }
          titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
        />
        <Collapse in={expandQualifiers.value}>

          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
              deleted_at: false,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: limit } },
            }}
            disableColumnMenu
            autoHeight
            pagination
            filterMode={tableMode}
            sortingMode={tableMode}
            rowCount={rowCount}
            getRowHeight={(params) => {
              if (params.model.search_info.length > 36) {
                return 'auto';
              }
              return null;
            }}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
              minHeight: '250px',
              m: 4,
              [`.${gridClasses.columnHeaderTitle}`]: {
                fontWeight: 'bold !important',
              },
            }}
          />
        </Collapse>
      </Card >
    </>
  )
}