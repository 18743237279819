/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '../../../../components/StandardModal';
import { useLang } from '../../../../hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import { useHookstate, useState } from '@hookstate/core';
import DispatchUnification from '../DispatchUnification';
import { authFetch } from '~/services/fetch';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function DispatchUnificationModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState();
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('DispatchUnificationModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.DispatchUnificationModalDraggable)
      }
    })

    return () => {
      windowManager()['DispatchUnification'].open.set(false)
    }
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['DispatchUnification'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

 DispatchUnification

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchUnificationModalDraggable',
        isOpen: windowManager()['DispatchUnification'].open.get(),
        isMinimized: windowManager()['DispatchUnification'].minimized.get(),
        positionX: windowManager()['DispatchUnification'].positionX.get(),
        positionY: windowManager()['DispatchUnification'].positionY.get()
      })
    }
  }, [
    windowManager()['DispatchUnification'].open.get(),
    windowManager()['DispatchUnification'].minimized.get(),
    windowManager()['DispatchUnification'].positionY.get(),
    windowManager()['DispatchUnification'].positionX.get()
  ])
  
  if (windowManager()['DispatchUnification'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={`${translate("Lasts Dispatchs")}`}
            subheader={`${translate('Latest open or ongoing dispatches from the same Dispatch Group')}`}
            dimensions={{
              width: 900
            }}
            isExpanded={windowManager()['DispatchUnification'].minimized}
            handleStop={handleStop}
            position={{
              x: windowManager()['DispatchUnification'].positionX.get() ? Number(windowManager()['DispatchUnification'].positionX.get()) : 250,
              y: windowManager()['DispatchUnification'].positionY.get() ? Number(windowManager()['DispatchUnification'].positionY.get()) : 100
            }}
            closeButton={() => windowManager()['DispatchUnification'].open.set(false)}
            variant={'h5'}
          >
            {windowManager()['DispatchUnification'].minimized.get() &&
              <DispatchUnification />
            }
          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}
