import React from "react"
import { Downgraded } from "@hookstate/core"
import { LoadingButton } from "@mui/lab"
import { Button, Grid } from "@mui/material"
import FilterAutocompleteLocationAccuracy from "~/components/FilterAutocompleteLocationAccuracy"
import { useLang } from "~/hooks/useLang"

export default function FilterDispatchHotMap({expandFilterSearch, locationAccuracy, refreshHotMap, loading}) {
  const { translate } = useLang()

    return (
        <Grid item xs={12} hidden={!expandFilterSearch} paddingBottom={2}>
        <Grid item xs={2} sx={{ pt: 1 }}>
          <FilterAutocompleteLocationAccuracy
            onAccuracyChange={(accuracy) => locationAccuracy.set(accuracy)}
            locationAccuracy={locationAccuracy.attach(Downgraded).get()}
          />
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              locationAccuracy.set([])
              refreshHotMap.set(!refreshHotMap.get())
            }}
          >
            {translate('Clear Filters')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant="contained"
            onClick={() => refreshHotMap.set(!refreshHotMap.get())}
          >
            {translate('Search')}
          </LoadingButton>
        </Grid>
      </Grid>
    )
}