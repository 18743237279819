import { authFetch } from '~/services/fetch'
import { LatLng } from 'leaflet'
import type { getMarkersDeviceAllServiceProps } from '../interfaces/IDevice'

export async function getMarkersDeviceAllService({ abort, operationId, agencyId }: getMarkersDeviceAllServiceProps) {
  try {
    const { data } = await authFetch({
      url: '/device/current-locations-all',
      method: 'POST',
      signal: abort.signal,
      data: {
        agency_id: agencyId,
        operation_id: operationId
      }
    })

    const result = {}

    data
      .filter((device) => device?.latitude && device?.longitude)
      .map((device, index) => {
        if (
          device?.device_id &&
          device?.latitude &&
          device?.longitude &&
          device?.device_status_id &&
          device?.identifier
        ) {
          // eslint-disable-next-line functional/immutable-data
          result[Number(device?.device_id)] = {
            id: device?.device_id,
            markerId: `device-${device?.device_id}`,
            code: device?.code ?? '',
            position: new LatLng(device.latitude, device.longitude),
            status: device?.device_status_id ?? '10',
            identifier: device?.identifier,
            speed: device?.speed ? device?.speed : '0',
            plate: device?.plate ? device?.plate : '',
            date: device?.date ? device?.date : null,
            groupId: device?.group_id,
            isCurrent: false,
            deviceType: device?.device_type ? device?.device_type : 'car',
          }
        }
      })

    return result
  } catch (error) {
    throw error
  }
}

export async function getCurrentDispatchDeviceService({ deviceId }) {
  try {
    const { data } = await authFetch({
      url: '/device/current-dispatch',
      method: 'POST',
      data: {
        device_id: deviceId,
      },
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getDeviceInfoService({ id }) {
  try {
    const { data } = await authFetch({
      url: `/device/${id}/get-info`,
      method: 'GET',
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getAllDevices() {
  try {
    const { data } = await authFetch({
      url: '/device/',
      method: 'GET',
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getCrewCompositionService({ deviceId }) {
  try {
    const { data } = await authFetch({
      url: '/device/logs/crew-composition',
      method: 'POST',
      data: { device_id: deviceId },
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function trasnferDeviceForDispatchGroup({
  deviceId,
  destDispatchGroupId,
  destinyName,
  dateTobeReturned,
}) {
  const response = authFetch({
    url: '/device/transfer',
    method: 'POST',
    data: {
      device_id: deviceId,
      dest_dispatch_group_id: destDispatchGroupId,
      destiny_name: destinyName ?? null,
      to_be_returned_at: dateTobeReturned
        ? dateTobeReturned.isValid()
          ? dateTobeReturned
          : null
        : null,
      // to_be_returned_at: dateTobeReturned,
    },
  })

  return response
}

export async function cancelTransferDevice({ deviceId }) {
  const response = authFetch({
    url: `/device/cancel-transfer-timer`,
    method: 'POST',
    data: {
      device_id: deviceId,
    },
  })

  return response
}

export async function checkTransferDevice({ deviceId }) {
  const response = authFetch({
    url: `/device/check-tranfer/${deviceId}`,
    method: 'GET',
  })

  return response
}
