/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { getOpenEntryByUser } from '../../services';
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic';
import ColumnChartHorizontal from '~/components/ColumnChartMui/HorizontalChart';

export default function EntryOpenUserChart({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed = false, showTitle = false, refresh, loading, cityId}: IEntryStatisticGraphProps) {
  const data = useHookstate([]);
  const total = useHookstate<number>(0);

  useEffect(() => {
    loading.set(true)
    total.set(0)
    getOpenEntryByUser({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed, cityId}).then((response) => {
      const result = response.map((data) => {
        total.set(total.get() + Number(data.total))
        return ({ item: data.name, value: data.total })
      });
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <ColumnChartHorizontal
    data={data.attach(Downgraded).get()}
    title={`${showTitle ? 'Open Entry Chart' : ''}`}
    subtitle={`Total de Atendimentos: ${Intl.NumberFormat('pt-BR').format(
      Number(total.get())
    )}`}
    typeTotal={'Users'}
    colorBar='secondary'
  />
  )
}