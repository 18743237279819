import { useHookstate } from "@hookstate/core";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLang } from "~/hooks/useLang";
import { getOperation, getOperationById } from "./services";
import { getOperationActive } from "~/features/Operation/services";
import IOperation from "~/features/Operation/interfaces/IOperation";

interface SelectOperationProps {
  readonly onOperationChange: (value: number | null) => void;
  readonly operationId?: number | null;
  readonly disabled?: boolean,
  readonly operationActive?: boolean,
  readonly isMapManagement?: boolean;
}

export default function FilterAutocompleteOperation({ onOperationChange, operationId, disabled, operationActive = false, isMapManagement = false }: SelectOperationProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly IOperation[]>([]);
  const [value, setValue] = useState<IOperation | null>(null);
  const loading = useHookstate(false);

  useEffect(() => {
    if (operationActive) {
      getOperationActive().then((operation) => {
        setOptions(operation)
      })
    } else {
      getOperation().then((operation) => {
        setOptions(operation)
      })
    }
  }, [])

  useEffect(() => {
    if (!operationId) {
      setValue(null)
    } else {
      getOperationById(operationId)
        .then((result) => {
          setValue(result);
        })
    }
  }, [operationId])

  const updateValueOperation = ({ value }) => {
    if (isMapManagement) {
      onOperationChange(value?.id)
    } else {
      onOperationChange(value?.id ?? null)
    }
  }

  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-categories'
        options={options}
        disabled={disabled}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, operation) => {
          setValue(operation)
          updateValueOperation({ value: operation })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Operation')}
          />
        )}
      />
    </FormControl>
  )
}