import React from "react";
import { IconButton, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { DirectionsCar } from '@mui/icons-material';
import useEntryState from '~/features/Entry/stores/EntryState';
import useSystemState from "~/features/System/stores/SystemState";
import { requestEntryAssociatedVehicleAllFromEntry } from "~/features/Entry/services";

export default function AddVehicleEntryButton({ canAddVehicles, isValid }) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();

  const entryState = useEntryState();
  const { id } = entryState.entry();
  const { vehicle } = entryState.entry();

  const openModalDraggable = () => {
    windowManager()['VehicleModal'].open.set(true)
    if (isValid) {

      requestEntryAssociatedVehicleAllFromEntry({ entryId: id.get() }).then((data) => {
        if (data) {
          data.map((item) => {
            vehicle.merge({
              circunstanceId: item.circunstance.id,
              externalKeyTracking: item.external_key_tracking,
              plate: item.plate
            })
          })
        }
      })
    }
  }

  return (
    <Grid item>
      <IconButton
        title={translate('Add Vehicle')}
        disabled={!canAddVehicles}
        onClick={() => openModalDraggable()}
      >
        <DirectionsCar />
      </IconButton>
    </Grid>
  )
}