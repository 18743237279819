import { useHookstate, type State } from '@hookstate/core';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ISubtype from '~/features/Subtype/interfaces/ISubtype';
import { getAllSubTypes } from '~/features/Subtype/services';
import { useLang } from '~/hooks/useLang';
import { SubtypeResponse } from '../FilterAutocompleteSubtypeByType/interface';
import { displayName } from 'react-quill';
import { Category } from '@mui/icons-material';

interface FilterAutocompleteSubtypesWithCategoryProps {
  readonly onSubtypesChange: (value) => void;
  readonly onTypeChange: (value) => void;
  readonly subtypes: readonly number[];
  readonly typeId: number[] | null;
  readonly refTypeIds?: State<number[] | []>
  readonly error?: boolean;
  readonly disabled?: boolean
  readonly limitedTags?: number
  readonly hideCode?: boolean
  readonly categoryIds: number | number[] | null;
  readonly clearTypeAndSubtype?: boolean;
  readonly itemTypeRemoved?: State<boolean>;
  readonly itemSubtypeRemoved?: State<boolean>;
}


// eslint-disable-next-line max-lines-per-function
export default function FilterAutocompleteSubtypesWithCategory({ error, typeId, subtypes, categoryIds, disabled, clearTypeAndSubtype, refTypeIds, limitedTags = 5, hideCode, itemSubtypeRemoved, itemTypeRemoved, onSubtypesChange, onTypeChange }: FilterAutocompleteSubtypesWithCategoryProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly ISubtype[]>([]);
  const [optionsFilter, setOptionsFilter] = useState<readonly ISubtype[]>([]);
  const [value, setValue] = useState<ISubtype[] | undefined>([]);
  const loading = useHookstate(false)
  const filteredSubtypes = useHookstate(false)

  useEffect(() => {
    getAllSubTypes().then((subTypesResponse) => {
      const resSubtypes = subTypesResponse.map((subtype) => ({
        ...subtype,
        displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
      }))
      setOptions(resSubtypes)
      setOptionsFilter(resSubtypes)
      setValue([])
    })
  }, [])

  useEffect(() => {
    if (!subtypes.length && (categoryIds || (Array.isArray(categoryIds) && categoryIds.length))) {
      setValue([])
      onSubtypesChange([])
    }
  }, [subtypes?.length])
  


  useEffect(() => {
    if (typeId) {
      if (Array.isArray(typeId) && typeId.length && !categoryIds || (Array.isArray(categoryIds) && !categoryIds.length)) {
        const filter = options.filter(element => typeId.includes(Number(element.type_id)))
        setOptionsFilter(filter.length ? filter : [])

        if (itemTypeRemoved?.get()) {
          const valueFilter = value?.filter(element => typeId.includes(Number(element.type_id)))

          setValue(valueFilter)
          onSubtypesChange(valueFilter?.map(subtype => subtype.id) ?? [])
          itemTypeRemoved.set(false)
        }

        filteredSubtypes.set(filter.length ? true : false)

      } else if (Array.isArray(typeId) && typeId.length && categoryIds || (Array.isArray(categoryIds) && categoryIds.length)) {
        if (itemTypeRemoved?.get()) {
          const valueFilter = value?.filter(element => typeId.includes(Number(element.type_id)))

          setValue(valueFilter)
          onSubtypesChange(valueFilter?.map(subtype => subtype.id) ?? [])
          itemTypeRemoved.set(false)
        }

      } else if (!typeId.length && categoryIds || (Array.isArray(categoryIds) && categoryIds.length) && itemTypeRemoved?.get()) {
        setValue([])
        itemTypeRemoved?.set(false)

      } else if (!categoryIds && !typeId.length && value?.length) {
        setValue([])
        setOptionsFilter(options)
      } else if (!categoryIds) {
        setOptionsFilter(options)
      }
    }

  }, [typeId])

  useEffect(() => {
    if (categoryIds) {
      if (Array.isArray(categoryIds) && categoryIds.length && options) {
        const filter = options.filter(element => element.categories?.find((category) => categoryIds.includes(category.id)))
        setOptionsFilter(filter.length ? filter : [])
      } else if (categoryIds && Array.isArray(categoryIds) && categoryIds?.length == 0 && value && value?.length > 0 && typeId && typeId.length == 0) {
        setOptionsFilter(options ?? [])
        refTypeIds?.set([])
      } else if (!Array.isArray(categoryIds) && options) {
        const filter = options.filter((typeMap) => typeMap.categories?.find((category) => categoryIds == category.id)) ?? []
        setOptionsFilter(filter.length ? filter : [])
      } else if (categoryIds && (!Array.isArray(categoryIds) || !categoryIds)) {
        const filter = options.filter(element => typeId?.includes(Number(element.type_id)))
        setOptionsFilter(filter.length ? filter : [])
      }

    } else {
      if (typeId?.length) {
        const filter = options.filter(element => typeId?.includes(Number(element.type_id)))
        setOptionsFilter(filter ?? [])
      } else {
        setOptionsFilter(options ?? [])
      }
    }

  }, [categoryIds])

  const verifyDisableButton = () => {
    if (categoryIds) return false;

    if (typeId && typeId.length > 0) {
      if (filteredSubtypes.get() || subtypes.length > 0) {
        return false;
      }
    }

    return true;
  };

  const removeDuplicity = (objSubtype) => {
    const withoutDuplicity = objSubtype.filter((item, index) => objSubtype.indexOf(item) === index)
    return withoutDuplicity
  }
  const handleAddTypeIdWithRefTypes = ({ subtype }) => {
    if (subtype.length) {
      setValue(subtype)
    }

    if (!itemSubtypeRemoved?.get()) {
      const filter = subtype.map((subtypeItem) => Number(subtypeItem.type_id))
      const allTypes = filter.concat(typeId)
      const typeIdsForSending = removeDuplicity(allTypes)

      refTypeIds?.set(typeIdsForSending)
      onTypeChange(typeIdsForSending)
      onSubtypesChange(subtype.map(subtype => subtype.id) ?? []);

    } 
  }
  

  const removeSubType = ({ subtype }) => {
    
    itemSubtypeRemoved?.set(true)

    if (categoryIds !== null && categoryIds !== undefined &&
      (typeof categoryIds === 'number' || (Array.isArray(categoryIds) && categoryIds.length > 0))) {

      onSubtypesChange(subtype.map((itemSubtype) => itemSubtype.id))

      const currentIds = subtype.length ? subtype.map((itemValue) => itemValue.id) : value?.map((itemValue) => itemValue.type_id)
      const currentItemRemoved = subtype.length ? value?.filter((itemValue) => !currentIds.includes(itemValue.id)) : value
      const refIdsUpdated = currentItemRemoved?.filter((itemRefId) => refTypeIds?.get().filter((idRemoved) => itemRefId.id == idRemoved)).map((itemId) => itemId.type_id)
      const newRefs = refTypeIds?.get().filter((itemRef) => !refIdsUpdated?.includes(itemRef))

      if (categoryIds !== null &&
        categoryIds !== undefined &&
        (typeof categoryIds === 'number' || (Array.isArray(categoryIds) && categoryIds.length > 0)))
      {
        refTypeIds?.set(newRefs ?? []) 
         onTypeChange(newRefs)
      }      
    } 
  }

 

  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-subtypes'
        multiple
        limitTags={limitedTags}
        disabled={verifyDisableButton()}
        options={optionsFilter}
        value={value}
        filterSelectedOptions
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        disableCloseOnSelect
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          if ((value && value.length > subtype.length) && (categoryIds || (Array.isArray(categoryIds) && categoryIds.length > 0))) {
            removeSubType({ subtype: subtype })
          } else {
            itemSubtypeRemoved?.set(false)
          }

          if (categoryIds !== null && categoryIds !== undefined &&
            (typeof categoryIds === 'number' || (Array.isArray(categoryIds) && categoryIds.length > 0)) && !itemSubtypeRemoved?.get()) {
            handleAddTypeIdWithRefTypes({ subtype: subtype })
          } else {
            onSubtypesChange(subtype.map(subtype => subtype.id) ?? []);
            setValue(subtype)
          }

          if (!subtype.length && (categoryIds || (Array.isArray(categoryIds) && categoryIds.length > 0))) {
            setValue([])
            onSubtypesChange([])
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Subtypes')}
          />
        )}
      />
    </FormControl>
  )
}