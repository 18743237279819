/* eslint-disable max-lines-per-function */
import React from 'react'
import { authFetch } from '~/services/fetch'
import IDevice from '../interfaces/IDevice';
import { createState, useState } from '@hookstate/core';

interface IDeviceResponse {
  readonly rowsCount: number;
  readonly data: readonly IDevice[]
}

interface IrefreshDeviceMarkers {
  readonly operationId: number | null;
  readonly agencyId: number | null;
  readonly activeActived: boolean;
  readonly reload: boolean;
}

interface IGetDevices {
  readonly page?: number;
  readonly limit?: number;
  readonly filters?: readonly {
    readonly field: string,
    readonly operator: string,
    readonly value: any
  }[]
}

const deviceFilterMarkers = createState <IrefreshDeviceMarkers>({
  operationId: null,
  agencyId: null,
  activeActived: false,
  reload: false
})

const useDeviceState = () => {
  const reloadMarkersDevice = useState(deviceFilterMarkers.reload);
  const operationIdFilterMap = useState(deviceFilterMarkers.operationId);
  const agencyIdFilterMap = useState(deviceFilterMarkers.agencyId);
  const filterActived = useState(deviceFilterMarkers.activeActived);
  return {
    reloadMarkersDevice: reloadMarkersDevice,
    operationIdFilterMap: operationIdFilterMap,
    agencyIdFilterMap: agencyIdFilterMap,
    filterActived: filterActived,
    getDevices: async ({ page, limit }): Promise<IDeviceResponse> => {
      const targetUrl = '/device';
      const response = await authFetch({
        url: targetUrl,
        data: {
          page,
          limit,
        },
      })
      return response.data;
    },
    getDevicesDisconnected: async () => {
      const targetUrl = '/device/disconnected';
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      })
      return response.data;
    }

  }
}

export default useDeviceState;