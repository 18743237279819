import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate, type State } from '@hookstate/core';
import { getAllSubTypes, getAllSubTypesByType } from '~/features/Subtype/services';
import ISubtype from '~/features/Subtype/interfaces/ISubtype';

interface FilterAutoCompleteSubtypeWithCategoryProps {
  readonly onSubtypeChange: (value: ISubtype | null) => void;
  readonly typeId?: number | null;
  readonly subtypeId?: number | null;
  readonly categoryId: number | null;
  readonly refTypeId?: State<number | null>;
  readonly hideCode?: boolean;
  readonly error?: boolean;
  readonly getRowsCount?: (value: number) => number;
}

export default function FilterAutoCompleteSubtypeWithCategory({ onSubtypeChange, hideCode, typeId, subtypeId, error, categoryId, refTypeId, getRowsCount }: FilterAutoCompleteSubtypeWithCategoryProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly ISubtype[]>([]);
  const [value, setValue] = useState<ISubtype | null>(null);
  const [loading, setLoading] = useState(false)
  const isEnabled = useHookstate(true)

  useEffect(() => {
    if (options.length && typeId) {
      isEnabled?.set(false)
    } else if (categoryId) {
      isEnabled?.set(false)
    } else {
      isEnabled?.set(true)
    }
  }, [options.length, typeId])


  useEffect(() => {
    setLoading(true)
    if (!categoryId && typeId) {

      getAllSubTypesByType(typeId).then(subtypes => {
        const resSubtypes = subtypes.filter((subtypesActives) => subtypesActives.active).map(subtype => ({
          ...subtype,
          displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
        }));

        setOptions(resSubtypes);

        if (getRowsCount) {
          getRowsCount(resSubtypes.length ?? 0);
        }

        if (subtypeId) {
          const option = resSubtypes.find((option) => option.id == subtypeId);
          setValue(option ?? null);
          onSubtypeChange(option ?? null);

        } else {
          setValue(null);
          onSubtypeChange(null);

        }
      }).finally(() => {
        setLoading(false)
      })
    }
    else if (!categoryId && !typeId) {
      setValue(null);
      onSubtypeChange(null);
    }
    else {
      if (getRowsCount) {
        getRowsCount(0);
      }
    }
  }, [typeId])

  useEffect(() => {
    setLoading(true)
    if (categoryId) {
      getAllSubTypes().then(subtypes => {
        const resSubtypes = subtypes.filter((subtypesActives) => subtypesActives.active).map(subtype => ({
          ...subtype,
          displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
        }));

        setOptions(resSubtypes);

        if (getRowsCount) {
          getRowsCount(resSubtypes.length ?? 0);
        }

        if (categoryId && !typeId) {
          const options = resSubtypes.filter((subtype) => subtype.categories.find((item) => item.id === categoryId))
          setOptions(options ?? null);
        } else if (categoryId && typeId) {
          const options = resSubtypes.filter((subtype) => subtype.type_id === typeId)
          setOptions(options ?? null);
          if (typeId !== refTypeId?.get()) {
            setValue(null)
          }
          refTypeId?.set(null)

          if (!options.length) {
            setValue(null)
            onSubtypeChange(null)
            refTypeId?.set(null)
          }
        }

        if (!categoryId && typeId) {

          return false
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [categoryId, typeId])

  useEffect(() => {
    if (subtypeId && !categoryId) {
      const option = options.find((option) => option.id == subtypeId);
      setValue(option ?? null);

      if (getRowsCount) {
        getRowsCount(options.length ?? 0);
      }

    }
  }, [subtypeId, typeId])



  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-subtypes'
        disabled={isEnabled?.get()}
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          if (subtype) {
            setValue(subtype);
            onSubtypeChange(subtype);
            refTypeId?.set(Number(subtype?.type_id))
          } else {
            setValue(null);
            onSubtypeChange(null);
            refTypeId?.set(null)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Subtype')}
          />
        )}
      />
    </FormControl>
  )
}