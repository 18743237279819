import React, { ChangeEvent } from "react"
import { TextField } from "@mui/material"
import { useLang } from "~/hooks/useLang"
import { State } from "@hookstate/core"

type InputOccurrenceNumberProps = {
  occurrence?: State<string | null>,
  onOccurrenceChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

export default function InputOccurrenceNumber({ occurrence, onOccurrenceChange }: InputOccurrenceNumberProps) {
  const { translate } = useLang()

  return (
    <TextField
      fullWidth
      type={'number'}
      sx={{
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          display: "none",
        },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      label={`${translate('Occurrence ID')}`}
      size="small"
      value={occurrence?.get() ? occurrence?.get() : ''}
      onChange={onOccurrenceChange}
    />
  )
}