/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import { GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { useLang } from '../../../../hooks/useLang'
import useEntryState from '../../stores/EntryState'
import { Downgraded, useHookstate, useState } from '@hookstate/core'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'
import ProcedureList, { ConfigProcedureListProps } from '~/components/ProcedureList'

const modal = document.getElementById('div-modal') as HTMLElement

type EntryLogParams = {
  onRowClick?: (
    rows: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => boolean | undefined
}

export default function EntryProcedureModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const configProcedureList = useHookstate<readonly ConfigProcedureListProps[]>([])

  useEffect(() => {
    configProcedureList.set(windowManager()['entryProcedure'].procedure?.attach(Downgraded).get() ?? [])

    return () => {
      windowManager()['entryProcedure'].open.set(false)
    }
  }, [])


  const handleStop = (event, dragElement) => {
    event.stopPropagation()
    windowManager()['entryProcedure'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'EntryProcedureModalDraggable',
        isOpen: windowManager()['entryProcedure'].open.get(),
        isMinimized: windowManager()['entryProcedure'].minimized.get(),
        positionX: windowManager()['entryProcedure'].positionX.get(),
        positionY: windowManager()['entryProcedure'].positionY.get(),
      })
    }
  }, [
    windowManager()['entryProcedure'].open.get(),
    windowManager()['entryProcedure'].minimized.get(),
    windowManager()['entryProcedure'].positionY.get(),
    windowManager()['entryProcedure'].positionX.get(),
  ])

  if (windowManager()['entryProcedure'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={
              <Typography variant='subtitle1'>
                {translate('Procedures')}
              </Typography>
            }
            dimensions={{
              width: 600,
              height: 500
            }}
            handleStop={handleStop}
            position={{
              x: windowManager()['entryProcedure'].positionX.get()
                ? Number(windowManager()['entryProcedure'].positionX.get())
                : 80,
              y: windowManager()['entryProcedure'].positionY.get()
                ? Number(windowManager()['entryProcedure'].positionY.get())
                : 141,
            }}
            closeButton={() => windowManager()['entryProcedure'].open.set(false)}
            isExpanded={windowManager()['entryProcedure'].minimized}
          >
            <ProcedureList configProcedureList={configProcedureList} />
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}
