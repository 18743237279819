import { authFetch } from "~/services/fetch";

interface getAssociatedSearchLogsProps {
  readonly searchType?: string | null;
  readonly userId?: number | null;
  readonly startDate?: string | null;
  readonly finishDate?: string | null;
  readonly limit?: number;
  readonly page?: number;
}

export async function getAssociatedSearchLogs({
  searchType,
  startDate,
  userId,
  finishDate,
  limit = 10000,
  page = 0,
}: getAssociatedSearchLogsProps) {
  const response = await authFetch({
    url: '/occurrence/associated-search-logs',
    method: 'POST',
    data: {
      search_type: searchType,
      start_date: startDate,
      user_id: userId,
      finish_date: finishDate,
      limit: limit,
      page: page,
      orders: [{ "field": "created_at", "sort": "desc" }]
    }
  })

  return response.data
}