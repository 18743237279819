import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { StateMethods, useHookstate, type State } from '@hookstate/core'
import { getAllTypes } from '~/features/Type/services'
import IType from '~/features/Type/interfaces/IType'
import type { ListTypeProps } from '../FilterAutocompleteTypesAndSubtypesWithDescription'

interface FilterAutoCompleteTypeProps {
  readonly onTypeChange: (value: IType | null) => void
  readonly categoryId?: number | number[] | null
  readonly hideCode?: boolean
  readonly typeId?: number | null
  readonly disabled?: boolean
  readonly error?: boolean
  readonly isActive?: boolean
  readonly refTypeIds?: State<number | null>
  readonly ListTypeProps?: StateMethods<ListTypeProps[] | []>
  readonly label?: string;
}

export default function FilterAutocompleteType({
  disabled,
  typeId,
  onTypeChange,
  hideCode = false,
  categoryId,
  error,
  isActive = false,
  refTypeIds,
  ListTypeProps,
  label
}: FilterAutoCompleteTypeProps) {
  const { translate } = useLang()
  const [options, setOptions] = useState<readonly IType[]>([])
  const [optionsFilter, setOptionsFilter] = useState<readonly IType[]>([])
  const resTypes = useHookstate<readonly IType[]>([])

  const [value, setValue] = useState<IType | null>(null)
  const loading = useHookstate(false)


  useEffect(() => {
    getAllTypes().then((types) => {
      const filteredTypes = isActive
        ? types.filter((item) => Boolean(item.active) == true)
        : types
      resTypes.set(
        filteredTypes.map((type) => ({
          ...type,
          displayName: hideCode
            ? `${type.name}`
            : `[${type.code}] ${type.name}`,
          category_id: type.category_id ?? null,
        }))
      )

      setOptions(resTypes.get())
      setOptionsFilter(resTypes.get())

      if (typeId) {
        const option = resTypes.get().find((option) => option.id == typeId)
        setValue(option ?? null)
      } else {
        setValue(null)
      }
    })
  }, [])

  useEffect(() => {
    if (!typeId) {
      setValue(null)
    } else {
      const option = options.find((option) => option.id == typeId)
      setValue(option ?? null)
    }
  }, [options, typeId])

  useEffect(() => {
    if (categoryId) {
      if (options) {
        const filter = options.filter(element => element.categories?.find((category) => categoryId == category.id))
        setOptionsFilter(filter.length ? filter : [])
      } else {
        setOptionsFilter(options ?? [])
      }
    } else {
      setOptionsFilter(options ?? [])
    }
  }, [categoryId, options])

  useEffect(() => {
    if (refTypeIds?.get() && categoryId) {
      const filterType = options?.filter((typeMap) => refTypeIds?.get() == Number(typeMap.id)) ?? []
      setValue(filterType[0])
      onTypeChange(filterType[0])
      updateListDescription(filterType[0])

    } else if (!refTypeIds?.get() && !categoryId) {
      setOptionsFilter(options ?? [])
      setValue(null)
    }

  }, [refTypeIds?.get()])

  const updateListDescription = (type) => {
    const findType = ListTypeProps?.get().findIndex((typeList) => typeList.id === type?.id)

    ListTypeProps?.set([])
    if (findType == -1 && type?.id) {
      ListTypeProps?.merge([{
        id: type?.id ?? '',
        typeName: type?.name ?? '',
        typeCode: type?.code ?? ''
      }])
    }
  }

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        id="filter-types"
        disabled={(!!categoryId && !optionsFilter.length) || disabled}
        options={optionsFilter}
        value={value}
        noOptionsText={translate('No options')}
        size="small"
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.displayName ?? option.name ?? ''}
        onChange={(_, type) => {
          setValue(type ?? null)
          onTypeChange(type ?? null)

          updateListDescription(type)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate(label ? label : 'Type')}
            helperText={error}
          />
        )}
      />
    </FormControl>
  )
}
