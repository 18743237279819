/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import {
  getDispatchByCity,
  getDispatchByDistrict,
} from './services/index'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import ColumnChartHorizontal from '~/components/ColumnChartMui/HorizontalChart'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DispatchByLocationCharts({
  loading,
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
  operationUnitId
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const cityData = useHookstate<ResponseProps>([])
  const districtData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const groupDataTotal = useHookstate<number>(0)
  const cityDataTotal = useHookstate<number>(0)
  const districtDataTotal = useHookstate<number>(0)

  useEffect(() => {
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    groupDataTotal.set(0)
    cityDataTotal.set(0)
    districtDataTotal.set(0)
    loading.set(true)

    getDispatchByCity({
      agencyId: agencyId,
      deviceId: deviceId,
      operationId: operationId,
      dispatchGroupId: dispatchGroupId,
      categoryIds: category,
      typeIds: type,
      subtypeIds: subtype,
      startDate: dateStart,
      endDate: dateFinish,
      payload: payload.get(),
      consummated: consummated,
      statusIds: statusIds,
      cityIds: cityIds,
      groupSetId: groupSetId,
      location: location,
      closedStatusClassId: closedStatusClassId,
      closedStatusId: closedStatusId,
      qualifierOptions: qualifierOptions,
      operationUnitId: operationUnitId
    })
      .then((response) => {
        const result = response.map((responseData) => {
          cityDataTotal.set(cityDataTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return cityData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })

    getDispatchByDistrict({
      agencyId: agencyId,
      deviceId: deviceId,
      operationId: operationId,
      dispatchGroupId: dispatchGroupId,
      categoryIds: category,
      typeIds: type,
      subtypeIds: subtype,
      startDate: dateStart,
      endDate: dateFinish,
      payload: payload.get(),
      consummated: consummated,
      statusIds: statusIds,
      cityIds: cityIds,
      groupSetId: groupSetId,
      location: location,
      closedStatusClassId: closedStatusClassId,
      closedStatusId: closedStatusId,
      qualifierOptions: qualifierOptions,
      operationUnitId: operationUnitId
    })
      .then((response) => {
        const result = response.map((responseData) => {
          districtDataTotal.set(districtDataTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return districtData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} hidden={!expandFilterSearch} paddingBottom={1}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch by Location')}
        </Typography>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={6} hidden={!expandFilterSearch} paddingRight={1}>
          <ColumnChartHorizontal
            data={cityData.attach(Downgraded).get()}
            title={'Dispatch x City'}
            subtitle={`Total: ${cityDataTotal.get()}`}
            typeTotal='Cities'
            colorBar='secondary'
          />
        </Grid>
        <Grid item xs={6} hidden={!expandFilterSearch}>
          <ColumnChartHorizontal
            data={districtData.attach(Downgraded).get()}
            title={'Dispatch x District'}
            subtitle={`Total: ${districtDataTotal.get()}`}
            typeTotal='Districts'
            colorBar='tertiary'
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
