import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';
import SearchWeapon from '../SearchWeapon';
import { authFetch } from '~/services/fetch';
import type ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { useHookstate } from '@hookstate/core';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function SearchWeaponModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('SearchWeaponModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.SearchWeaponModalDraggable)
      }
      loadingComponent.set(false)

    })

    return () => {
      windowManager()['searchWeapon'].open.set(false)
    }
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchWeaponModalDraggable',
        isOpen: windowManager()['searchWeapon'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['searchWeapon'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])



  if (windowManager()['searchWeapon'].open.value) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && !loadingComponent.get() &&
          <StandardModal
            title={`${translate("Search Weapons")}`}
            avatar={InsertDriveFile}
            dimensions={{
              width: 400
            }}
            isExpanded={getPositionsDraggable.is_window_minimized}
            handleStop={handleStop}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 250,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 100
            }}
            closeButton={!(windowManager()['searchWeapon'].disabled.value) ? () => {
              windowManager()['searchWeapon'].open.set(false)
              windowManager()['searchWeapon'].dataSearchWeapon.set([])
            } : false}
          >
            <SearchWeapon />
          </StandardModal>
        }
      </>
      , modal)
  } else {
    return <></>
  };
}
