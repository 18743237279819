import React, { ChangeEvent, useEffect } from 'react'
import {
  IconButton,
  ListItem,
  ListItemText,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Badge,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback'
import { getInfoDispatch, getOccurrenceAllService, getTimeLineDispatch } from '~/features/Occurrence/services/index'
import { useHookstate } from '@hookstate/core'
import IType from '~/features/Type/interfaces/IType'
import ISubtype from '~/features/Subtype/interfaces/ISubtype'
import ICity from '~/features/City/interfaces/ICity'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLang } from '~/hooks/useLang'
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState'
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState'
import useSystemState from '~/features/System/stores/SystemState'
import TypeSelectField from '~/features/Entry/components/TypeSelectField'
import SubtypeSelectField from '~/features/Entry/components/SubtypeSelectField'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import InputMask from 'react-input-mask'
import { getOnlyNumbersInString } from '~/utils/strings'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity'
import FilterAutocompleteDistrict from '~/components/FilterSelectAutocompleteDistrict'
import FilterAutocompleteStatus from '~/components/FilterAutoCompleteStatus';
import { TimeLineOccurrenceDispatchProps } from '../../interfaces/IDispatchOccurrence'
import TimelineDispatchesOccurrence from './components/timeLineDispatchesOccurrence'
import InputOccurrenceNumber from '~/components/InputOccurrenceNumber'
import FilterSwitch from '~/components/FilterSwitch'
import FilterKeyword from '~/features/Report/components/FilterKeyword'

interface Dispatches {
  readonly id: number
  readonly code: string | null
  readonly status: {
    readonly id: number
    readonly name: string | null
    readonly acronym: string | null
  }
}
interface OccurrenceListProps {
  readonly id: number
  readonly location: string
  readonly type: IType
  readonly subtype: ISubtype
  readonly city: ICity
  readonly name: string
  readonly phone: string
  readonly calls: number
  readonly dispatches: readonly Dispatches[]
}

interface filtersProps {
  readonly hide: boolean
  readonly content: Array<any>
  readonly fields: {
    readonly id: string | null
    readonly name: string | null
    readonly phone: string | null
    readonly code: string | null
    readonly type_id: number | null
    readonly subtype_id: number | null
    readonly city_id: number | null
    readonly district_id: number | null
    readonly location: string | null
    readonly created_at: Array<string> | null
  }
}

const filtersDefault: filtersProps = {
  hide: true,
  content: [],
  fields: {
    id: null,
    name: null,
    phone: null,
    code: null,
    type_id: null,
    subtype_id: null,
    city_id: null,
    district_id: null,
    location: null,
    created_at: [],
  },
}

interface dispatchDestinationProps {
  readonly id: number
  readonly code: string
}



// eslint-disable-next-line max-lines-per-function
export default function OccurrenceList() {
  const { translate } = useLang()
  const { syncOccurrenceEditAttendent, statusDispatchRelated, occurrence, dispatchesOccurrence } = useOccurrenceState()
  const { id: occurrencId } = occurrence();
  const { windowManager, getStatusColors } = useSystemState()
  const { syncPreviousComments } = useOccurrenceCommentState()
  // const occurrences = useHookstate<readonly OccurrenceListProps[]>([])

  const filters = useHookstate(filtersDefault)
  const typeName = useHookstate('')
  const subTypeName = useHookstate<string | null>('')
  const [open, setOpen] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [agency, setAgency] = React.useState('')
  const [dispatchGroup, setDispatchGroup] = React.useState('')
  const [type, setType] = React.useState('')
  const [subtype, setSubtype] = React.useState('')
  const [status, setStatus] = React.useState('')
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const period = useHookstate('')
  const groupIds = useHookstate<readonly number[]>([])
  const checked = useHookstate<boolean>(false)
  const dispatchDestination = useHookstate<dispatchDestinationProps | null>(null)
  const loading = useHookstate(false)
  const maskCelPhone = '(99) 99999-9999'
  const maskPhone = '(99) 9999-9999'
  const mask = useHookstate(maskCelPhone)
  const phoneValue = filters.fields.phone.get() == null ? '' : `${filters.fields.phone.get()}`
  const checkedCreatedByMe = useHookstate<boolean>(false)
  const statusId = useHookstate<number | null>(null)
  const dispatchIdSelected = useHookstate(null)
  const timeLine = useHookstate<TimeLineOccurrenceDispatchProps[]>([])
  const {screen} = window
  const height = filters.hide.get() ? screen.height / 2.5 : screen.height / 2
  const comments = useHookstate<string>('')
  const includeAllWords = useHookstate<boolean>(false)
  const keyWordFormated = useHookstate<string[]>([])



  useEffect(() => {
    loading.set(true)
    dispatchesOccurrence().set([])
    nextData()
  }, [])


  const nextData = async (reset = false) => {
    makeCommentsRequest()
    const pageQuery = reset ? 0 : Math.floor(dispatchesOccurrence().get().length / 10)
    const response = await getOccurrenceAllService({
      keyWords: keyWordFormated.get(),
      includeAllWords: includeAllWords.get(),
      page: pageQuery,
      limit: 10,
      filters: filters.content.get(),
      dispatchGroupIds: groupIds.get(),
      visibleDispatchGroups: checked.get(),
      createdByMe: checkedCreatedByMe.get(),
      statusId: statusId.get()
    })
    if (reset) {
      dispatchesOccurrence().set(response.results)
    } else {
      dispatchesOccurrence().merge(response.results)
    }
    loading.set(false)
  }

  const onClick = (params) => {
    syncOccurrenceEditAttendent(params.id)
    syncPreviousComments(params.id)
    windowManager().occurrence.open.set(true)
  }

  const actionFilter = () => {
    loading.set(true)
    filters.content.set([])
    Object.keys(filters.fields.value).map((field) => {
      if (
        filters.fields.value[field] != null &&
        filters.fields.value[field] != ''
      ) {
        filters.content.merge([
          {
            field: field,
            operator: field === 'created_at' ? 'between' : 'contains',
            value: filters.fields.value[field] ?? null,
          },
        ])
      }
    })
    nextData(true)
    filters.hide.set(true)
  }

  const handleClickOpen = (code) => {
    getInfoDispatch({ code: code }).then((response) => {
      dispatchDestination.set(
        response?.data?.unified?.dispatch_destination
          ? response?.data?.unified?.dispatch_destination
          : null
      )
      setCode(code)
      setAgency(` - ${response.data.agency?.name}`)
      setDispatchGroup(`${response.data.dispatch_group?.name}`)
      setType(`${response.data.type?.name}`)
      setSubtype(
        response.data.subtype ? `- ${response.data.subtype?.name}` : ''
      )
      setStatus(`[${response.data.status?.acronym}]`)
      dispatchIdSelected.set(response.data.id)
      setOpen(true)
    }).finally(() => {
      getTimeLineDispatch({ dispatchId: dispatchIdSelected.get() }).then((response) => {
        timeLine.set(response.data)
      }
      )
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const clearFilters = () => {
    loading.set(true)
    filters.content.set([])
    filters.fields.id.set(null)
    filters.fields.name.set(null)
    filters.fields.phone.set(null)
    filters.fields.code.set(null)
    filters.fields.type_id.set(null)
    filters.fields.subtype_id.set(null)
    filters.fields.city_id.set(null)
    filters.fields.district_id.set(null)
    filters.fields.location.set(null)
    filters.fields.created_at.set([])
    period.set('')
    startDate.set(null)
    finishDate.set(null)
    groupIds.set([])
    statusId.set(null)
    checked.set(false)
    checkedCreatedByMe.set(false)
    includeAllWords.set(false)
    comments.set('')
    keyWordFormated.set([])

    // occurrences.set([])
    dispatchesOccurrence().set([])
    nextData()
  }

  const handleChange = (event) => {
    checked.set(event.target.checked)
  }

  const handleChangeCheckCreatedByMe = (event) => {
    checkedCreatedByMe.set(event.target.checked)
  }

  React.useEffect(() => {
    if (phoneValue.length <= 9) {
      mask.set(maskCelPhone)
    }
  }, [phoneValue])

  const defineMask = (number) => {
    if (number <= 10) {
      mask.set(maskPhone)
    } else {
      mask.set(maskCelPhone)
    }
  }

  const makeCommentsRequest = () => {
    if (comments.get()) {
      const wordsFormatedToSend = comments.get()?.replace(/\s/g, '').split(';')
      keyWordFormated.set(wordsFormatedToSend?.length ? wordsFormatedToSend : [])
    } else {
      return false
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container spacing={2}>
        <Grid container padding={1}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'start',
              marginLeft: 1,
              padding: 0,
            }}
          >
            <Tooltip title={translate('Filters')}>
              <IconButton
                aria-label="filters"
                onClick={() => filters.hide.set(!filters.hide.get())}
              >
                <Badge
                  badgeContent={filters.content.get().length}
                  color="primary"
                >
                  <FilterAltIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} padding={1} hidden={!filters.hide.get()}>
            <Grid container direction={'row'}>
              <FilterSelectPeriod
                // disabled={lockFields.get()}
                xsDateBetween={3.9}
                xsPeriod={3.9}
                onStartDateChange={(value) => {
                  startDate.set(value?.toJSON() ?? null)
                  if (startDate.get() != null) {
                    filters.fields.created_at[0]?.set(startDate.get())
                  }
                }}
                onFinishDateChange={(value) => {
                  finishDate.set(value?.toJSON() ?? null)
                  if (finishDate.get() != null) {
                    filters.fields.created_at[1]?.set(finishDate.get())
                  }
                }}
                onPeriodChange={(value) => {
                  period.set(value)
                }}
                period={period.get()}
              />
            </Grid>
            <Grid
              container
              xs={12}
              direction="row"
              spacing={1}
              padding={1}
              hidden={filters.hide.get()}
            >
              <Grid item xs={4} spacing={1}>
              <InputOccurrenceNumber 
                occurrence={filters.fields.id} 
                onOccurrenceChange={(event: ChangeEvent<HTMLInputElement>) => {
                  filters.fields.id.set(event.target.value)
                }}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <TextField
                  fullWidth
                  label={translate('Nº Occurrence')}
                  size="small"
                  value={filters.fields.code.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.code.set(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <FilterAutocompleteDispatchsGroups
                  dispatcheGroups={groupIds.get() ?? undefined}
                  onGroupDispatchChange={(group) => {
                    groupIds.set(group)
                  }}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <TypeSelectField
                  readonly={windowManager()['entry'].readonly.value}
                  isValid={true}
                  typeId={filters.fields.type_id}
                  typeName={typeName}
                  isActive={true}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SubtypeSelectField
                  readonly={windowManager()['entry'].readonly.value}
                  isValid={Boolean(filters.fields.type_id)}
                  typeId={filters.fields.type_id}
                  subtypeId={filters.fields.subtype_id}
                  subTypeName={subTypeName}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <FilterAutocompleteStatus
                  onStatusChange={(status) => { statusId?.set(status) }}
                  statusId={statusId.get()}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <TextField
                  fullWidth
                  label={translate('Requester')}
                  size="small"
                  value={filters.fields.name.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.name.set(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <InputMask
                  mask={mask.get()}
                  value={phoneValue}
                  onBlur={() => defineMask(phoneValue.length)}
                  maskChar=""
                  onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.phone.set(
                      getOnlyNumbersInString(changeEvent.target.value)
                    )
                  }}
                >
                  <TextField
                    fullWidth
                    label={translate('Phone')}
                    size="small"
                    value={filters.fields.phone.get() ?? ''}
                  // onChange={(event: ChangeEvent<HTMLInputElement>) => { filters.fields.phone.set(event.target.value) }}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={4} spacing={1}>
                <FilterAutocompleteCity
                  onCityChange={(city) => {
                    filters.fields.city_id?.set(city)
                  }}
                  cityId={filters.fields.city_id?.get()}
                />
              </Grid>              
              <Grid item xs={4} spacing={1}>
                <TextField
                  fullWidth
                  label={`${translate('Location')}`}
                  size="small"
                  value={filters.fields.location.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.location.set(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <FilterAutocompleteDistrict
                  onDistrictChange={(district) => {
                    filters.fields.district_id?.set(district)
                  }}
                  districtId={filters.fields.district_id}
                  cityId={filters.fields.city_id.get()}
                />
              </Grid>
              <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
                <FilterKeyword keyWords={comments} />
              </Grid>
              <Grid item xs={4} sx={{ pl: 2 }}>
                <FilterSwitch
                  label={translate('Contain all keywords')}
                  onCheckedChange={() => {
                    includeAllWords?.set(!includeAllWords.value)
                  }}
                  // disabled={lockFields}
                  checked={includeAllWords?.get()}
                  tooltip={'All words must exist in the comment'}
                />
              </Grid>
              <Grid item spacing={1} sx={{ pl: 2 }}>
                <FilterSwitch
                  label={translate(
                    'Occurrences created by me'
                  )}
                  onCheckedChange={() => {
                    checkedCreatedByMe.set(!checkedCreatedByMe.get())
                  }}
                  // disabled={lockFields}
                  checked={checkedCreatedByMe.get()}
                />
              </Grid>
              <Grid item spacing={1} sx={{ pl: 2 }}>
                <FilterSwitch
                  label={translate(
                    'Only dispatch groups assigned to the station'
                  )}
                  onCheckedChange={() => {
                    checked.set(!checked.get())
                  }}
                  // disabled={lockFields}
                  checked={checked.get()}
                />
              </Grid>
              <Grid
                item
                marginTop={1}
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                gap={2}
              >
                <Grid item spacing={1}>
                  <Button variant="outlined" onClick={() => clearFilters()}>
                    {translate('Clear Filters')}
                  </Button>
                </Grid>
                <Grid item spacing={1}>
                  <Button variant="contained" onClick={() => actionFilter()}>
                    {/* <SearchIcon /> */}
                    {translate('Search')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Grid>

        <Grid container padding={1} >
          <Grid item xs={12}>
            <InfiniteScroll
              dataLength={dispatchesOccurrence().value.length}
              // dataLength={dispatchesOccurrence().value.length}
              next={nextData}
              hasMore={
                dispatchesOccurrence().value.length % 10 == 0 &&
                dispatchesOccurrence().value.length != 0
                // dispatchesOccurrence().value.length % 10 == 0 &&
                // dispatchesOccurrence().value.length != 0
              }
              height={filters.hide.get() ? screen.height/2.5 : screen.height/1.2}
              loader={false}
            >
              {dispatchesOccurrence().value.length === 0 && !loading.get() ? (
              // {dispatchesOccurrence().value.length === 0 && !loading.get() ? (
                <Grid
                  xs={12}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>{translate('No Occurrences')}</Typography>
                </Grid>
              ) : dispatchesOccurrence().value.length != 0 && !loading.get() ? (
                dispatchesOccurrence().get().map((items) => (
                // ): dispatchesOccurrence().value.length != 0 && !loading.get() ? (
                //   dispatchesOccurrence().get().map((items) => (
                  <>
                    <ListItem
                      key={items.id}
                      sx={{ mt:1 }}
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() => onClick(items)}
                          disabled={Boolean(
                            items?.dispatches?.find(
                              (element) => element.status.acronym === 'UN'
                            )
                          )}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={`OC: ${items?.id} - ${items?.type?.name ? items?.type?.name : ''
                          } ${items?.subtype?.name ? items?.subtype?.name : ''
                            }  ${items?.calls ? items?.calls > 1  ? `| ${translate('RepeatedCall')}` : '' : ''
                          }`}
                        secondary={`${translate('Location')}: ${items?.location ? items?.location : ''
                          } ${items?.city?.name ? `- ${items?.city?.name}` : ''
                          } ${items?.name ? `| Solicitante: ${items?.name}` : ''
                          } ${items?.phone ? `| Telefone: ${items?.phone}` : ''}`}
                      />
                    </ListItem>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {items.dispatches.map((element) => (
                        <Chip
                          key={element.id}
                          label={element.code}
                          onClick={() => handleClickOpen(element.code)}
                          sx={{
                            margin: '16px 0 0 16px',
                            backgroundColor: getStatusColors(element.status?.id)
                              .main,
                            color: getStatusColors(element.status?.id)
                              .contrastText,
                            '&:hover': {
                              backgroundColor: getStatusColors(
                                element.status?.id
                              ).dark,
                              color: getStatusColors(element.status?.id)
                                .contrastText,
                            },
                          }}
                        />
                      ))}
                    </Grid>
                    <Divider sx={{ marginTop: 2 }} />
                  </>
                ))
              ) : (
                <Grid
                  container
                  xs={12}
                  item
                  display={'flex'}
                  marginTop={'30%'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <CircularProgress />
                </Grid>
              )}
            </InfiniteScroll>
          </Grid>
        </Grid>
        <Dialog maxWidth={'xs'}  fullWidth={true} open={open} onClose={handleClose}>
          <DialogTitle>
            {translate('Dispatch')} - {code} - {status}
            <Typography variant='subtitle2'>
              {dispatchGroup} {agency}
            </Typography>
            <Typography variant='subtitle2'>
              {type} {subtype}
            </Typography>
          </DialogTitle>
          <DialogContent>

            {dispatchDestination.get() ? (
              <>
                <br />
                <Typography>
                  {translate('Unified')} {translate('With')}:{' '}
                  <Chip label={dispatchDestination.get()?.code} />
                </Typography>
              </>
            ) : (
              false
            )}

            <TimelineDispatchesOccurrence timeLinePoint={timeLine} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {translate('Close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </ErrorBoundary>
  )
}
