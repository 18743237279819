import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

export default function StationCardDivisor({divisorName}){
  return (
    <Card variant="outlined">
        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid xs={10} sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Typography textAlign={'center'}>
              {divisorName}
            </Typography>
          </Grid>
        </Grid>
    </Card>
  )
}