import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import StandardModal from '~/components/StandardModal';
import { Tune } from '@mui/icons-material';
import { Grid, IconButton, Button, CircularProgress, Slider, Typography, TextField, InputAdornment, Stack, Tooltip } from '@mui/material';
import { storeScreenSettings } from '~/features/System/services';
import InputDateTime from '~/components/InputDate';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { Search } from '@mui/icons-material'
import FilterAutocompleteCities from '~/components/FilterAutocompleteCities';
import { Downgraded, useHookstate } from '@hookstate/core';
import { getMarkersDispatchFilteredPrevisionsService, getMarkersPrevisionService } from '../../services/request';
import { useMarkerState } from '../../stores/MapState';
import notify from '~/utils/notify';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

const modal = document.getElementById('div-modal') as HTMLElement;


// eslint-disable-next-line max-lines-per-function
export default function MapFiltersModalDraggable() {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const { markersState } = useMarkerState();
  const location = useLocation();
  const loading = useHookstate<boolean>(false)
  const loadingComponent = useHookstate(true)
  const criticalLevel = useHookstate(1)
  const dateFinishMax = useHookstate<Dayjs | undefined>(undefined);
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })


  function generateMarks() {
    const marks: { value: number; label: string }[] = [];

    for (let value = 5; value <= 100; value += 5) {
      // eslint-disable-next-line functional/immutable-data
      marks.push({
        value: value,
        label: value === 5 || value === 100 ? `${value}%` : ''
      });
    }

    return marks;
  }

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('MapFiltersModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MapFiltersModalDraggable)
      }
      loadingComponent.set(false)
    })

    return () => {
      windowManager()['mapFilters'].open.set(false)
    }
  }, [])


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapFiltersModalDraggable',
        isOpen: windowManager()['mapFilters'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['mapFilters'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  const error = useHookstate({
    start_date: false,
    finish_date: false,
  })

  const handleSearchPrevision = () => {
    if (!windowManager()['mapFilters']['valueFilter']['start_date'].get() || !windowManager()['mapFilters']['valueFilter']['finish_date'].get()) {

      !windowManager()['mapFilters']['valueFilter']['start_date'].get() && error.start_date.set(true)
      !windowManager()['mapFilters']['valueFilter']['finish_date'].get() && error.finish_date.set(true)
      // !windowManager()['mapFilters']['valueFilter']['city_ids']?.get()?.length && error.cities.set(true)

      notify({ message: translate('It is necessary to inform the period and at least one city.'), type: 'error' })
    } else {
      loading.set(true)
      getMarkersPrevisionService({
        start_date: windowManager()['mapFilters']['valueFilter']['start_date']?.attach(Downgraded).get(),
        finish_date: windowManager()['mapFilters']['valueFilter']['finish_date']?.attach(Downgraded).get(),
        criticalityLevel: criticalLevel.get()
        // city: windowManager()['mapFilters']['valueFilter']['city_ids']?.get()
      }).then((response) => {
        markersState()['prediction']?.set(response)
      }).finally(() => {
        loading.set(false)
      })
      getMarkersDispatchFilteredPrevisionsService({
        start_date: windowManager()['mapFilters']['valueFilter']['start_date']?.attach(Downgraded).get(),
        finish_date: windowManager()['mapFilters']['valueFilter']['finish_date']?.attach(Downgraded).get(),
        criticalityLevel: criticalLevel.get()
        // city: windowManager()['mapFilters']['valueFilter']['city_ids']?.get()
      }).then((response) => {
        markersState()['dispatchFiltered']?.set(response)
      })
    }

  }


  function valueCriticalLevel(value: number) {
    return `${value}%`;
  }

  const clearilters = () => {
    windowManager()['mapFilters']['valueFilter']['start_date'].set(null)
    windowManager()['mapFilters']['valueFilter']['finish_date'].set(null)
    windowManager()['mapFilters']['valueFilter']['city_ids'].set([])
    error.finish_date.set(false)
    error.start_date.set(false)
  }



  if (windowManager()['mapFilters'].open.value && !loadingComponent.get()) {

    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingComponent.get() &&
          <StandardModal
            title={translate('Dispatch Prediction Map')}
            subheader={translate('Selecione um período e o percentual de áreas de acordo com a criticidade')}
            handleStop={handleStop}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 80,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 141
            }}
            // closeButton={!(windowManager()['mapFilters'].disabled.value) ? () => windowManager()['mapFilters'].open.set(false) : false}
            isExpanded={getPositionsDraggable.is_window_minimized}
            dimensions={{
              width: 600
            }}
          >
            <Grid container xs={12} display={'flex'} flexDirection={'row'} >
              <Grid item xs={12} display={'flex'} flexDirection={'row'}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'pt-br'}
                >
                  <InputDateTime
                    sx={{ width: '50%', pr: 1 }}
                    disabled={false}
                    inputFormat='DD/MM/YYYY HH:mm'
                    placeholder={translate('dd/mm/yyyy')}
                    views={['day', 'hours']}
                    onDateTimeChange={(date) => {
                      if (date) {
                        error.start_date.set(false)
                        windowManager()['mapFilters']['valueFilter']['finish_date'].set(null)
                        dateFinishMax.set(date.add(7, 'day'))
                      } 

                      windowManager()['mapFilters']['valueFilter']['start_date'].set(date)
                    }}
                    label={translate('Initial Date')}
                    required
                    error={error.start_date.get()}
                    disableFuture={false}
                    dateTime={windowManager()['mapFilters']['valueFilter']['start_date'].attach(Downgraded).get()}
                  />
                  <InputDateTime
                    sx={{ width: '50%', }}
                    disabled={false}
                    inputFormat='DD/MM/YYYY HH:mm'
                    views={['day', 'hours']}
                    placeholder={translate('dd/mm/yyyy')}
                    onDateTimeChange={(date) => {
                      if (date) {
                        error.finish_date.set(false)
                      } 

                      windowManager()['mapFilters']['valueFilter']['finish_date'].set(date)
                    }}
                    label={translate('End Date')}
                    required
                    error={error.finish_date.get()}
                    disableFuture={false}
                    dateTime={windowManager()['mapFilters']['valueFilter']['finish_date'].attach(Downgraded).get()}
                    dateMax={dateFinishMax.attach(Downgraded).get()}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, paddingX: 1 }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography id="input-slider" gutterBottom alignContent={'center'}>
                    {`${translate('Criticality level')}: ${Math.round(criticalLevel.get() * 100)}%`}
                  </Typography>
                  <Tooltip title={translate('The lower the percentage of selected areas, the greater the criticality to be visualized')}>
                  <IconButton aria-label="info" size="small">
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={0.5} marginTop={2}>
                  <Typography variant='caption'>
                    {'Áreas mais Críticas'}
                  </Typography>
                  <Grid item paddingRight={4} paddingLeft={2} width={'100%'}>

                    <Slider
                      aria-label="Critical-percent"
                      defaultValue={100}
                      getAriaValueText={valueCriticalLevel}
                      valueLabelDisplay="auto"
                      valueLabelFormat={value => `${value}%`}
                      shiftStep={5}
                      onChange={(_, value) => {
                        if (value) {
                          criticalLevel.set(Number(value) / 100)
                        }
                      }}
                      step={5}
                      marks={generateMarks()}
                      min={5}
                      max={100}
                    />
                  </Grid>
                  <Typography variant='caption'>
                    {'Todas as Áreas'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item display={'flex'} alignItems={'center'} justifyContent={'right'} sx={{ mr: 0, ml: 'auto', p: 1 }}>
                <Button
                  variant="text"
                  onClick={() => clearilters()}
                  size='medium'
                  sx={{
                    height: '40px',
                    textTransform: 'none',
                    textDecorationLine: 'underline',
                    color: 'text.primary',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'primary.main'
                    }
                  }}
                >
                  {translate('Clear filters')}
                </Button>
                <IconButton
                  onClick={() => handleSearchPrevision()}
                  disabled={false}
                  title={translate('Search')}
                >
                  {loading.get() ? <CircularProgress size={30} /> : <Search />}
                </IconButton>
              </Grid>
            </Grid>
          </StandardModal>
        }
      </>
      , modal)
  } else {
    return <></>
  }
}