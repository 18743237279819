import React, { useEffect, useState } from 'react'
import { Card, CardHeader, IconButton } from '@mui/material'
import { useHookstate } from '@hookstate/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import dayjs, { Dayjs } from 'dayjs'
import { useLang } from '~/hooks/useLang'

import { CurrentCoordination } from '~/components/FilterSwitchCoordination/interface'
import DispatchByDeviceCharts from '../DispatchByDeviceCharts'
import DispatchByLocationCharts from '../DispatchByLocationCharts'
import DispatchByTimeAndStatusCharts from '../DispatchByTimeAndStatusCharts'
import DispatchByTotalByStatusCards from '../DispatchByTotalByStatusCards'
import DispatchByTypeAndSubtypeCharts from '../DispatchByTypeAndSubtypeCharts'
import DispatchFiltersDashboard from '../DispatchFiltersDashboard'
import DispatchHotMap from '../DispatchHotMap'
import DispatchByTimeChart from '../DispatchByTimeChart'

export interface DispatchClosedProps {
  readonly id: number
  readonly name: string
  readonly acronym: string
  readonly category_id?: number
}

/* eslint-disable max-lines-per-function */
export default function DispatchCardDashboard() {
  const { translate } = useLang()
  const refresh = useHookstate<boolean>(false)
  const loading = useHookstate<boolean>(false)
  const expandFilterSearch = useHookstate<boolean>(true)
  const lockFields = useHookstate<boolean>(false)
  const currentCoordination = useHookstate<CurrentCoordination | null>(null)

  const withSolvedThirdParty = useHookstate<boolean>(false)

  const categoryIds = useHookstate<number[]>([])
  const agencyId = useHookstate<number | null>(null)
  const deviceId = useHookstate<number | null>(null)
  const groupId = useHookstate<readonly number[]>([])
  const operationId = useHookstate<number | null>(null)

  const typeIds = useHookstate<number[]>([])
  const subtypeIds = useHookstate<number[]>([])
  const refTypeIds = useHookstate<number[] | []>([]);
  const initialPeriod = useHookstate<string>('0')
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(24, 'hours')
  )
  const [finishDate, setFinishDate] = useState<Dayjs | null>(dayjs())

  const consummatedAndAttempted = useHookstate<string | null>(null)
  const statusIds = useHookstate<number[]>([])
  const cityIds = useHookstate<number[]>([])
  const dispatchGroupSetId = useHookstate<number | null>(null)
  const location = useHookstate<string>('')
  const operationUnitId = useHookstate<number | null>(null)
  const closedStatusClassId = useHookstate<number | null>(null)
  const closedStatusId = useHookstate<number | null>(null)
  const qualifiersSelected = useHookstate<number[]>([])
  const clearState = useHookstate<boolean>(false)
  const searched = useHookstate<boolean>(false)

  useEffect(() => {
    document.title = translate('Dispatch Dashboard - Dispatch System')
  }, [])

  return (
    <Card sx={{ boxShadow: 4, paddingBottom: 2, height: expandFilterSearch.value ? 'auto' : 50 }}>
      <CardHeader
        title={`${translate('Dispatch')}`}
        avatar={
          <IconButton
            onClick={() => {
              expandFilterSearch.set(!expandFilterSearch.get())
            }}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: 1,
              padding: 0,
            }}
          >
            {expandFilterSearch.value ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        }
        titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
      />
      <DispatchFiltersDashboard
        loading={loading}
        currentCoordination={currentCoordination}
        withSolvedThirdParty={withSolvedThirdParty}
        deviceId={deviceId}
        groupId={groupId}
        agencyId={agencyId}
        categoryIds={categoryIds}
        typeIds={typeIds}
        subtypeIds={subtypeIds}
        refTypeIds={refTypeIds}
        initialPeriod={initialPeriod}
        operationId={operationId}
        onStartDateChange={(value) => setStartDate(value)}
        onFinishDateChange={(value) => setFinishDate(value)}
        onPeriodChange={(value) => initialPeriod.set(value)}
        hidden={expandFilterSearch.value}
        lockFields={lockFields.get()}
        consummatedAndAttempted={consummatedAndAttempted}
        setCoordinationDates={(checked) => {
          if (checked) {
            groupId.set(
              currentCoordination
                .get()
                ?.dispatch_groups.map((group) => group.id) ?? []
            )
            initialPeriod.set(
              'c' +
              currentCoordination.get()?.date_begin +
              '&' +
              currentCoordination.get()?.date_expected_closing
            )

            lockFields.set(true)
          } else {
            groupId.set([])
            initialPeriod.set('')
            lockFields.set(false)
          }
        }}
        clearFilters={() => {
          currentCoordination.set(null)
          withSolvedThirdParty.set(false)
          deviceId.set(null)
          groupId.set([])
          agencyId.set(null)
          refTypeIds.set([])
          groupId.set([])
          categoryIds.set([])
          typeIds.set([])
          subtypeIds.set([])
          initialPeriod.set('')
          operationId.set(null)
          setFinishDate(null)
          setStartDate(null)
          consummatedAndAttempted.set(null)
          statusIds.set([])
          cityIds.set([])
          dispatchGroupSetId.set(null)
          location.set('')
          operationUnitId.set(null)
          closedStatusClassId.set(null)
          closedStatusId.set(null)
          qualifiersSelected.set([])
          clearState.set(!clearState.get())

          refresh.set(!refresh.get())
        }}
        searchButton={() => {
          refresh.set(!refresh.get())
          searched.set(true)
        }}
        statusIds={statusIds}
        cityIds={cityIds}
        dispatchGroupSetId={dispatchGroupSetId}
        location={location}
        operationUnitId={operationUnitId}
        closedStatusClassId={closedStatusClassId}
        closedStatusId={closedStatusId}
        qualifiersSelected={qualifiersSelected}
        clearState={clearState}
      />
      {searched.get() && <>
        <DispatchByTotalByStatusCards
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          operationId={operationId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          operationUnitId={operationUnitId.get()}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
        />

        <DispatchByTypeAndSubtypeCharts
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          refresh={refresh.get()}
          operationId={operationId.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
          operationUnitId={operationUnitId.get()}
        />

        <DispatchByTimeAndStatusCharts
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          operationUnitId={operationUnitId.get()}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
        />

        <DispatchByLocationCharts
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          location={location.get() ? location.get() : null}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
          groupSetId={dispatchGroupSetId.get()}
          operationUnitId={operationUnitId.get()}
        />

        <DispatchByDeviceCharts
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          operationUnitId={operationUnitId.get()}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
        />

        <DispatchByTimeChart
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          operationUnitId={operationUnitId.get()}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
        />
        <DispatchHotMap
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
          operationUnitId={operationUnitId.get()}
        />
      </>
      }
    </Card>
  )
}
