import React from 'react'
import { useForm } from 'react-hook-form'
import CrudCreate from '~/components/CrudCreate'
import IDeviceOutOfServiceReason from '../interfaces/IDeviceOutOfServiceReason'
import Layout from '~/components/Layout'
import { SvgIcon } from '@mui/material'

export default function Create() {
  const {
    register,
    handleSubmit,
    control,
    setValue: setFormValue,
  } = useForm<IDeviceOutOfServiceReason>()
  const fields = [
    { name: 'name', type: 'string', required: true },
    // { name: 'icon', type: 'string', renderCell: (params) =>
    //   <SvgIcon>
    //     {params.value}
    //   </SvgIcon>
    // },
    { name: 'usual', type: 'boolean' },
  ]

  const defaultValue = {
    usual: false,
  }

  return (
    <Layout marginLayout={true}>
      <CrudCreate
        model={'device/out-of-service-reason'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Device out of service reason successfully created'}
        title={'Add Device out of service reason'}
        subheader={'Add new Device out of service reason'}
        setFormValue={setFormValue}
        defaultValue={defaultValue}
      />
    </Layout>
  )
}
