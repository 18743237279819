import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import { truncateText } from '~/utils/strings';

export default function StationCardMonitor({station, user, stationIsOnline}){
  return (
    <Card variant="outlined">
       <CardContent>
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={3} sx={{ alignItems: 'center', textAlign: 'center' }}>
            <DesktopWindowsIcon fontSize={'large'} sx={{ color: stationIsOnline ? 'green' : 'red' }} />
            <Typography>
             {stationIsOnline ? 'ON' : 'OFF'}
            </Typography>
          </Grid>
          <Grid xs={9} sx={{ alignItems: 'center' }}>
            <Typography>
              <DesktopWindowsIcon fontSize={'small'}/> {station?.name ? (station?.name) : 'Não Informado'}
            </Typography>
            <Typography>
              <PersonIcon fontSize={'small'}/> {user?.representation_name ? truncateText(user?.representation_name) : 'Não Informado'}
            </Typography>
            <Typography>
              <CallIcon fontSize={'small'}/> {station?.branch_line ? station?.branch_line : 'Não Informado'}
            </Typography>
            {/* <Typography>
              Status: PM2023565162 [Aberto]
            </Typography> */}
          </Grid>
        </Grid>
        </CardContent>
    </Card>
  )
}