import React, { useEffect } from 'react';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import { useHookstate } from '@hookstate/core';
import { getHeatMapFiltered } from './services/index';

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean;
}
type ResponseProps = ReadonlyArray<{ readonly latitude: number, readonly longitude: number, readonly value: number }>;

export default function HeatMapDispatchLayer({ agencyId, operationId, deviceId, dispatchGroupId, category, type, subtype, dateStart, dateFinish, thirdParty, refresh, consummated, statusIds, cityIds, groupSetId, location, closedStatusClassId, closedStatusId, qualifierOptions,operationUnitId, locationAccuracy, refreshHopMap, loading}) {
  const heatmapOptions = {
    max: 30,
    radius: 20,
    blur: 20,
  }
  const mapData = useHookstate<ResponseProps>([]);
  const payload = useHookstate<PayloadProps>({ withSolvedThirdParty: thirdParty, exludeClosed: false })


  useEffect(() => {
    loading.set(true)
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false });

    getHeatMapFiltered(agencyId, deviceId, operationId, dispatchGroupId, category, type, subtype, dateStart, dateFinish, payload.get(), consummated, statusIds, cityIds, groupSetId, location, closedStatusClassId, closedStatusId, qualifierOptions,operationUnitId, locationAccuracy).then((response) => {
      const result = response.map((responseData) => ({ latitude: responseData.latitude, longitude: responseData.longitude, value: responseData.total }))
      return mapData.set(result)
    }).finally(() => loading.set(false))

  }, [refresh, refreshHopMap]);

  return (
    <HeatmapLayer
      points={mapData.get()}
      longitudeExtractor={point => point.longitude}
      latitudeExtractor={point => point.latitude}
      intensityExtractor={point => parseFloat(point.value)}
    />
  );
}