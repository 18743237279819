/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';

interface ContactCenterProps {
    readonly onIsCheckedChange: (value: number | null) => void;
    readonly isChecked: number | null;
    readonly disabled?: boolean;
    readonly isBooleanField?: boolean;
    readonly label?: string;
}

export default function FilterAutocompleteBoolean({ label = '', disabled, onIsCheckedChange, isChecked }: ContactCenterProps) {
    const { translate } = useLang();
    const options = [0, 1]
    const [value, setValue] = useState<number | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        setValue(isChecked)
    }, [isChecked])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-boolean'
                limitTags={2}
                disabled={disabled}
                sx={{ height: 2 }}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option ? 'Sim' : 'Não'}
                onChange={(_, type) => {
                    setValue(type)
                    onIsCheckedChange(type ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate(label)}
                    />
                )}
            />
        </FormControl>
    )
}