import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDeviceOutOfServiceReason from '../interfaces/IDeviceOutOfServiceReason';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IDeviceOutOfServiceReason>();
  const fields = [
    { name: 'id', type: 'hidden'},
    { name: 'name', type: 'string', required: true},
    // { name: 'icon', type: 'string'},
    { name: 'usual', type: 'boolean'},
];
  
    return(
      <CrudEdit 
        model={'device/out-of-service-reason'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Device out of service reason successfully updated!'}  
        title={'Edit out of service reason status'}
        getValues={ getValues }      
      />
    )
}