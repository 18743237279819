import React from 'react';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import DeviceDispatchListTable from '~/components/DeviceDispatchListTable';
import EditIcon from '@mui/icons-material/Edit';
import useDispatchVehicleState from '../../stores/DispatchVehicleState';
import { useLang } from '~/hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import NameField from '~/features/Entry/components/NameField';
import PhoneTextField from '~/components/PhoneField';
import { truncateText } from '~/utils/strings';
import { formatPhone } from '~/utils/format';
import useSystemState from '~/features/System/stores/SystemState';

export default function EventInputRequesterDispatch({ dispatchId, isValid, isValidEdit, canEdit = true, readonly = false }) {
  const { translate } = useLang()
  const { vehicleListInDispatch, syncVehicleListInDispatch, loading, retryConnect } = useDispatchVehicleState()
  const dispatchState = useDispatchState()
  const { occurrence } = dispatchState.dispatch()
  const { dispatchStatusActions } = useDispatchState();
  const { systemConfig } = useSystemState();

  return (
    <Grid item md={3.5} width={'100%'} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'space-between'} paddingLeft={1}>
      <DeviceDispatchListTable
        data={vehicleListInDispatch()}
        readonly={readonly}
        loading={loading().get()}
        refresh={retryConnect().get()}
        refreshAction={() => dispatchId ? syncVehicleListInDispatch(dispatchId) : false}
      />
      {systemConfig().displayRequesterDataInPrint.get() === '1' ?
        <>
          {!dispatchStatusActions().editingDispatchRequester.get() ? (

            <Grid
              item
              width={'100%'}
            >
              <Tooltip
                arrow
                placement="top"
                title={occurrence.name.get() ?? translate('Uninformed')}
              >
                <TextField
                  disabled={true}
                  placeholder={translate('Address')}
                  value={
                    `${translate('Requester')}: ${occurrence.name?.get() ? truncateText(String(occurrence.name.get()), 11) : translate('Not informed')}\n\n${translate('Phone')}: ${occurrence.get()?.phone ? `${formatPhone(occurrence.get().phone)}` : translate('Not informed')}`
                  }
                  size='small'
                  fullWidth
                  multiline
                  rows={3.5}
                  InputProps={{
                    style: {
                      paddingTop: '18px'
                    },
                    endAdornment:
                      canEdit && !readonly ? <IconButton
                        disabled={!isValid || !isValidEdit}
                        onClick={() => dispatchStatusActions().editingDispatchRequester.set(true)}
                        sx={{
                          position: 'absolute',
                          bottom: 20,
                          left: 238,
                          padding: '3px',
                        }}>
                        <EditIcon />
                      </IconButton>
                        : false
                  }}
                />
              </Tooltip>
            </Grid>
          ) : false}


          {dispatchStatusActions().editingDispatchRequester.get() ? (
            <Grid item marginTop={2} width={'100%'}>
              <Grid item >
                <NameField isValid={isValid} name={occurrence.name} />
              </Grid>
              <Grid item marginTop={2.1}>
                <PhoneTextField
                  statePhone={occurrence.phone}
                  disabled={!isValid}
                  onClickButton={() => ('')}
                  disabledLastCalls={true}
                />
              </Grid>
            </Grid>
          ) : false}
        </>
        : false}

    </Grid>
  )
}