import React from 'react'
import { AppRouteProps } from '~/routes'
import { useParams } from 'react-router-dom'
import Index from './pages/AuditQualifiers'
import AuditQualifiers from './pages/AuditQualifiers'
import AuditInterestPoint from './pages/AuditInterestPoint'



export default function SearchLogsRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/audit-qualifiers',
      component: AuditQualifiers,
      isProtected: true,
    },
    {
      path: '/audit-interest-point',
      component: AuditInterestPoint,
      isProtected: true,
    },
  ]

  return routes
}
