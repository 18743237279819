import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { BarChart } from '@mui/x-charts/BarChart'
import { AxisConfig, ChartsYAxisProps } from '@mui/x-charts'
import { AxisScaleConfig, MakeOptional } from '@mui/x-charts/internals'
import { truncate } from '~/features/Dispatch/utils/stringsManipulations'

export default function ColumnChartHorizontal({
  data,
  title,
  height = 800,
  width = 0,
  subtitle = '',
  sxFont = {},
  colorBar = 'primary',
  typeTotal = '',
  
}) {
  const { translate } = useLang()

  const getColor = {
    'primary': '#6395FA',
    'secondary': '#59BD8E',
    'tertiary': '#EDAE49'

  }

  const yFieldTreatament = data.map((element) => {
    return String(element.item)
  })

  const dataTreatament = data.map((element) => {
    return element.value
  })

  return (
    <Card variant="outlined" sx={{ height: 500, overflowY: 'auto' }}>
      <CardContent sx={{ height: '80%'}}>
          <Typography sx={{ fontWeight: 'bold', ...sxFont }}>
            {translate(title)}
          </Typography>
          {subtitle && 
        <Typography variant='subtitle2' marginBottom={2} marginTop={1} >
          {translate(subtitle)} {typeTotal && `| ${translate('Total of')} ${translate(typeTotal)}: ${data.length}`}
        </Typography>
        }

        {data.length ? (
          <Grid xs={11}>
            <BarChart
              series={[{ data: dataTreatament }]}
              yAxis={[
                {
                  scaleType: 'band',
                  data: yFieldTreatament,
                  categoryGapRatio: 0.5,
                  tickPlacement: 'middle',
                  tickLabelPlacement: 'tick',
                  valueFormatter: (value, context) =>
                    context.location === 'tick'
                      ? truncate(String(value), 30)
                      : value,
                  tickLabelStyle: {
                    fontSize: 14,
                  },
                  colorMap: {
                    type: 'piecewise',
                    thresholds: [0],
                    colors: [getColor[colorBar]],
                  },
                } as unknown as MakeOptional<
                  AxisConfig<keyof AxisScaleConfig, number, ChartsYAxisProps>,
                  'id'
                >,
              ]}
              xAxis={[
                {
                  id: 'linearAxis',
                  scaleType: 'linear',
                  tickMinStep: 1,
                  position: 'top',
                },
              ]}
              height={
                yFieldTreatament.length *
                (yFieldTreatament.length == 1
                  ? 150
                  : yFieldTreatament.length >= 5
                  ? 50
                  : 80)
              }
              skipAnimation
              width={850}
              layout="horizontal"
              grid={{ vertical: true }}
              bottomAxis={yFieldTreatament.length >= 5 ? 'linearAxis' : null}
              topAxis={'linearAxis'}
              margin={{ left: 310, top: 25 }}
              barLabel={(item) => {
                // eslint-disable-next-line new-cap
                return Intl.NumberFormat('pt-BR').format(Number(item.value))
              }}
              leftAxis={{ labelStyle: { fontSize: 10 } }}
              // eslint-disable-next-line id-length
              slotProps={{ barLabel: { x: 25, style: { fontSize: 16 } } }}
              sx={{}}
            />
          </Grid>
        ) : (
          <Grid item sx={{height: '100%'}}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
            >
              <CardContent>
                <Typography>{translate('No data available')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
