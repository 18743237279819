import React, { useEffect } from 'react';
import { Grid, IconButton, TextField, Fab } from '@mui/material';
import { t as translate } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useHookstate } from '@hookstate/core';
import { authFetch } from '~/services/fetch';
import TypeSelectField from '~/features/Entry/components/TypeSelectField';
import SubtypeSelectField from '~/features/Entry/components/SubtypeSelectField';
import { LocalityTextField, LocationState } from "~/components/LocalityTextField";
import ReferenceField from '~/features/Entry/components/ReferenceField';
import CitySelectField from '~/features/Entry/components/CitySelectField';
import DistrictSelectField from '~/features/Entry/components/DistrictSelectField';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useSystemState from '~/features/System/stores/SystemState';
import { getOccurrenceById } from '../../services';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';
import FilterAutocompleteDistrict from '~/components/FilterSelectAutocompleteDistrict';


export default function EventInputContainerOccurrence({
  readonly = false,
  isValid,
  isValidEdit,
  isInvalidRequest,
  id,
  lat,
  lon,
  type,
  location,
  cityId,
  districtId,
  typeId,
  typeName,
  typeCode,
  subtypeId,
  subTypeName,
  subtypeCode,
  reference,
  canEdit = true,
  totalSubtypes,
  districtName,
  cityName,
  totalDistricts
}) {
  const { occurrenceStatusActions } = useOccurrenceState();
  const { systemConfig } = useSystemState();

  // && (systemConfig().allowedEditTypeSubtypeOfOccurrence.get() === '1' || systemConfig().allowedEditAddressOfOccurrence.get() === '1')
  const city = useHookstate('');
  const district = useHookstate('');

  const locationState: LocationState = {
    id,
    cityId,
    districtId,
    latitude: lat,
    longitude: lon,
    locationValue: location,
    type
  }

  return (
    <Grid container rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
      <Grid container columnSpacing={1} sx={{ flexDirection: 'row', mb: 0.5, mt: 1, alignItems: 'center' }}>
        <Grid item xs md>

          {!occurrenceStatusActions().editingOccurrence.get() ? (
            <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
              <Grid item>
                <TextField
                  disabled={true}
                  placeholder={translate('Address')}
                  value={`${translate('Type')}: ${typeName?.get() ? typeCode?.get() + ` - ${typeName?.get()}` : ''}\n${translate('Subtype')}: ${subTypeName?.get() ? subtypeCode?.get() + ` - ${subTypeName?.get()}` : ''}\n\n${translate('Localização')}: ${locationState?.locationValue?.get() ?? ''}\n${translate('Reference')}: ${reference?.get() ?? ''}\n${translate('City')}: ${cityName.get() ?? ''}\n${translate('District')}: ${districtName.get() ?? ''}`}
                  size='small'
                  fullWidth
                  multiline
                  rows={12}
                  InputProps={{
                    endAdornment:
                      canEdit && !readonly ? <IconButton
                        onClick={() => occurrenceStatusActions().editingOccurrence.set(true)}
                        sx={{
                          position: 'absolute',
                          bottom: 25,
                          right: 25,
                          padding: '3px',
                        }}>
                        <EditIcon />
                      </IconButton>
                        : false
                  }}
                />
              </Grid>
            </Grid>
          ) : false}

          {/* / */}
          {occurrenceStatusActions().editingOccurrence.get() ? (
            <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
              <Grid item>
                <TypeSelectField readonly={systemConfig().allowedEditTypeSubtypeOfOccurrence.get() === '0'} isValid={isValid} isInvalidRequest={typeId.get() === null} typeId={typeId} typeName={typeName} />
              </Grid>
              <Grid item>
                <SubtypeSelectField readonly={systemConfig().allowedEditTypeSubtypeOfOccurrence.get() === '0'} isValid={isValid} isInvalidRequest={totalSubtypes.get() > 0 ? totalSubtypes.get() > 0 && subtypeId.get() === null : false} typeId={typeId} subtypeId={subtypeId} subTypeName={subTypeName} subTypeCode={subtypeCode} getTotalSubtypes={(value) => {
                  if (totalSubtypes) {
                    return totalSubtypes.set(value ?? 0);
                  }
                  return 0;
                }}
                 />
              </Grid>

              <Grid item>
                <LocalityTextField readonly={systemConfig().allowedEditAddressOfOccurrence.get() === '0'} isValid={isValid} isInvalidRequest={isInvalidRequest} locationState={locationState}
                />
              </Grid>
              <Grid item>
                <ReferenceField readonly={systemConfig().allowedEditAddressOfOccurrence.get() === '0'} isValid={isValid} reference={reference}
                />
              </Grid>
              <Grid item>
                <FilterAutocompleteCity
                  disabled={!isValid}
                  readonly={systemConfig().allowedEditAddressOfOccurrence.get() === '0'}
                  onCityChange={(city) => {
                    cityId?.set(city)
                  }}
                  cityId={cityId?.get()}
                />
                {/* <CitySelectField readonly={systemConfig().allowedEditAddressOfOccurrence.get() === '0'} isValid={isValid} isInvalidRequest={isInvalidRequest} cityId={cityId} /> */}
              </Grid>
              <Grid item>
                <FilterAutocompleteDistrict
                  disabled={!isValid}
                  onDistrictChange={(district) => {
                    districtId?.set(district)
                  }}
                  districtId={districtId}
                  cityId={cityId.get()}
                  getRowsCount={(value) => totalDistricts.set(value)}
                  isInvalidRequest={totalDistricts.get() > 0 ? totalDistricts.get() > 0 && districtId.get() === null : false}
                />
                {/* <DistrictSelectField isValid={isValid} cityId={cityId} districtId={districtId} /> */}
              </Grid>
            </Grid>
          ) : false}
          {/* / */}

        </Grid>
      </Grid>

    </Grid>
  );
}
