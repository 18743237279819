import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import ColumnChartVertical from '~/components/ColumnChartMui/VerticalChart'
import { getDispatchByHour, getDispatchByWeekday } from './services'
import LineChartMui from '~/components/LineChartMui'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DispatchByTimeCharts({
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  loading,
  statusIds,
  cityIds,
  groupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const hourData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const weekdayData = useHookstate<ResponseProps>([])
  const weekdayDataTotal = useHookstate<number>(0)
  const hourDataTotal = useHookstate<number>(0)

  useEffect(() => {
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    hourDataTotal.set(0)
    weekdayDataTotal.set(0)
    loading.set(true)

    getDispatchByHour(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload.get(),
      consummated,
      statusIds,
      cityIds,
      groupSetId,
      location,
      operationUnitId,
      closedStatusClassId,
      closedStatusId,
      qualifierOptions
    )
      .then((response) => {
        const result = response.map((responseData) => {
          hourDataTotal.set(hourDataTotal.get() + responseData.total)
          return { item: responseData.range, value: responseData.total }
        })
        return hourData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })

      getDispatchByWeekday(
        agencyId,
        deviceId,
        operationId,
        dispatchGroupId,
        category,
        type,
        subtype,
        dateStart,
        dateFinish,
        payload.get(),
        consummated,
        statusIds,
        cityIds,
        groupSetId,
        location,
        operationUnitId,
        closedStatusClassId,
        closedStatusId,
        qualifierOptions
      )
        .then((response) => {
          const result = response.map((responseData) => {
            weekdayDataTotal.set(weekdayDataTotal.get() + responseData.total)
            return { item: translate(`${responseData.weekday}`.toLowerCase()), value: responseData.total }
          })
          return weekdayData.set(result)
        })
        .finally(() => {
          loading.set(false)
        })
  }, [refresh])

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} hidden={!expandFilterSearch} paddingBottom={1}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch by Time Period')}
        </Typography>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={12} hidden={!expandFilterSearch}>
          <LineChartMui
            data={hourData.attach(Downgraded).get()}
            title={'Dispatch x Hour Interval'}
            subtitle={`Total: ${hourDataTotal.get()}`}
            // width={900}
            colorBar='secondary'
            // valueFormatter={hourFormatter}
          />
        </Grid>
        <Grid item xs={12} hidden={!expandFilterSearch} paddingTop={2}>
          <ColumnChartVertical
            data={weekdayData.attach(Downgraded).get()}
            title={'Dispatch x Weekday'}
            subtitle={`Total: ${weekdayDataTotal.get()}`}
            colorBar='tertiary'
            width={1700}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
