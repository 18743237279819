import { useHookstate } from "@hookstate/core"

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function convertMsToTime(milliseconds, withSeconds) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60

  return withSeconds
    ? `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    : `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

export function convertMsToTimeFriendly(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60) >= 0 ? Math.floor(seconds / 60) : 0
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (minutes < 60) {
    return `${minutes} ${'min'}`
  } else {
    if (hours < 24) return `${hours} ${'hrs'}`
    else return `${days} ${'dias'}`
  }
}

export function elapsedTime(initialDateTime: string) {
  const initialDate = new Date(initialDateTime)
  const currentDate = new Date()
  return convertMsToTimeFriendly(currentDate.getTime() - initialDate.getTime())
}

export function elapsedTimeWithDate(initialDateTime: Date) {
  const initialDate = new Date(initialDateTime)
  const currentDate = new Date()
  return convertMsToTimeFriendly(currentDate.getTime() - initialDate.getTime())
}

export function elapsedTimeInMinutes(initialDateTime: Date) {
  const initialDate = new Date(initialDateTime)
  const currentDate = new Date()
  const milliseconds = currentDate.getTime() - initialDate.getTime()

  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)

  return minutes
}

export function dateTimeFormat(date: string | undefined) {
  if (typeof date == 'string') {
    const newDate = new Date(date)
    return `${padTo2Digits(newDate.getDate())}/${padTo2Digits(
      newDate.getMonth() + 1
    )}/${newDate.getFullYear()} ${padTo2Digits(
      newDate.getHours()
    )}:${padTo2Digits(newDate.getMinutes())}`
  } else {
    return ''
  }
}

export function dateFormat(date: string | undefined) {
  if (typeof date == 'string') {
    const newDate = new Date(date)
    return `${padTo2Digits(newDate.getDate())}/${padTo2Digits(
      newDate.getMonth() + 1
    )}/${newDate.getFullYear()}`
  } else {
    return ''
  }
}

export function dateTimeFormatServer(date: string | undefined) {
  if (typeof date == 'string') {
    const newDate = new Date(date)
    return `${newDate.getFullYear()}-${padTo2Digits(
      newDate.getMonth() + 1
    )}-${padTo2Digits(newDate.getDate())} ${padTo2Digits(
      newDate.getHours()
    )}:${padTo2Digits(newDate.getMinutes())}:${padTo2Digits(
      newDate.getMilliseconds()
    )}`
  } else {
    return ''
  }
}

export function dateFormatNoTimeZone(date: string | undefined) {
  if (typeof date == 'string') {
    const dateSplit = date.split('-')
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
  } else {
    return ''
  }
}

export function convertSecondsToTime(value: number) {
  let seconds = value
  const days = Math.floor(seconds / (24 * 3600))
  seconds -= days*24*3600
  const hours = Math.floor(seconds / 3600)
  seconds -= hours*3600
  const minutes = Math.floor(seconds/ 60)
  seconds -= minutes*60

  const timeString =
    days > 0
      ? `${days}d ${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  return timeString
}
