import { func } from "prop-types";
import { authFetch } from "~/services/fetch"

export async function makeRequestToGetReportInfoDetailedByType({ 
    startDate, 
    finishDate, 
    typeId, 
    categoryId,
    dispatchGroupsId,
    agencyIds,
    withSolvedThirdParty, 
    exportCSV = false,
    cityId,
    entryOriginId,
    groupSetId,
    originTypeId,
    operationId,
}) {
    try {
        const response = await authFetch({
            url: '/report/occurrence-detail-by-type',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                type_id: typeId,
                category_id: categoryId,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                with_solved_third_party: withSolvedThirdParty,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId,
                origin_type_id: originTypeId,
                operation_id: operationId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetDevicesByDispatchGroupAndAgency({ 
    agencyId, 
    dispatchGroupId, 
    exportCSV = false, 
    groupSetId
}) {
    try {
        const response = await authFetch({
            url: `/report/devices`,
            method: 'POST',
            data: {
                agency_ids: agencyId,
                dispatch_group_ids: dispatchGroupId,
                export_csv: exportCSV,
                group_set_id: groupSetId
                
            }
        });

        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetConnectionLogs({ 
    startDate, 
    finishDate, 
    deviceIds, 
    detailedReport,
    dispatchGroupsId,
    agencyIds,
    exportCSV = false,
    keyWords,
    userId,
    groupSetId
}) {
    try {
        const response = await authFetch({
            url: `/report/connection-logs`,
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                export_csv: exportCSV,
                device_ids: deviceIds,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                detailed_report: detailedReport,
                key_words: keyWords,
                user_id: userId,
                group_set_id: groupSetId
            }
        });

        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}


export async function makeRequestToGetChronologyByOccurrence({ 
    dispatchId,
    dispatchGroupsId,
    agencyIds,
    exportCSV = false
}) {
    try {
        const response = await authFetch({
            url: '/report/dispatch-timeline',
            method: 'POST',
            data: {
                dispatch_id: dispatchId,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                // with_all_agencies: false,
                export_csv: exportCSV,
            }
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export async function makeRequestToGetReportTypesOccurrencesDetailed({ 
    startDate, 
    finishDate, 
    typeIds, 
    dispatchGroupsId, 
    groupByLocation, 
    simpleReport, 
    agencyIds, 
    categoryId, 
    withSolvedThirdParty, 
    subtypeIds, 
    exportCSV = false, 
    cityId,
    entryOriginId,
    closedStatusId,
    groupSetId,
    operationId,
}) {
    try {
        const response = await authFetch({
            url: '/report/occurrences',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                type_ids: typeIds,
                dispatch_group_ids: dispatchGroupsId,
                group_by_location: groupByLocation,
                simple_report: simpleReport,
                agency_ids: agencyIds,
                category_id: categoryId,
                with_solved_third_party: withSolvedThirdParty,
                subtype_ids: subtypeIds,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                closed_status_id: closedStatusId,
                group_set_id: groupSetId,
                operation_id: operationId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetVehiclesInOccurrence({ startDate, 
    finishDate, 
    deviceId, 
    withSolvedThirdParty, 
    dispatchGroupsIds, 
    agencyIds, 
    exportCSV = false,
    cityId,
    entryOriginId,
    typeIds,
    subtypeIds,
    groupSetId,
    operationId,
}) {
    try {
        const response = await authFetch({
            url: '/report/dispatches-by-device',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                device_id: deviceId,
                with_solved_third_party: withSolvedThirdParty,
                dispatch_group_ids: dispatchGroupsIds,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                type_ids: typeIds,
                subtype_ids: subtypeIds,
                group_set_id: groupSetId,
                operation_id: operationId,
            },
        });
        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetOccurrenceConsultation({
    dispatchId,
    startDate,
    finishDate,
    dispatchGroupsId,
    typeIds,
    subtypeIds,
    agencyIds,
    exportCSV = false,
    phone,
    entryOriginId,
    cityId,
    groupSetId,
    operationId
}) {
    try {
        const response = await authFetch({
            url: '/report/occurrence-consultation',
            method: 'POST',
            data: {
                dispatch_id: dispatchId,
                start_date: startDate,
                finish_date: finishDate, 
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                type_ids: typeIds,
                subtype_ids: subtypeIds,
                export_csv: exportCSV,
                phone: phone,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId,
                operation_id: operationId
            }
        });

        return response.data;
    } catch (error) {
        throw error
    }
}

export async function makeRequestToGetReportOccurrencesHandledByGroup({ 
    startDate, 
    finishDate, 
    dispatchGroupsId, 
    agencyIds, 
    categoryId, 
    typeIds, 
    withSolvedThirdParty, 
    exportCSV = false,
    cityId,
    entryOriginId,
    groupSetId,
    operationId,
}) {
    try {
        const response = await authFetch({
            url: '/report/occurrences-handled-by-group',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                type_ids: typeIds,
                category_id: categoryId,
                with_solved_third_party: withSolvedThirdParty,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId,
                operation_id: operationId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReporDevicesByStatus({ 
    momentDate, 
    dispatchGroupsId, 
    agencyIds, 
    deviceIds, 
    exportCSV = false,
    groupSetId
}) {
    try {
        const response = await authFetch({
            url: '/report/devices-by-status',
            method: 'POST',
            data: {
                date_time: momentDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                device_ids: deviceIds,
                group_set_id: groupSetId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReportServiceDevices({ 
    startDate, 
    finishDate, 
    dispatchGroupsId, 
    agencyIds, 
    withSolvedThirdParty, 
    exportCSV = false,
    groupSetId
 }) {
    try {
        const response = await authFetch({
            url: '/report/device-services',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                with_solved_third_party: withSolvedThirdParty,
                export_csv: exportCSV,
                group_set_id: groupSetId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReportDispatchesStatusByDate({ 
    momentDate, 
    agencyIds, 
    dispatchGroupsIds, 
    categoryId, 
    typeIds, 
    exportCSV = false,
    cityId,
    entryOriginId,
    groupSetId,
}) {
    try {
        const response = await authFetch({
            url: '/report/dispatches-status-by-date',
            method: 'POST',
            data: {
                date_time: momentDate,
                agency_ids: agencyIds,
                dispatch_group_ids: dispatchGroupsIds,
                category_id: categoryId,
                type_ids: typeIds,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReportSearchMultipleComents({ 
    keyWords, 
    includeAllWords, 
    agencyIds, 
    dispatchGroupsId, 
    startDate, 
    finishDate, 
    withSolvedThirdParty, 
    location,
    cityId,
    entryOriginId,
    groupSetId,
    operationId,
}) {
    try {
        const response = await authFetch({
            url: '/report/search-comments',
            method: 'POST',
            data: {
                key_words: keyWords,
                include_all_words: includeAllWords,
                start_date: startDate,
                finish_date: finishDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                with_solved_third_party: withSolvedThirdParty,
                location: location,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId,
                operation_id: operationId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReportTotalOccurrencesByClosingStatus({ 
    startDate, 
    finishDate, 
    dispatchGroupsId, 
    agencyIds, 
    categoryId, 
    typeIds, 
    withSolvedThirdParty, 
    exportCSV = false,
    cityId,
    entryOriginId,
    groupSetId,
    operationId,
 }) {
    try {
        const response = await authFetch({
            url: '/report/dispatch-by-closed-status',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                type_ids: typeIds,
                category_id: categoryId,
                with_solved_third_party: withSolvedThirdParty,
                export_csv: exportCSV,
                city_id: cityId,
                entry_origin_id: entryOriginId,
                group_set_id: groupSetId,
                operation_id: operationId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReporDeviceVSDispatches({ momentDate, dispatchGroupsId, agencyIds, exportCSV = false, groupSetId }) {
    try {
        const response = await authFetch({
            url: '/report/devices-vs-dispatches',
            method: 'POST',
            data: {
                date_time: momentDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                group_set_id: groupSetId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestToGetReporTotalConnectedDevices({ momentDate, dispatchGroupsId, agencyIds, withOperationUnit, exportCSV = false, groupSetId }) {
    try {
        const response = await authFetch({
            url: '/report/connected-devices',
            method: 'POST',
            data: {
                date_time: momentDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                with_operation_unit: withOperationUnit,
                group_set_id: groupSetId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export async function makeRequestTogetReportApproachesByUser({ startDate, finishDate, agencyIds, dispatchGroupsId, userId, plate, cpf, exportCSV = false }) {
    try {
        const response = await authFetch({
            url: '/report/approaches-by-user',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                agency_ids: agencyIds,
                dispatch_group_ids: dispatchGroupsId,
                user_id: userId,
                plate: plate,
                cpf: cpf,
                export_csv: exportCSV
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}

export const downloadReportCSV = (filename: string, content: string) => {
    const element = document.createElement('a');

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export async function makeRequestToGetDeviceTimeline({ 
    startDate, 
    finishDate, 
    deviceId,
    dispatchGroupsId,
    agencyIds,
    exportCSV = false,
    groupSetId,
}) {
    try {
        const response = await authFetch({
            url: `/report/device-timeline`,
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                export_csv: exportCSV,
                device_id: deviceId,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                group_set_id: groupSetId,
            }
        });

        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}