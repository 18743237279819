import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate, type State } from '@hookstate/core';
import { getAllSubTypesByType } from '~/features/Subtype/services';
import ISubtype from '~/features/Subtype/interfaces/ISubtype';

interface FilterAutoCompleteSubTypeProps {
  readonly onSubtypeChange: (value: ISubtype | null) => void;
  readonly typeId?: number | null;
  readonly subtypeId?: number | null;
  readonly hasType?: boolean;
  readonly hideCode?: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly getRowsCount?: (value: number) => number;
  readonly isEnabled?: State<boolean>
}

export default function FilterAutocompleteSubtypeByType({ disabled, onSubtypeChange, hideCode, typeId, subtypeId, hasType = false, error, isEnabled, getRowsCount }: FilterAutoCompleteSubTypeProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly ISubtype[]>([]);
  const [value, setValue] = useState<ISubtype | null>(null);
  const loading = useHookstate(false);

  useEffect(() => {
    if (!hasType || !options.length || disabled) {
      isEnabled?.set(false)
    } else {
      isEnabled?.set(true)
    }
  }, [options.length])


  useEffect(() => {
    if (typeId) {

      getAllSubTypesByType(typeId).then(subtypes => {
        const resSubtypes = subtypes.filter((subtypesActives) => subtypesActives.active).map(subtype => ({
          ...subtype,
          displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
        }));

        setOptions(resSubtypes);

        if (getRowsCount) {
          getRowsCount(resSubtypes.length ?? 0);
        }

        if (subtypeId) {
          const option = resSubtypes.find((option) => option.id == subtypeId);
          setValue(option ?? null);
          onSubtypeChange(option ?? null);

        } else {
          setValue(null);
          onSubtypeChange(null);

        }
      })
    } else {
      if (getRowsCount) {
        getRowsCount(0);
      }
      setOptions([]);
      setValue(null);
      onSubtypeChange(null);
    }
  }, [typeId])

  useEffect(() => {
    if (subtypeId) {
      const option = options.find((option) => option.id == subtypeId);
      setValue(option ?? null);

      if (getRowsCount) {
        getRowsCount(options.length ?? 0);
      }
    } else {
      setValue(null);
      onSubtypeChange(null);
    }
  }, [subtypeId])

  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-subtypes'
        disabled={!hasType || !options.length || disabled}
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          setValue(subtype ?? null);
          onSubtypeChange(subtype ?? null);

        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Subtype')}
          />
        )}
      />
    </FormControl>
  )
}