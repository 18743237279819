import React, { ChangeEvent } from 'react'
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { State, useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import CancelIcon from '@mui/icons-material/Cancel'
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteType from '~/components/FilterAutocompleteType'
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType'
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import FilterAutocompleteCategory from '~/components/FilterAutocompleteCategory'
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation'
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups'
import FilterAutocompleteStatus from '~/components/FilterAutoCompleteStatus'
import SelectConsummatedAndAttempted from '~/components/FilterSelectConsummatedAndAttempted'
import useSystemState from '~/features/System/stores/SystemState'
import { LoadingButton } from '@mui/lab'
import FilterSwitch from '~/components/FilterSwitch'
import userUserState from '~/features/User/stores/UserState'
import InputMask from 'react-input-mask'
import { getOnlyNumbersInString } from '~/utils/strings'
import { DispatchSearchBox } from '~/components/DispatchSearchBox'
import InputOccurrenceNumber from '~/components/InputOccurrenceNumber'
import { OptionPeriodProps } from '~/components/FilterAutocompletePeriod/interface'
import FilterAutocompleteClosedStatusClass from '~/components/FilterAutocompleteClosedStatusClass'
import FilterAutocompleteClosedStatus from '~/components/FilterAutocompleteClosedStatus'
import FilterCheckboxQualifiers from '~/components/FilterCheckboxQualifiers'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit'
import FilterAutocompleteTypeWithCategory from '~/components/FilterAutocompleteTypeWithCategory'
import FilterAutoCompleteSubtypeWithCategory from '~/components/FilterAutocompleteSubtypeWithCategory'
import FilterKeyword from '~/features/Report/components/FilterKeyword'

interface ListAdvancedFiltersProps {
  readonly loadingButton: State<boolean>
  readonly lockFields: boolean
  readonly hidden?: boolean
  readonly disabledSendButton: () => boolean
  readonly code?: State<string | null>
  readonly labelButton?: State<string>
  readonly occurrenceNumber?: State<string | null>
  readonly initialPeriod: State<string>
  readonly location?: State<string | null>
  readonly cityId?: State<number | null>
  readonly groupId?: State<readonly number[] | null>
  readonly deviceId?: State<number | null>
  readonly typeId: State<number | null>
  readonly subtypeId?: State<number | null>
  readonly refTypeId?: State<number | null>
  readonly categoryId: State<number | null>
  readonly agencyId?: State<number | null>
  readonly operationId?: State<number | null>
  readonly withSolvedThirdParty: State<boolean>
  readonly statusId: State<number | null>
  readonly consummatedAndAttempted: State<string | null>
  readonly onlyToClerk: State<boolean>
  readonly requester: State<string | null>
  readonly phone: State<string | null>
  readonly dispatchId?: State<number | null>
  readonly closedStatusClassId?: State<number | null>
  readonly closedStatusId?: State<number | null>
  readonly qualifiersSelected?: State<number[]>
  readonly clearState?: State<boolean>
  readonly comments?: State<string>
  readonly includeAllWords: State<boolean>

  readonly searchButton: () => void
  readonly clearFilters: () => void
  readonly onStartDateChange: (value: string | null) => void
  readonly onFinishDateChange: (value: string | null) => void
  readonly onPeriodChange: (value: string) => void

  readonly groupSetId?: State<number | null>
  readonly operationUnitId?: State<number | null>
}

// eslint-disable-next-line max-lines-per-function
export default function ListAdvancedFilters({
  loadingButton,
  hidden,
  code,
  lockFields,
  labelButton,
  initialPeriod,
  location,
  cityId,
  groupId,
  deviceId,
  typeId,
  categoryId,
  refTypeId,
  subtypeId,
  agencyId,
  operationId,
  withSolvedThirdParty,
  statusId,
  consummatedAndAttempted,
  onlyToClerk,
  comments,
  onFinishDateChange,
  onPeriodChange,
  onStartDateChange,
  clearFilters,
  disabledSendButton,
  searchButton,
  requester,
  phone,
  dispatchId,
  occurrenceNumber,
  closedStatusClassId,
  closedStatusId,
  qualifiersSelected,
  clearState,
  groupSetId,
  operationUnitId,
  includeAllWords
}: ListAdvancedFiltersProps) {
  const { translate } = useLang()
  const { systemConfig } = useSystemState()
  const { verifyPermission } = userUserState()

  const maskCelPhone = '(99) 99999-9999'
  const maskPhone = '(99) 9999-9999'
  const mask = useHookstate(maskCelPhone)
  const phoneValue = phone.get() == null ? '' : `${phone.get()}`
  const isOpen = useHookstate(true)
  const closedStatusClassLock = lockFields
    ? lockFields
    : statusId.get() != null && statusId.get() != 8

  const periodOptions: OptionPeriodProps[] = [
    { id: 0, number: 6, type: 'hour', name: translate('Last 6 hours') },
    { id: 1, number: 24, type: 'hour', name: translate('Last 24 hours') },
    { id: 2, number: 48, type: 'hour', name: translate('Last 48 hours') },
    { id: 3, number: 7, type: 'day', name: translate('Last 7 days') },
    { id: 4, number: 1, type: 'month', name: translate('Last month') },
  ]

  React.useEffect(() => {
    if (phoneValue.length <= 9) {
      mask.set(maskCelPhone)
    }
  }, [phoneValue])

  const defineMask = (number) => {
    if (number <= 10) {
      mask.set(maskPhone)
    } else {
      mask.set(maskCelPhone)
    }
  }

  return (
    <Collapse in={hidden}>
      <Grid
        container
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <InputOccurrenceNumber
              occurrence={occurrenceNumber}
              onOccurrenceChange={(event: ChangeEvent<HTMLInputElement>) => {
                occurrenceNumber?.set(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <DispatchSearchBox
              isOpen={isOpen}
              isValid={true}
              notOnlyVisibles={true}
              dispatchId={dispatchId}
              dispatchValue={code}
              isReport={true}
              size="small"
              placeholder="Nº Occurrence"
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgency
              agencyId={agencyId?.get()}
              onAgencyChange={(agency) => agencyId?.set(agency)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchGroupSet
              disabled={lockFields}
              dispatchGroupSetId={groupSetId?.get() ?? undefined}
              onGroupDispatchSetChange={(group) => {
                groupSetId?.set(group)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchsGroups
              disabled={lockFields}
              agencyId={agencyId?.get() ?? undefined}
              dispatcheGroups={groupId?.get() ?? undefined}
              onGroupDispatchChange={(group) => {
                groupId?.set(group)
                deviceId?.set(null)
                labelButton?.set(translate('Search'))
              }}
              groupSetId={groupSetId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCategory
              disabled={lockFields}
              categoryId={categoryId?.get()}
              onCategoryChange={(category) => {
                categoryId?.set(category)
                labelButton?.set(translate('Search'))
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteTypeWithCategory
              disabled={lockFields}
              categoryId={categoryId?.get()}
              typeId={typeId?.get()}
              refTypeId={refTypeId}
              onTypeChange={(type) => typeId?.set(Number(type?.id) ?? null)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutoCompleteSubtypeWithCategory
              typeId={typeId?.get()}
              categoryId={categoryId?.get()}
              refTypeId={refTypeId}
              onSubtypeChange={(subtype) => subtypeId?.set(subtype?.id ?? null)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCity
              disabled={lockFields}
              onCityChange={(city) => {
                cityId?.set(city)
              }}
              cityId={cityId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <OutlinedInput
              fullWidth
              id="outlined-search"
              value={location?.get() ?? null}
              size="small"
              placeholder={translate('Location')}
              onChange={(event) => {
                location?.set(event.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle clear search location"
                    onClick={() => {
                      location?.set('')
                    }}
                    disabled={location?.get()?.length ? false : true}
                    edge="end"
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperation
              disabled={lockFields}
              operationActive={false}
              onOperationChange={(operation) => {
                operationId?.set(operation)
              }}
              operationId={operationId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperationUnit
              disabled={lockFields}
              onOperationUnitChange={(operationUnit) => {
                operationUnitId?.set(operationUnit)
                deviceId?.set(null)
              }}
              operationUnitId={operationUnitId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDevice
              onDeviceChange={(device) => {
                deviceId?.set(device)
              }}
              deviceId={deviceId?.get()}
              disabled={lockFields}
              operationUnitId={operationUnitId?.get()}
              groupDispatchs={groupId?.get()}
            />
          </Grid>

          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <TextField
              fullWidth
              disabled={lockFields}
              label={translate('Requester')}
              size="small"
              value={requester?.get() ?? ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                requester?.set(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <InputMask
              mask={mask.get()}
              value={phoneValue}
              onBlur={() => defineMask(phoneValue.length)}
              maskChar=""
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                phone.set(getOnlyNumbersInString(changeEvent.target.value))
              }}
            >
              <TextField
                fullWidth
                label={translate('Requester Phone')}
                size="small"
                value={phone.get() ?? ''}
              />
            </InputMask>
          </Grid>
          <FilterSelectPeriod
            disabled={lockFields}
            onStartDateChange={(value) =>
              onStartDateChange(value?.toJSON() ?? null)
            }
            onFinishDateChange={(value) =>
              onFinishDateChange(value?.toJSON() ?? null)
            }
            onPeriodChange={onPeriodChange}
            period={initialPeriod.get()}
            periodOptions={periodOptions}
            sxPeriod={{ width: '50%', pt: 1, pl: 1 }}
            sxInitialDate={{ pl: 1, pt: 1, pr: 0 }}

          />
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteStatus
              disabled={lockFields}
              onStatusChange={(status) => {
                statusId?.set(status)
                closedStatusClassId?.set(null)
              }}
              statusId={statusId.get()}
            />
          </Grid>

          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteClosedStatusClass
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              onClosedStatusClassChange={(closedStatusClass) => {
                closedStatusClassId?.set(closedStatusClass)
                closedStatusId?.set(null)
                labelButton?.set(translate('Search'))
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteClosedStatus
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              closedStatusId={closedStatusId?.get()}
              onClosedStatusIdChange={(closedStatus) => {
                closedStatusId?.set(closedStatus)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1, pr: 0.2 }}>
            <FilterCheckboxQualifiers
              qualifiersSelected={qualifiersSelected}
              clearState={clearState?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterKeyword keyWords={comments} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 2, pt: 1 }}>
              <FilterSwitch
                label={translate('Contain all keywords')}
                onCheckedChange={() => {
                  includeAllWords?.set(!includeAllWords.value)
                }}
                disabled={lockFields}
              checked={includeAllWords?.get()}
              tooltip={'All words must exist in the comment'}
              />
          </Grid>
          {systemConfig().displayConsummatedFilterInDashboardAndList.get() ===
            '1' ? (
            <Grid item xs={2} sx={{ pl: 1, pt: 1, width: '90%' }}>
              <SelectConsummatedAndAttempted
                disabled={lockFields}
                valueDefault={consummatedAndAttempted.get()}
                onReportConsummatedAndAttempetdChange={(type) => {
                  consummatedAndAttempted.set(type)
                }}
              />
            </Grid>
          ) : (
            false
          )}

          <Grid
            item
            xs={4}
            sx={{ pl: 2, mt: -4, display: 'flex', alignItems: 'center' }}
          >
            <FilterSwitchOtherDispatchsGroup
              onCheckedChange={() => {
                labelButton?.set(translate('Search'))
                withSolvedThirdParty?.set(!withSolvedThirdParty?.value)
              }}
              disabled={lockFields}
              checked={withSolvedThirdParty.get()}
            />
          </Grid>
          {verifyPermission(['occurrence_clerk_index']) && (
            <Grid
              item
              xs={4}
              sx={{pl: 2, mt: -4, display: 'flex', alignItems: 'center'}}
            >
              <FilterSwitch
                label="Show only occurrences marked for clerk"
                onCheckedChange={() => {
                  labelButton?.set(translate('Search'))
                  onlyToClerk?.set(!onlyToClerk?.value)
                }}
                disabled={lockFields}
                checked={onlyToClerk.get()}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={clearFilters}>
            {translate('Clear Filters')}
          </Button>
          <LoadingButton
            loading={loadingButton.get()}
            variant="contained"
            disabled={disabledSendButton()}
            onClick={searchButton}
          >
            {labelButton?.get()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Collapse>
  )
}
