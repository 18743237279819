import { authFetch } from "~/services/fetch";

interface getInterestPointSearchLogsProps {
    readonly action?: string | null;
    readonly userId?: number | null;
    readonly startDate?: string | null;
    readonly finishDate?: string | null;
    readonly limit?: number;
    readonly page?: number;
}

export async function getInterestPointSearchLogs({
    action,
    userId,
    startDate,
    finishDate,
    limit = 10000,
    page = 0,
}: getInterestPointSearchLogsProps) {
    const response = await authFetch({
        url: '/interest-point/log',
        method: 'POST',
        data: {
            action: action,
            user_id: userId,
            start_date: startDate,
            finish_date: finishDate,
            limit: limit,
            page: page,
            orders: [{ "field": "created_at", "sort": "desc" }]
        }
    })

    return response.data
}