import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { PhoneCallback } from '@mui/icons-material';
import EntryForm from '../EntryForm';
import StandardModal from '../../../../components/StandardModal';
import { useLang } from '../../../../hooks/useLang';
import useEntryState from '../../stores/EntryState';
import { Validation as validation } from '@hookstate/validation';
import useSystemState from '~/features/System/stores/SystemState';
import { Grid, Typography } from '@mui/material';
import EntryTravelInfo from './Components/EntryTravelInfo';
import EntryModalLocation from './Components/EntryModalLocation';
import { authFetch } from '~/services/fetch';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { storeScreenSettings } from '~/features/System/services';
import type ScreenSettingsDraggableProps from '../../interfaces/IPositionDraggable';
import { useHookstate } from '@hookstate/core';

const modal = document.getElementById('div-modal') as HTMLElement;

type EntryModalProps = {
  phoneFullEvent?: (phone: string) => boolean;
  phonePartialEvent?: (phone: string) => boolean;
};

export default function EntryFormModalDraggable(props: EntryModalProps) {
  const { translate } = useLang();
  const location = useLocation()
  const { windowManager, loadingButton } = useSystemState();
  const { id, latitude, longitude, createdAt } = useEntryState().entry();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    windowManager()['entry'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'EntryFormModalDraggable',
        isOpen: windowManager()['entry'].open.get(),
        isMinimized: windowManager()['entry'].minimized.get(),
        positionX: windowManager()['entry'].positionX.get(),
        positionY: windowManager()['entry'].positionY.get()
      })
    }
  }, [
    windowManager()['entry'].open.get(),
    windowManager()['entry'].minimized.get(),
    windowManager()['entry'].positionY.get(),
    windowManager()['entry'].positionX.get()
  ])

  if (location.pathname === '/entry' || location.pathname === '/dispatch'){

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !loadingButton.get() ?
        <StandardModal
          title={
            <Grid container sx={{ flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>

              <Grid item sx={{ alignSelf: 'center', marginRight: 'auto' }}>
                <Typography>
                  {translate('Entry Log')}
                  {(validation(id).valid()) ? `- ID ${id.get()}` : ''}
                </Typography>
                <Typography variant='subtitle2' color={'red'}>
                  {createdAt.get() ? `${translate('opened in')}: ${dayjs(createdAt.get()).format('DD/MM/YYYY - HH:mm:ss')}` : ''}
                </Typography>
              </Grid>

              <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                <EntryTravelInfo
                  title='Locate on the map'
                  latitude={latitude?.get()}
                  longitude={longitude?.get()}
                />
              </Grid>

              {Boolean(windowManager()['entry'].minimized.get()) &&
                <Grid item sx={{ alignSelf: 'flex-end', display: 'inline-flex', alignItems: 'center', padding: '5px' }}>
                  <EntryModalLocation
                    latitude={latitude?.get()}
                    longitude={longitude?.get()}
                    modalIsExpanded={Boolean(windowManager()['entry'].minimized.get())}
                  />
                </Grid>
              }

            </Grid>
          }
          avatar={PhoneCallback}
          dimensions={{
            width: 1000
          }}
          handleStop={handleStop}
          position={{
            x: windowManager()['entry'].positionX.get() ? Number(windowManager()['entry'].positionX.get()) : 600,
            y: windowManager()['entry'].positionY.get() ? Number(windowManager()['entry'].positionY.get()) : 100
          }}

          isExpanded={windowManager()['entry'].minimized}
        >
          <EntryForm
            readonly={windowManager()['entry'].readonly.value}
            phoneFullEvent={props.phoneFullEvent}
            phonePartialEvent={props.phonePartialEvent}
          />

        </StandardModal> 
        : false
      }
    </>
    , modal)
  } else {
    return <></>
  }
}