import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Card, CardActions, Typography, CardContent, Tooltip, Stack, Grid, Button, Chip, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import PinIcon from '@mui/icons-material/Pin';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { dateTimeFormat, elapsedTime, elapsedTimeInMinutes } from '~/utils/dateTime';
import ButtonActionsPopupDeviceContainer from '~/features/Map/components/ButtonActionsPopupDeviceContainer';
import NewDispatchPopupDeviceDialog from '~/features/Map/components/NewDispatchPopupDeviceDialog';
import ConfirmDialog from '~/components/ConfirmDialog';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { authFetch } from '~/services/fetch';
import notify from '~/utils/notify';
import { useHookstate } from "@hookstate/core";
import IconButtonInfoPopupMarker from '~/features/Map/components/IconButtonInfoPopupMarker';
import useMapState, { useMarkerState } from '~/features/Map/stores/MapState';
import Close from '@mui/icons-material/Close';

// eslint-disable-next-line max-lines-per-function
export default function MarkerPopupContentDevice({ id, status, code, identifier, plate, date, speed, closeThisPopUp, groupId, readonly, agencyName }) {
  const { translate } = useLang();
  const motiveState = useHookstate<any>(null);
  const openReturnToServiceDialog = useHookstate<boolean>(false);
  const openCreateNewDispatchForDeviceDialog = useHookstate<boolean>(false);
  const existMarker = useHookstate<string[]>([])
  const { markers } = useMapState();
  const { forceClosePopup } = useMarkerState();
  const changeColorTime = (elapsedMinutes) => {
    if (elapsedMinutes <= 5) {
      return 'vehicleLastUpdate.online.main';
    } else if (elapsedMinutes > 5 && elapsedMinutes <= 10) {
      return 'vehicleLastUpdate.recent.main';
    } else if (elapsedMinutes > 10) {
      return 'vehicleLastUpdate.offline.main';
    }
  }

  useEffect(() => {
    existMarker.set(Object.keys(markers()['device'].get()).filter((item) => item == String(id)))
    if (!existMarker.get().length) {
      closeThisPopUp()
    }

  }, [forceClosePopup.get()])


  const outServiceTime = useHookstate<string | null>(null);

  const handleReturnToServiceVehicle = () => {
    const deviceReturnToServiceId = id;

    authFetch({
      url: '/device/back-to-service',
      method: 'POST',
      data: {
        'device_id': deviceReturnToServiceId,
      }
    })
      .then(data => {
        notify({ message: translate('Device returned to service sucessfully!'), type: 'success' });
      })
      .catch(err => console.error(err))
      .finally(() => {
        openReturnToServiceDialog.set(false);
      });
  };
  return (
    <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
      <CardContent sx={{ padding: '1em 0 1em 0' }}>
        <Stack direction='row' alignItems='center'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Tooltip title={translate('Vehicle')} placement='left'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CarCrashIcon sx={{ mr: '10px' }} />
                <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>
                  {markers()['device'][id].get()?.identifier}
                </Typography>
              </div>
            </Tooltip>
            <div style={{ display: 'flex', gap: 2 }}>
              <IconButtonInfoPopupMarker
                markerPoint={{
                  identifier: markers()['device'][id].get()?.identifier,
                  code: markers()['device'][id].get()?.code,
                  id: id,
                  plate: markers()['device'][id].get()?.plate,
                  status: status
                }}
                motiveState={motiveState}
                outServiceTime={outServiceTime}
              />
              <IconButton size='small' onClick={() => closeThisPopUp()}>
                <Close fontSize='small' />
              </IconButton>
            </div>
          </div>
        </Stack>

        <Tooltip title={translate('Plate')} placement='left'>
          <Stack direction='row' alignItems='center'>
            <PinIcon sx={{ mr: '10px' }} />
            <Typography variant='subtitle1' sx={{ margin: '.4em 0' }} >
              {markers()['device'][id].get()?.plate ? markers()['device'][id].get().plate : 'Não Informado'}
            </Typography>
          </Stack>
        </Tooltip>
        <Tooltip title={translate('Tracking')} placement='left'>
          <Stack direction='row' alignItems='center'>
            <LocationSearchingIcon sx={{ mr: '10px' }} />
            <Typography variant='subtitle1' sx={{ margin: '.4em 0' }} >
              {code ? code : 'Não Informado'}
            </Typography>
          </Stack>
        </Tooltip>
        <Tooltip title={translate('Last update')} placement='left'>
          <Stack direction='row' alignItems='center' >
            <AccessTimeIcon sx={{ mr: '10px', color: changeColorTime(elapsedTimeInMinutes(date)) }} />
            <Typography variant='subtitle1' sx={{ margin: '.4em 0', color: changeColorTime(elapsedTimeInMinutes(date)) }} >
              {dayjs(date).isValid() ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''}
            </Typography>
          </Stack>
        </Tooltip>
        <Tooltip title={translate('Speed')} placement='left'>
          <Stack direction='row' alignItems='center'>
            <SpeedIcon sx={{ mr: '10px', display: markers()['device'][id].get()?.speed === undefined || markers()['device'][id].get()?.speed === null ? 'none' : 'block' }} />
            <Typography variant='subtitle1' sx={{ margin: '.4em 0', display: markers()['device'][id].get()?.speed === undefined || markers()['device'][id].get()?.speed === null ? 'none' : 'block' }} >
              {markers()['device'][id].get()?.speed} km/h
            </Typography>
          </Stack>
        </Tooltip>
        {motiveState && motiveState.get() != null && markers()['device'][id].get().status == 11 ? <Tooltip title={translate('Motive')} placement='left'>
          <Stack direction='row' alignItems='center' sx={{ justifyContent: 'space-between' }}>
            <Stack direction='row' alignItems='center'>
              <HomeRepairServiceIcon sx={{ mr: '10px' }} />
              <Typography variant='subtitle1' sx={{ margin: '.4em 0' }} >
                {motiveState.get()}
              </Typography>
            </Stack>
            {outServiceTime && outServiceTime.get() != null &&
              <Tooltip title={`Fora de Serviço em: ${dateTimeFormat(outServiceTime.get() ?? '')}`} placement='top'>
                <Chip variant={'outlined'} label={elapsedTime(outServiceTime.get() ?? '')} />
              </Tooltip>
            }
          </Stack>
        </Tooltip> : false}

        <Stack
          direction="column"
          display={'flex'}
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          marginTop={2}
        >
          {markers()['device'][id].get()?.status == 11 && !readonly ?
            <Button
              disabled={markers()['device'][id].get()?.status != 11}
              onClick={() => openReturnToServiceDialog.set(true)}
              variant='outlined'
              size='small'
              sx={{ width: '80%' }}>
              {translate('Return to service')}
            </Button> : false}

          <Button
            onClick={() => openCreateNewDispatchForDeviceDialog.set(true)}
            variant='contained'
            sx={{ width: '80%' }}
            size='small'
            disabled={Number(markers()['device'][id].get()?.status) >= 10}
          >
            &nbsp;
            {translate('Create Occurrence')}
          </Button>
        </Stack>
      </CardContent>

      <CardActions>
        <ButtonActionsPopupDeviceContainer
          id={id}
          status={markers()['device'][id].get()?.status}
          plate={plate}
          closeThisPopUp={closeThisPopUp}
          groupId={markers()['device'][id].get()?.groupId}
          readonly={!readonly}
        />
      </CardActions>

      {openReturnToServiceDialog.get() ?
        <ConfirmDialog
          open={openReturnToServiceDialog}
          title={translate('Return to service')}
          content={translate('Confirm this vehicle to return to service?')}
          handleClose={() => openReturnToServiceDialog.set(false)}
          handleAgree={handleReturnToServiceVehicle}
        /> : false}

      {openCreateNewDispatchForDeviceDialog.get() ?
        <NewDispatchPopupDeviceDialog
          open={openCreateNewDispatchForDeviceDialog}
          deviceId={id}
          latitude={markers()['device'][id].get()?.position.lat}
          longitude={markers()['device'][id].get()?.position.lng}
        /> :
        false}

    </Card >
  )
}