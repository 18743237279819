import React, { useEffect } from "react"
import Layout from "~/components/Layout";
import { useLang } from "~/hooks/useLang";
import AuditInterestPointTable from "../components/InterestPoint";

export default function AuditInterestPoint() {
    const { translate } = useLang();

    useEffect(() => {
        document.title = translate('Interest Point Audit - Dispatch System')
    }, [])
    return (
        <Layout marginLayout={true}>
            <AuditInterestPointTable />
        </Layout>
    )
}