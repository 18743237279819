import React, { useEffect } from "react"
import { none, useHookstate, type State } from "@hookstate/core";
import { Button, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, Paper } from "@mui/material";
import FilterAutocompleteType from "../FilterAutocompleteType";
import { useLang } from "~/hooks/useLang";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Delete } from "@mui/icons-material";
import FilterAutocompleteSubtypesByType from "../FilterAutocompleteSubtypesByType";

interface FilterAutocompleteTypesAndSubtypesWithDescriptionProps {
  readonly typeId: State<number | null>;
  readonly subtypeIds: State<number[] | null>
  readonly typesAndSubtypesSelected: State<typesAndSubtypesSelectedProps[] | []>;
  readonly lockFields: boolean;
  readonly errorType?: State<string | null | undefined>;
  readonly errorSubType?: State<string | null | undefined>;
  readonly requiredSubType?: State<boolean>;
  readonly category?: number | null;
}

export interface ListTypeProps {
  readonly id: string | number;
  readonly typeCode: string;
  readonly typeName: string;
}

export interface ListSubtypesProps {
  readonly id: string | number;
  readonly subTypeCode: string;
  readonly subTypeName: string;
  readonly typeId: string | number;
}

export interface typesAndSubtypesSelectedProps {
  readonly id: string | number;
  readonly typeCode: string;
  readonly typeName: string;
  readonly subtype: readonly {
    readonly id: string | number;
    readonly subTypeCode: string;
    readonly subTypeName: string;
    readonly typeId: string | number;
  }[] | []
}

interface removeTypeOrSubTypeProps {
  readonly typeId?: string | number;
  readonly subTypeId?: string | number;
}

// eslint-disable-next-line max-lines-per-function
export default function FilterAutocompleteTypesAndSubtypesWithDescription({ typeId, subtypeIds, typesAndSubtypesSelected, lockFields, errorType, category, errorSubType, requiredSubType }: FilterAutocompleteTypesAndSubtypesWithDescriptionProps) {
  const { translate } = useLang()
  const listType = useHookstate<ListTypeProps[] | []>([])
  const listSubtype = useHookstate<ListSubtypesProps[] | []>([])
  const selectedIndex = useHookstate<any[]>([])
  const filterSubtypesSelecteds = useHookstate<string[]>([]);
  const updateList = useHookstate(false)
  const refTypeIds = useHookstate<number | null>(null)

  const handleClick = (index: number) => {
    const indexItemColapse = selectedIndex.get().findIndex((item) => item === index)

    if (indexItemColapse !== -1) {
      selectedIndex[indexItemColapse].set(none)
    } else {
      selectedIndex.merge([index])
    }
  }

  const removeTypeOrSubType = ({ typeId, subTypeId }: removeTypeOrSubTypeProps) => {
    if (typeId && !subTypeId) {
      const indexRemoveType = typesAndSubtypesSelected.get().findIndex((item) => item.id === typeId)

      const subTypesIds = typesAndSubtypesSelected[indexRemoveType].get().subtype?.map(item => item.id)
      subTypesIds.map((itemSubTypeId) => {
        const indexRemoveSubType = typesAndSubtypesSelected[indexRemoveType].get().subtype.findIndex((item) => item.id === itemSubTypeId)
        typesAndSubtypesSelected[indexRemoveType].subtype[indexRemoveSubType]?.set(none)
      })

      setTimeout(() => {
        typesAndSubtypesSelected[indexRemoveType].set(none)
      }, 1)

    } else if (typeId && subTypeId) {
      const indexRemoveType = typesAndSubtypesSelected.get().findIndex((item) => item.id === typeId)
      const indexRemoveSubType = typesAndSubtypesSelected[indexRemoveType].get().subtype.findIndex((item) => item.id === subTypeId)

      const indexRemoveFilterSubtypesSelected = filterSubtypesSelecteds.get().indexOf(typesAndSubtypesSelected[indexRemoveType].subtype[indexRemoveSubType]?.get()?.subTypeCode)

      typesAndSubtypesSelected[indexRemoveType].subtype[indexRemoveSubType]?.set(none)
      filterSubtypesSelecteds[indexRemoveFilterSubtypesSelected].set(none)

      updateList.set(!updateList.get())
      if (requiredSubType && !typesAndSubtypesSelected[indexRemoveType].subtype?.length) {
        typesAndSubtypesSelected[indexRemoveType].set(none)
        filterSubtypesSelecteds.set([])
      }
    }
  }

  const verifyTypeExist = () => {
    const result = typesAndSubtypesSelected.get().filter((type) => type.id === typeId?.get())
    if (result?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const checkExistenceSubType = (currentSubtypes, newSubtypes) => {
    return newSubtypes.map(newSubtypeItem => {
      const exists = currentSubtypes?.some(type =>
        type.subtype?.some(subtypeItem => subtypeItem.id === newSubtypeItem.id)
      );
      return { ...newSubtypeItem, exists };
    });
  };

  const addTypeAndSubtype = () => {
    const existType = verifyTypeExist()

    if (!existType) {
      listType.get().map((type) => {
        typesAndSubtypesSelected.merge([{
          id: type.id,
          typeName: type.typeName,
          typeCode: type.typeCode,
          subtype: []
        }])
      })
    }


    const indexType = typesAndSubtypesSelected.get().findIndex((type) => type.id === typeId?.get())
    const result = checkExistenceSubType(typesAndSubtypesSelected.get(), listSubtype.get())

    result.map((sub) => {
      if (sub.exists === false) {
        typesAndSubtypesSelected[indexType]?.subtype?.merge([{
          id: sub.id,
          subTypeCode: sub.subTypeCode,
          subTypeName: sub.subTypeName,
          typeId: sub.typeId
        }])
        filterSubtypesSelecteds.merge([sub?.subTypeCode])
      }
    })

    updateList.set(!updateList.get())
    typeId.set(null)
    subtypeIds.set(null)
    listType.set([])
    listSubtype.set([])
  }

  return (
    <Grid item xs={6} display={'flex'} flexDirection={"column"} spacing={1}>

      <Grid item xs={12} display={'flex'} flexDirection={'row'} gap={1}>
        <Grid item xs={8} display={'flex'} flexDirection={'row'} gap={1} marginBottom={2}>

          <FilterAutocompleteType
            typeId={typeId?.get()}
            onTypeChange={(type) => {
              typeId?.set(Number(type?.id) ?? null)
            }}
            disabled={lockFields}
            ListTypeProps={listType}
            refTypeIds={refTypeIds}
            error={!!errorType?.get()}
            categoryId={category ?? null}
          />

          <FilterAutocompleteSubtypesByType
            disabled={lockFields}
            subtypes={subtypeIds?.get() ?? []}
            typeId={typeId?.get()}
            categoryId={category ?? null}
            onSubtypesChange={(subtype) => {
              subtypeIds?.set(subtype)
            }}
            refTypeIds={refTypeIds}
            ListSubtypesProps={listSubtype}
            error={!!errorSubType?.get()}
            requiredSubType={requiredSubType}
            updateFilterSubtype={updateList}
            filterSubtypesSelecteds={filterSubtypesSelecteds}
          />

        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={addTypeAndSubtype}
            sx={{ width: '100%' }}
            disabled={requiredSubType?.get()}
          >
            {translate("Add")}
          </Button>
        </Grid>

      </Grid>
      <Grid>

        <Paper elevation={0} variant='outlined' sx={{ height: '300px', overflowY: 'auto' }}>
          <List
            component="nav"
            aria-labelledby="nested-list-type-subtype"
            dense
            subheader={
              <>
                <ListSubheader component="div" id="nested-list-subheader">
                  {translate('Types and Subtypes Selecteds')}
                </ListSubheader>
                <Divider />
              </>
            }>
            {typesAndSubtypesSelected.get().map((itemType: typesAndSubtypesSelectedProps, index) => (
              <>
                <ListItem
                  key={itemType.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        removeTypeOrSubType({ typeId: itemType?.id })
                      }}>
                      <Delete />
                    </IconButton>
                  }
                  sx={{ paddingRight: 0 }}
                >
                  <ListItemButton
                    onClick={() => handleClick(index)}
                  >
                    {`[${itemType.typeCode}]`}
                    <ListItemText
                      primary={itemType.typeName}
                      sx={{ pl: 2 }}
                    />
                    {itemType.subtype?.length ? selectedIndex.get().includes(index) ? <ExpandLess /> : <ExpandMore /> : false}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={selectedIndex.get().includes(index)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding dense >
                    {itemType.subtype?.length ? itemType.subtype.map((itemSubtype: ListSubtypesProps) => {
                      return (
                      <ListItem
                        key={itemType.id}
                        sx={{ pl: 8 }}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              removeTypeOrSubType({ typeId: itemType?.id, subTypeId: itemSubtype?.id })
                            }}>
                            <Delete />
                          </IconButton>
                        }
                      >
                          {`[${itemSubtype?.subTypeCode}]`}
                          <ListItemText primary={itemSubtype?.subTypeName} sx={{ pl: 2 }} />
                      </ListItem>
                      )
                    }) : false}
                  </List>
                </ Collapse>
              </>
            ))}
          </List>
        </Paper>


      </Grid>
    </Grid>
  );
}