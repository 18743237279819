/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { useHookstate } from '@hookstate/core'

interface LocationAccuracyProps {
  readonly onAccuracyChange: (value: number[]) => void
  readonly locationAccuracy: number[]
  readonly disabled?: boolean
  readonly isBooleanField?: boolean
}

export default function FilterAutocompleteLocationAccuracy({
  disabled,
  onAccuracyChange,
  locationAccuracy,
}: LocationAccuracyProps) {
  const { translate } = useLang()
  const options = [
    {
      name: 'Todos os níveis',
      value: [],
    },
    {
      name: 'Média e Alta',
      value: [1, 2],
    },
    {
      name: 'Alta',
      value: [2],
    },
  ]
  const [value, setValue] = useState<{ name: string; value: number[] } | null>(null)
  const loading = useHookstate(false)

  useEffect(() => {
    setValue(options.find((item) => item.value.length == locationAccuracy.length) ?? null)
  }, [locationAccuracy])

  return (
    <FormControl fullWidth size="small">
      <Autocomplete
        limitTags={2}
        disabled={disabled}
        sx={{ height: 2 }}
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size="small"
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option == value}
        getOptionLabel={(option) => option.name}
        onChange={(_, type) => {
          setValue(type)
          onAccuracyChange(type?.value ?? [])
        }}
        renderInput={(params) => (
          <TextField {...params} label={translate('Location Accuracy Level')} />
        )}
      />
    </FormControl>
  )
}
