import { useHookstate, type State } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { Button, Collapse, Grid } from "@mui/material"
import React, { useEffect } from "react"
import FilterAutocompleteFetch from "~/components/FilterAutocompleteFetch";
import FilterSelectPeriod from "~/components/FilterAutocompletePeriod";
import SelectBasic from "~/components/SelectBasic";
import { useLang } from "~/hooks/useLang";

interface AuditInterestPointFiltersProps {
    readonly hidden?: boolean;
    readonly labelButton?: State<string>;
    readonly loadingButton: State<boolean>;
    readonly selectAction?: State<string | null>;
    readonly resetValues?: State<boolean>;
    readonly focusChange?: State<boolean>;
    readonly userId?: State<number | null>;
    readonly initialPeriod: State<string>;
    readonly onPeriodChange: (value: string) => void;
    readonly onStartDateChange: (value: string | null) => void;
    readonly onFinishDateChange: (value: string | null) => void;
    readonly clearFilters: () => void;
    readonly searchButton?: () => void;
}

export default function AuditInterestPointFilters({
    hidden,
    labelButton,
    loadingButton,
    selectAction,
    userId,
    resetValues,
    focusChange,
    initialPeriod,
    onStartDateChange,
    onFinishDateChange,
    onPeriodChange,
    clearFilters,
    searchButton
}: AuditInterestPointFiltersProps) {
    const { translate } = useLang();
    const selectTypeSearch = useHookstate<string | null>('1');
    const optionsSelect = useHookstate([
        { id: 1, name: 'All' },
        { id: 2, name: 'Creation' },
        { id: 3, name: 'Viewport' },
        { id: 4, name: 'Forced expiration' },
        { id: 5, name: 'Validation' },
    ])

    useEffect(() => {
        switch (String(selectTypeSearch?.get())) {
            case '1':
                selectAction?.set(null)
                break;
            case '2':
                selectAction?.set('creation')
                break;
            case '3':
                selectAction?.set('view')
                break;
            case '4':
                selectAction?.set('forced_expiration')
                break;
            case '5':
                selectAction?.set('validation')
                break;
            default:
                selectAction?.set(null)
        }
    }, [selectTypeSearch?.get()])


    return (
        <Collapse in={hidden}>
            <Grid container xs={12}
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Grid container direction={'column'} display={'flex'} gap={1}>
                    <Grid container direction={'row'}>
                        <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                            <SelectBasic
                                state={selectTypeSearch}
                                options={optionsSelect.get()}
                                placeholder={translate('Search Action')}
                                size={'small'}
                                disabled={false}
                                defaultValue={selectTypeSearch.get() ?? ''}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                            <FilterAutocompleteFetch
                                label={'User'}
                                urlFetch={'/user/get'}
                                size={"small"}
                                disabled={false}
                                onFetchChange={(user) => user ? userId?.set(user) : userId?.set(null)}
                                resetValues={resetValues?.get()}
                                focusChange={focusChange}
                                // model='user'
                                valueId={userId?.get() ? Number(userId?.get()) : undefined}
                            />
                        </Grid>
                        <Grid item xs={8} display={'flex'} direction={'row'} >
                            <FilterSelectPeriod
                                onStartDateChange={(value) => onStartDateChange(value?.toJSON() ?? null)}
                                onFinishDateChange={(value) => onFinishDateChange(value?.toJSON() ?? null)}
                                onPeriodChange={onPeriodChange}
                                period={initialPeriod.get()}
                                xsDateBetween={4}
                                xsPeriod={3}
                                hidePeriod={false}
                                marginDateBetween={0}
                                disableFuture={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction={'row'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    pt: 2,
                    gap: 2,
                }}
            >
                <Button variant="outlined"
                    onClick={() => {
                        clearFilters(),
                            selectTypeSearch?.set(null)
                    }}
                >
                    {translate('Clear Filters')}
                </Button>
                <LoadingButton
                    loading={loadingButton.get()}
                    variant="contained"
                    onClick={searchButton}
                >
                    {labelButton?.get()}
                </LoadingButton>
            </Grid>
        </Collapse>
    )
}