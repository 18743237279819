import React, {useState, useEffect} from 'react';
import SelectBasic from '~/components/SelectBasic';
import { getEntryOrigins } from '~/features/EntryOrigin/services/index';
import { Autocomplete, FormControl, TextField, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';

type EntryOriginProps = {
  readonly readonly: boolean;
  readonly id: number;
  readonly name: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string | null;
}

interface SelectEntryOriginProps {
    readonly onEntryOriginChange: (value: number | null) => void;
    readonly originId?: number | null;
    readonly disabled?: boolean;
    readonly label?: string;
  }

export default function FilterAutocompleteEntryOrigin({onEntryOriginChange, originId, disabled, label }: SelectEntryOriginProps) {
  const [option, setOption] = useState<EntryOriginProps[]>([]);
  const [value, setValue] = useState<EntryOriginProps | null>(null);
  const loading = useHookstate(false);
  const { translate } = useLang();

  useEffect(() => {
    getEntryOrigins().then((values) => {
      setOption(values);
    })
  },[]);

  useEffect(() => {
    if (!originId) {
      setValue(null)
    } else {
      setValue(option.find(item => item.id == originId) ?? null)
    }
  }, [option, originId])

return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-categories'
        options={option}
        disabled={disabled}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, origin) => {
          setValue(origin)
          onEntryOriginChange(origin?.id ?? null)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate(label ? label : 'Entry Origin')}
          />
        )}
      />
    </FormControl>
  )
};
