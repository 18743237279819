import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useLang } from '~/hooks/useLang'
import useSystemState from '~/features/System/stores/SystemState'
import StandardModal from '~/components/StandardModal'
import { Tune } from '@mui/icons-material'
import {
  Grid,
  IconButton,
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from '@mui/material'
import { storeScreenSettings } from '~/features/System/services'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Search } from '@mui/icons-material'
import { Downgraded, useHookstate } from '@hookstate/core'
import { useMarkerState } from '../../stores/MapState'
import { Dayjs, ManipulateType } from 'dayjs'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { getMarkersDispatchFilteredHistoricalService } from '../../services/request'

const modal = document.getElementById('div-modal') as HTMLElement

// eslint-disable-next-line max-lines-per-function
export default function MapHistoricFiltersModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const { markersState } = useMarkerState()
  const dateTime = useHookstate<Dayjs | null>(null)
  const loading = useHookstate<boolean>(false)
  const options = ['second', 'minute', 'hour', 'day']
  const period = useHookstate<string | null>(null)

  const handleStop = (event, dragElement) => {
    event.stopPropagation()

    windowManager()['mapHistoricFilters'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      windowManager()['mapHistoricFilters'].open.set(false)
    }
  })

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapHistoricFiltersModalDraggable',
        isOpen: windowManager()['mapHistoricFilters'].open.get(),
        isMinimized: windowManager()['mapHistoricFilters'].minimized.get(),
        positionX: windowManager()['mapHistoricFilters'].positionX.get(),
        positionY: windowManager()['mapHistoricFilters'].positionY.get(),
      })
    }
  }, [
    windowManager()['mapHistoricFilters'].open.get(),
    windowManager()['mapHistoricFilters'].minimized.get(),
    windowManager()['mapHistoricFilters'].positionY.get(),
    windowManager()['mapHistoricFilters'].positionX.get(),
  ])

  const searchHistorical = () => {
    loading.set(true)
    getMarkersDispatchFilteredHistoricalService({
      dateTime: dateTime.attach(Downgraded).get()
    }).then((response) => {
      markersState()['dispatchFiltered']?.set(response)
    }).finally(() => loading.set(false))
  }

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapHistoricFilters'].open.value &&
        !loadingButton.get() && (
          <StandardModal
            title={translate('Historic Search')}
            handleStop={handleStop}
            position={{
              x: windowManager()['mapHistoricFilters'].positionX?.get()
                ? Number(windowManager()['mapHistoricFilters'].positionX?.get())
                : 80,
              y: windowManager()['mapHistoricFilters'].positionY?.get()
                ? Number(windowManager()['mapHistoricFilters'].positionY?.get())
                : 141,
            }}
            closeButton={
              !windowManager()['mapHistoricFilters'].disabled.value
                ? () => windowManager()['mapHistoricFilters'].open.set(false)
                : false
            }
            isExpanded={windowManager()['mapHistoricFilters'].minimized}
            dimensions={{
              width: 500,
            }}
          >
            <Grid container display={'flex'} flexDirection={'row'}>
              <Grid item xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'pt-br'}
                >
                  <DateTimePicker
                    format="DD/MM/YY HH:mm:ss"
                    views={[
                      'year',
                      'month',
                      'day',
                      'hours',
                      'minutes',
                      'seconds',
                    ]}
                    value={dateTime.attach(Downgraded).get()}
                    onChange={(value) => dateTime.set(value)}
                    slotProps={{
                      textField: {
                        inputProps: {
                          placeholder: translate(`Historic Date`),
                        },
                      },
                    }}
                    disableFuture
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={8}
                display={'flex'}
                sx={{ gap: 1, pt: 2 }}
              >
                <Autocomplete
                  options={options}
                  size='small'
                  value={period.get()}
                  getOptionLabel={(option) => translate(option)}
                  renderInput={(params) => (
                    <TextField {...params} label={translate('Period')} />
                  )}
                  onChange={(_, value) => period.set(value)}
                  sx={{ width: '50%' }}
                />
                  <Button
                  title={translate('Subtract period')}
                  variant='contained'
                  size="small"
                  onClick={() =>
                    dateTime.set(
                      dateTime
                        .get()
                        ?.subtract(1, period.get() as ManipulateType) ?? null
                    )
                  }
                  sx={{fontSize: 16}}

                >
                  -1
                </Button>
                <Button
                  title={translate('Add period')}
                  variant='contained'
                  size="small"
                  onClick={() =>
                    dateTime.set(
                      dateTime.get()?.add(1, period.get() as ManipulateType) ??
                        null
                    )
                  }
                  sx={{fontSize: 16}}
                >
                  +1
                </Button>
              
              </Grid>
              <Grid
                item
                xs={4}
                display={'flex'}
                justifyContent={'end'}
                alignItems={'flex-end'}
                sx={{ pt: 2 }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    dateTime.set(null)
                    period.set(null)
                  }}
                  sx={{
                    height: '40px',
                    textTransform: 'none',
                    textDecorationLine: 'underline',
                    color: 'text.primary',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'primary.main',
                    },
                  }}
                >
                  {translate('Clear filters')}
                </Button>
                <IconButton
                  onClick={() => searchHistorical()}
                  disabled={false}
                  title={translate('Search')}
                >
                  {loading.get() ? <CircularProgress size={30} /> : <Search />}
                </IconButton>
              </Grid>
            </Grid>
          </StandardModal>
        )}
    </>,
    modal
  )
}
