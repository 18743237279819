import React, { useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary";
import useSystemState from "~/features/System/stores/SystemState";
import { useLang } from "~/hooks/useLang";
import ErrorFallback from '~/components/ErrorFallback';
import { Divider, Grid, IconButton, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { useHookstate } from "@hookstate/core";
import FilterAutocompleteFetch from "~/components/FilterAutocompleteFetch";
import { deviceResponseProps } from "../../interfaces/ISearchstaff";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import dayjs from "dayjs";
import { authFetch } from "~/services/fetch";
import { useMarkerState } from "~/features/Map/stores/MapState";
import { LatLng } from "leaflet";
import { getDeviceInfo } from "../../services";

// eslint-disable-next-line max-lines-per-function
export default function SearchStaff() {
  const { translate } = useLang();
  const loading = useHookstate(false)
  const resetValeus = useHookstate(false)
  const deviceId = useHookstate<number | null>(null)
  const userId = useHookstate<number | null>(null)

  const { forceViewMap, markersState } = useMarkerState()
  const devicesInfoDefault = {
    id: 0
  }
  const devicesInfo = useHookstate<deviceResponseProps>(devicesInfoDefault)
  const focusChange = useHookstate<boolean>(false)


  const requestDevice = () => {
    loading.set(true)
    focusChange.set(false)
    getDeviceInfo({
      deviceId: deviceId.get(),
      userId: userId.get()
    }).then(({ data }) => {
      devicesInfo.set(data);
    }).finally(() => {
      loading.set(false)
      deviceId.set(null)
      resetValeus.set(!resetValeus.get())
    })
  }

  useEffect(() => {
    if (deviceId.get() || userId.get()) {
      requestDevice()
    }
  }, [deviceId.get(), userId.get()])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container width={'100%'}>
        <Grid container xs={12} width={'100%'} direction="row" spacing={1}>
          <Grid item xs={6} >
            <FilterAutocompleteFetch
              label={'device'}
              urlFetch={'/device/find'}
              size={"small"}
              disabled={false}
              dataRequest="device"
              onFetchChange={(device) => device ? deviceId.set(device) : null}
              resetValues={resetValeus.get()}
              focusChange={focusChange}
            />
          </Grid>
          <Grid item xs={6} >
            <FilterAutocompleteFetch
              label={'User'}
              urlFetch={'/user/get'}
              size={"small"}
              disabled={false}
              onFetchChange={(user) => user ? userId.set(user) : null}
              resetValues={resetValeus.get()}
              focusChange={focusChange}
              // model='user'
              valueId={userId.get() ? Number(userId.get()) : undefined}
            />
          </Grid>
        </Grid>
        <Grid container paddingTop={3}>
          <Grid item xs={12}>
            {
              devicesInfo.get() && devicesInfo.get().name ?
                <>
                  <Divider sx={{paddingTop: 3}} />
                  <Grid key={devicesInfo.get()?.id} container display={'flex'} alignItems={"center"} justifyContent={'space-between'} marginTop={2}>
                    <Grid width={'100%'} display={'flex'} flexDirection={'row'} alignItems={"center"}>
                      <ListItem
                        sx={{ display: ' flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', padding: 0 }}
                      >
                        <ListItemText
                          sx={{ width: '100%' }}
                          primary={<Grid width={'100%'} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography>
                              {`${translate('Name')}: ${devicesInfo.get()?.name}`}
                            </Typography>
                            <Tooltip
                              title={translate('Locate on the map')}
                              placement="bottom" sx={{
                                "&:hover": {
                                  background: "none"
                                }
                              }}>
                              <Grid>
                                <IconButton
                                  aria-label="comment"
                                  onClick={() => {
                                    if (devicesInfo.get()?.id) {
                                      forceViewMap().zoom.set(17);
                                      forceViewMap().position.set(new LatLng(markersState()['device'][devicesInfo.get()?.id].position.lat.get(), markersState()['device'][devicesInfo.get()?.id].position.lng.get()));
                                    }
                                  }}

                                  sx={{
                                    padding: '4px 8px 8px 12px', "&:hover": {
                                      background: "none"
                                    }
                                  }}
                                  disabled={(Boolean(!markersState()['device'].get()[devicesInfo.get()?.id]?.position)) || !devicesInfo.get()?.stationHasAccess}
                                >
                                  <TravelExploreIcon />
                                </IconButton>
                              </Grid>
                            </Tooltip>
                          </Grid>}
                          secondary={<>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Plate')}: ${devicesInfo.get()?.vehicle !== null ? devicesInfo.get()?.vehicle?.plate : translate('Not informed')}`}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Device Type')} : ${devicesInfo.get()?.device_type?.name ? devicesInfo.get()?.device_type?.name : translate('Not informed')}`}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Dispatch Group')} : ${devicesInfo.get()?.dispatch_group?.name ? devicesInfo.get()?.dispatch_group?.name : translate('Not informed')}`}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Task')}: ${devicesInfo.get()?.device_log?.task && devicesInfo.get()?.device_log?.task !== '' ? devicesInfo.get()?.device_log?.task : translate('Not informed')}`}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Status')}: ${devicesInfo.get()?.status?.name !== null ? devicesInfo.get()?.status?.name : translate('Not informed')}`}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                              {`${translate('Connected on')} : ${devicesInfo.get()?.device_log?.date_connected !== null ? dayjs(devicesInfo.get()?.device_log?.date_connected).format('DD/MM/YYYY HH:mm:ss') : translate('Not informed')}`}
                            </Typography>
                            {devicesInfo.get()?.status?.acronym === 'DC' ?
                              <Typography variant={'subtitle2'}>
                                {`${translate('Disconnected on')} : ${devicesInfo.get()?.device_log?.date_connected !== null ? dayjs(devicesInfo.get()?.device_log?.date_disconnected).format('DD/MM/YYYY HH:mm:ss') : translate('Not informed')}`}
                              </Typography>
                              : ''}
                          </>} />
                        <ListItemText
                          primary={`${translate('Composition')}`}
                          secondary={devicesInfo.get()?.device_log?.crew?.map((element) => {
                            return (
                              element != null ?

                                <Typography key={devicesInfo.get()?.device_log?.id}>
                                  {`${element.position.name} - ${element.user.representation_name} `}
                                </Typography>
                                :
                                <Typography>
                                  {translate('Not informed')}
                                </Typography>
                            );
                          })} />
                      </ListItem>
                    </Grid>
                    <Divider sx={{ marginTop: 2 }} />
                  </Grid>
                </> :
                <Grid display={'flex'} alignItems={"center"} justifyContent={'center'} marginTop={8}>
                  <Typography>
                    {translate('No Staff')}
                  </Typography>
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary >
  )
}

