import React, { useEffect } from "react";
import InternalHeader from "~/components/Layout/components/InternalHeader";
import { useLang } from "~/hooks/useLang";
import ListAllAuditQualifiers from "./components/ListAllAuditQualificators";

export default function AuditQualifiersTable() {
    const { translate } = useLang();
    

    return (
        <>
            <InternalHeader title={translate('Qualificators Audit')} />
            <ListAllAuditQualifiers />
        </>
    )
}