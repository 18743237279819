/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import dayjs from 'dayjs';

interface DispatchedVehiclesPrintProps {
  readonly DispatchedVehiclesPrintResponse: any | null
}

export default function DispatchedVehiclesPrint({ DispatchedVehiclesPrintResponse }: DispatchedVehiclesPrintProps) {
  const { translate } = useLang();

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        {DispatchedVehiclesPrintResponse.length != 0 ? (
          DispatchedVehiclesPrintResponse.map((vehicle, index) => (
            <Grid container key={index.toString()} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{ border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Typography variant='subtitle1'><b>{vehicle?.device?.name}</b><br /><Typography variant='subtitle2'>{vehicle?.device?.vehicle?.plate ? `(${vehicle?.device?.vehicle?.plate})` : ''}</Typography></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={12}>

                  <Typography variant='subtitle2'>
                    <b>Hora/Despacho:</b>
                    {
                      vehicle?.report_timers?.date_dispatch ?
                      dayjs(vehicle?.report_timers?.date_dispatch).isValid() ? dayjs(vehicle?.report_timers?.date_dispatch).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *` :
                      `* ${translate('Uninformed')} *` 
                    }
                  </Typography>

                  <Typography variant='subtitle2'>
                    <b>Hora/Local:</b>
                    {
                      vehicle?.report_timers?.date_on_site ?
                      dayjs(vehicle?.report_timers?.date_on_site).isValid() ? dayjs(vehicle?.report_timers?.date_on_site).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *` :
                      `* ${translate('Uninformed')} *`
                    }
                  </Typography>

                  <Typography variant='subtitle2'>
                    <b>Hora/Final:</b>
                    {
                      vehicle?.report_timers?.date_status_finish ?
                      dayjs(vehicle?.report_timers?.date_status_finish).isValid() ? dayjs(vehicle?.report_timers?.date_status_finish).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *` :
                      `* ${translate('Uninformed')} *`
                    }
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    <b>Tempo/Despacho:</b>
                    {vehicle?.report_timers?.timer_status?.dispatch_time ? vehicle?.report_timers?.timer_status?.dispatch_time : `* ${translate('Uninformed')} *`}
                  </Typography>

                  <Typography variant='subtitle2'>
                    <b>Tempo/Finalização:</b>
                    {vehicle?.report_timers?.timer_status?.finishing_time ? vehicle?.report_timers?.timer_status?.finishing_time : `* ${translate('Uninformed')} *`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    <b>Tempo/Deslocamento:</b>
                    {vehicle?.report_timers?.timer_status?.in_route_time ? vehicle?.report_timers?.timer_status?.in_route_time : `* ${translate('Uninformed')} *`}
                  </Typography>
                  <Typography variant='subtitle2'>
                    <b>Tempo/Total:</b>
                    {vehicle?.report_timers?.total_time ? vehicle?.report_timers?.total_time : `* ${translate('Uninformed')} *`}
                  </Typography>
                </Grid>

                {
                  vehicle['device_log'] && (
                    vehicle['device_log']['crew'].length && (
                      <Grid item xs={12}>
                        <Grid container xs={12}>
                          <Grid item xs={3}>
                            <Typography variant='subtitle2'><b>Guarnição:</b></Typography>
                          </Grid>
                          <Grid item xs={9}>
                            {vehicle['device_log']['crew'].map((crew, crewIndex) => {
                              const crewPosition = crew.position;
                              const crewUser = crew.user;
                              const getPosition = {
                                'Comandante': vehicle.device?.device_type?.label_commander ? vehicle.device?.device_type.label_commander : 'Comandante',
                                'Motorista': vehicle.device?.device_type?.label_driver ? vehicle.device?.device_type.label_driver : 'Motorista',
                                'Patrulheiro': vehicle.device?.device_type?.label_patrolmans ? vehicle.device?.device_type.label_patrolmans : 'Patrulheiro',
                              }

                              return (
                                (crewPosition && crewUser) &&
                                (<Typography key={crewIndex.toString()} variant='subtitle2'><b>{getPosition[crewPosition.name] ? getPosition[crewPosition.name] : crewPosition.name}:</b> {crewUser.representation_name} {crewUser.registration && `[${crewUser.registration}]`}</Typography>)
                              )
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )
                }
                { vehicle?.removed_from_dispatch && (
                <Grid item xs={6}>
                  <Typography variant='subtitle2'>
                    <b>{`[${translate('Vehicle Removed')}]`}</b>
                  </Typography>
                </Grid>
                )
                }

              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}